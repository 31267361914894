@import 'https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap';
/*font-family: 'Poppins', sans-serif;*/
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  line-height: 1.1;
  font-family: "Poppins", sans-serif;
}

p {
  font-family: "Poppins", sans-serif;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}

html {
  overflow-x: hidden;
  font-family: "Poppins", sans-serif;
}

body {
  overflow-x: hidden;
  font-family: "Poppins", sans-serif;
}

/*****Breadcrumb Section Starts*****/
.page-breadcrumb {
  padding: 0 115px;
}
.page-breadcrumb .breadcrumb {
  justify-content: flex-end;
  margin-top: 1rem;
}
.page-breadcrumb .breadcrumb a {
  color: #000;
  text-decoration: none;
  font-size: 16px;
  font-weight: 300;
}
.page-breadcrumb .breadcrumb i {
  padding: 0 10px;
}

.page-breadcrumb {
  padding: 0 115px;
}
.page-breadcrumb .breadcrumb {
  justify-content: flex-end;
  margin-top: 1rem;
}
.page-breadcrumb .breadcrumb a {
  color: #000;
  text-decoration: none;
  font-size: 16px;
  font-weight: 300;
}
.page-breadcrumb .breadcrumb i {
  padding: 0 10px;
}

/*****Breadcrumb Section Ends*****/
/*****Home Carousel Style Starts*****/
.carousel-control-next {
  opacity: 0;
}
.carousel-control-next:focus {
  opacity: 0;
}
.carousel-control-next:hover {
  opacity: 0;
}

.carousel-control-prev {
  opacity: 0;
}
.carousel-control-prev:focus {
  opacity: 0;
}
.carousel-control-prev:hover {
  opacity: 0;
}

.carousel-caption {
  text-align: left;
  left: 5%;
  top: 55%;
}
.carousel-caption h3 {
  font-size: 120px;
  line-height: 120px;
  font-weight: 700;
  color: #07102a;
}
.carousel-caption p {
  font-size: 50px;
  font-weight: 400;
  color: #fff;
  margin-bottom: 50px;
}
.carousel-caption p:last-child {
  max-width: 300px;
  width: 100%;
  background: #ffb41f;
  height: 2px;
  margin-left: 25px;
}
.carousel-caption a {
  color: #fff;
  border: 2px solid #fff;
  padding: 10px 25px;
  text-decoration: none;
  float: right;
  font-size: 20px;
  text-transform: uppercase;
  font-weight: 600;
  float: none !important;
}
.carousel-caption .banner-text {
  display: flex;
  align-items: center;
}

.carousel-indicators {
  display: none;
}

/*****Home Carousel Style Ends*****/
.home-banner {
  overflow: hidden;
  position: relative;
}
.home-banner__single-img img {
  width: 100%;
  height: 950px;
  object-fit: cover;
  animation: zoom-in-zoom-out 55s ease infinite;
}
.home-banner__single-img .mouse-btn {
  display: block !important;
}
.home-banner__single-img .mouse-btn a {
  background-image: url(../images/icons/mouse_icon.webp);
  background-repeat: no-repeat;
  bottom: 8%;
  content: "";
  display: block;
  height: 60px;
  left: 50%;
  margin-left: -20px;
  position: absolute;
  width: 40px;
  z-index: 9;
  -webkit-animation-name: floating;
  animation-name: floating;
  -webkit-animation-duration: 3s;
  animation-duration: 3s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
}
@keyframes zoom-in-zoom-out {
  0% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(1.1, 1.1);
  }
  100% {
    transform: scale(1, 1);
  }
}
.home-banner__health-exibition {
  max-width: 450px;
  width: 100%;
  height: auto;
  position: absolute;
  inset-block-start: 30%;
  inset-inline-end: 5%;
  background-color: rgba(255, 255, 255, 0.5);
  box-sizing: border-box;
  padding: 20px;
  box-shadow: 0 0 5px #2b3758;
  border-radius: 3px;
}
.home-banner__exibition-content h1 {
  font-size: 27px;
  line-height: 27px;
  font-weight: 600;
  margin-block-end: 15px;
}
.home-banner__exibition-content h3 {
  font-size: 20px;
  line-height: 26px;
  font-weight: 500;
}
.home-banner__exibition-content p {
  font-size: 16px;
  line-height: 25px;
  font-weight: 500;
}

.spinner-grow {
  width: 3rem;
  height: 3rem;
  visibility: hidden;
}

.spinner-grow.spinnershow {
  width: 3rem;
  height: 3rem;
  visibility: visible;
}

.term::after {
  top: 1% !important;
}

@media (max-width: 1199px) and (min-width: 992px) {
  .know-more h1 {
    font-size: 28px;
  }

  .carousel-caption {
    top: 65%;
    left: 10%;
  }
  .carousel-caption h3 {
    font-size: 90px;
  }
  .carousel-caption p {
    font-size: 35px;
  }
  .carousel-caption p:last-child {
    max-width: 250px;
  }
}
@media (max-width: 991px) and (min-width: 768px) {
  .home-banner__single-img img {
    height: auto;
  }

  .carousel-caption {
    top: 55%;
  }
  .carousel-caption h3 {
    font-size: 60px;
    line-height: 90px;
  }
  .carousel-caption p {
    font-size: 22px;
  }
  .carousel-caption p:last-child {
    max-width: 170px;
  }

  .buttons-wrapper a {
    display: block;
    text-align: center;
    margin: 10px 0;
  }

  .top-banner h1 {
    font-size: 100px;
    margin-top: -53px;
  }
  .top-banner .solution-pages-main-title {
    top: 40%;
  }
  .top-banner .solution-pages-main-title h2 {
    font-size: 60px !important;
    line-height: 60px;
  }

  .form-wrapper .form-check-input {
    margin-bottom: 61px;
  }

  .sec-achievement .img-box img {
    height: auto;
  }

  .home-banner__health-exibition {
    inset-block-start: 25%;
    inset-inline-end: 2%;
  }
}
@media (max-width: 516px) and (min-width: 375px) {
  .know-more a {
    width: 60% !important;
  }

  .carousel-caption {
    top: 30%;
  }
  .carousel-caption p {
    font-size: 18px;
  }
  .carousel-caption a {
    font-size: 13px !important;
  }

  .buttons-wrapper a {
    display: block;
    text-align: center;
    margin: 10px 0;
  }

  .form-wrapper .form-check-input {
    margin-bottom: 55px;
  }
}
@media (max-width: 767px) {
  .know-more {
    margin-bottom: 50px;
  }
  .know-more h1 {
    text-align: center;
    margin-bottom: 20px;
  }
  .know-more a {
    float: none;
    margin: 20px auto 0;
    display: block;
    width: 40%;
    text-align: center;
  }

  .left-img-holder.float-end {
    float: none !important;
  }

  .form-wrapper {
    padding-top: 0;
    padding-bottom: 50px;
  }
  .form-wrapper::before {
    display: none;
  }
  .form-wrapper::after {
    display: none;
  }
  .form-wrapper h2 {
    font-size: 40px;
    line-height: 45px !important;
    line-height: 30px;
  }
  .form-wrapper .form-check {
    margin-bottom: 30px;
  }
  .form-wrapper a {
    float: left;
  }
  .form-wrapper label {
    font-size: 14px;
  }
  .form-wrapper__contact-box {
    margin-top: 20px;
  }

  .hide-in-mobile {
    display: none;
  }

  .carousel-caption {
    top: 50%;
  }
  .carousel-caption h3 {
    font-size: 40px;
    line-height: 60px;
  }
  .carousel-caption p {
    font-size: 18px;
  }
  .carousel-caption p:last-child {
    max-width: 70px;
  }
  .carousel-caption a {
    font-size: 13px !important;
  }

  .home-banner__health-exibition {
    max-width: 100%;
    position: relative;
    inset-inline: 0;
    margin: 70px auto 50px;
    background: linear-gradient(146.08deg, #2b3758 -0.65%, #2a9d8f 102.53%);
    padding: 25px;
  }
  .home-banner__exibition-content {
    max-width: 450px;
    width: 100%;
    margin: 0 auto;
    background-color: rgba(255, 255, 255, 0.2);
    padding: 20px;
  }
  .home-banner__exibition-content h1,
.home-banner__exibition-content h3,
.home-banner__exibition-content p {
    color: #fff;
  }
}
@media (max-width: 575px) {
  .container {
    padding: 0 25px;
  }

  .carousel-caption h3 {
    font-size: 25px;
    line-height: 25px;
  }

  .carousel-caption p {
    font-size: 13px;
    margin-bottom: 20px;
  }

  .carousel-caption p:last-child {
    max-width: 40px;
    margin-left: 10px;
  }

  .form-wrapper h2 {
    font-size: 25px;
    line-height: 30px !important;
    margin-bottom: 50px;
  }
}
@media (max-width: 375px) {
  .know-more a {
    width: 70%;
  }

  .navbar-brand {
    max-width: 50% !important;
  }

  .buttons-wrapper a {
    display: block;
    text-align: center;
    margin: 10px 0;
    font-size: 14px;
  }

  .carousel-caption h3 {
    margin: 0;
  }
  .carousel-caption p {
    margin-bottom: 0;
  }
  .carousel-caption a {
    padding: 2px 10px;
  }

  .form-wrapper .form-check-input {
    margin-bottom: 55px;
  }
  .form-wrapper .form-check-label {
    font-size: 13px;
  }
  .form-wrapper__contact-box p {
    font-size: 14px;
  }

  .top-banner h1 {
    font-size: 35px;
    margin-top: -77px;
  }
  .top-banner .solution-pages-main-title h2 {
    font-size: 30px;
  }

  .page-breadcrumb {
    padding: 0 30px;
  }
}
.btn-outline-white {
  text-decoration: none;
  color: #fff;
  width: 267px;
  height: 67px;
  border: 1px solid #fff;
  display: block;
  font-size: 30px;
  font-weight: 300;
  font-family: Poppins, sans-serif;
  text-align: center;
  line-height: 65px;
  margin-top: 25px;
}
.btn-outline-white:hover {
  color: #fff;
}
.btn-outline-white--font-border {
  border-color: #006b60;
  color: #006b60;
  margin: 50px auto 0;
  font-size: 18px;
  font-weight: 500;
  width: 150px;
  line-height: 50px;
  height: 50px;
}
.btn-outline-white--font-border:hover {
  background-color: #ffb41f;
  border-color: #ffb41f;
  color: #000;
}

.btn-success {
  background: linear-gradient(146.08deg, #2b3758 -0.65%, #2a9d8f 102.53%);
  border-radius: 0;
  padding: 8px 40px;
  color: #fff !important;
  text-decoration: none;
  font-size: 18px;
  font-weight: 600;
}

footer {
  background-color: #07102a;
  padding-top: 70px;
}
footer .logo-footer {
  border-right: 1px solid #ffb41f;
  padding: 10px 0;
}
footer .footer-text {
  margin-bottom: 60px;
}
footer .footer-text p {
  color: #fff;
  font-size: 18px;
  line-height: 25px;
  font-weight: 400;
  padding-left: 50px;
}
footer .contact {
  display: flex;
}
footer .contact div:last-child {
  color: #fff;
  margin-left: 20px;
  font-size: 18px;
  font-weight: 500;
}
footer .contact a {
  color: #fff !important;
  text-decoration: none;
}
footer .contact:hover a {
  color: #ffb41f !important;
}
footer .contact:hover i {
  color: #ffb41f !important;
}
footer .contact p {
  font-size: 18px;
  font-weight: 500;
}
footer .address {
  display: flex;
}
footer .address div:last-child {
  color: #fff;
  margin-left: 20px;
  font-size: 18px;
  font-weight: 500;
}
footer .address a {
  text-decoration: none;
  color: #fff;
}
footer .address a:hover {
  color: #ffb41f;
}
footer .email {
  display: flex;
}
footer .email div:last-child {
  color: #fff;
  margin-left: 20px;
  font-size: 18px;
  font-weight: 500;
}
footer .email a {
  color: #fff;
  text-decoration: none;
}
footer .email:hover a {
  color: #ffb41f !important;
}
footer .email:hover i {
  color: #ffb41f !important;
}
footer .social-media--icons {
  display: flex;
  justify-content: center;
}
footer .social-media--icons ul {
  list-style-type: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 30%;
}
footer .social-media--icons ul i {
  color: #fff;
  font-size: 26px;
}
footer .social-media--icons ul i:hover {
  color: #ffb41f;
}
footer .social-media--icons ul .fa-facebook {
  background-color: #fff;
  color: #07102a;
  width: 30px;
  border-radius: 50%;
  text-align: center;
  height: 30px;
  padding-top: 8px;
  font-size: 23px;
}
footer .social-media--icons ul .fa-facebook:hover {
  background-color: #ffb41f;
  color: #07102a;
}
footer .separator {
  display: block;
  height: 1px;
  background-color: #ffb41f;
  margin: 60px 0;
  position: relative;
}
footer .separator::after {
  position: absolute;
  content: "";
  width: 50%;
  height: 4px;
  background-color: #ffb41f;
  bottom: -1px;
  left: 0;
  right: 0;
  margin: 0 auto;
}
footer .footer-links {
  margin-top: 20px;
}
footer .footer-links ul {
  display: flex;
  list-style-type: none;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
  flex-wrap: wrap;
}
footer .footer-links ul a {
  color: #fff;
  text-decoration: none;
  padding: 0 10px;
  font-size: 20px;
  font-weight: 400;
}
footer .footer-links ul a:hover {
  color: #ffb41f;
}
footer .policy-links {
  padding: 30px 0 0;
}
footer .policy-links ul {
  padding: 0;
}
footer .policy-links ul li {
  display: inline-block;
}
footer .policy-links ul li a {
  text-decoration: none;
  color: #ffb41f;
  font-size: 18px;
  font-weight: 500;
}
footer .policy-links ul li:nth-child(2) {
  padding: 0 40px;
}
footer .copyright {
  padding: 30px 0 0;
  text-align: right;
}
footer .copyright p {
  margin: 0;
  font-size: 18px;
  font-weight: 500;
}
footer .copyright p span {
  color: #ffb41f;
}

.cookie-wrapper {
  display: none;
  width: 50%;
  position: fixed;
  bottom: 10px;
  z-index: 9;
  color: #fff;
  padding: 10px 20px;
  background: #fbf3e3;
  border-radius: 8px;
  border: 2px solid #000;
}
.cookie-wrapper.show {
  display: unset;
}
.cookie-wrapper p {
  text-align: justify;
  color: #000;
}
.cookie-wrapper p.text-right {
  text-align: right;
  margin: 0;
}
.cookie-wrapper p.text-right .btn-dark {
  background-color: #07102a;
  padding: 5px 8px;
  border: none;
  border-radius: 3px;
  font-size: 13px;
  font-weight: 500;
  color: #fff !important;
  text-decoration: none;
}
.cookie-wrapper p.text-right .btn-dark:hover {
  background-color: #ffb41f;
  color: #fff;
}
.cookie-wrapper a {
  color: #ffb41f;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 14px;
}

@media (max-width: 1024px) and (min-width: 992px) {
  footer .contact a,
footer .address a,
footer .email a {
    font-size: 16px;
  }
}
@media (max-width: 991px) and (min-width: 768px) {
  .social-media--icons ul {
    width: 45% !important;
  }

  footer .contact a,
footer .address a,
footer .email a {
    font-size: 12px;
  }
  footer .footer-text p {
    font-size: 15px !important;
  }
  footer .copyright p {
    font-size: 15px;
  }
  footer .policy-links li:nth-child(2) {
    padding: 0 10px !important;
  }
  footer .policy-links a {
    font-size: 15px !important;
  }
}
@media (max-width: 767px) {
  .social-media--icons ul {
    width: 80% !important;
  }

  footer .logo-footer {
    border: none !important;
    margin-bottom: 25px;
  }
  footer .logo-footer img {
    display: block;
    margin: 0 auto;
  }
  footer .footer-text p {
    padding: 0;
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
  }
  footer .contact a {
    font-size: 16px;
  }
  footer .address {
    display: block !important;
    text-align: center;
    margin: 50px 0;
  }
  footer .address a {
    font-size: 16px;
  }
  footer .address div:last-child {
    margin-top: 20px;
  }
  footer .email {
    display: block !important;
    text-align: center;
  }
  footer .email a {
    font-size: 16px;
  }
  footer .email div:last-child {
    margin-top: 20px;
  }
  footer .footer-links ul a {
    font-size: 16px;
  }
  footer .policy-links {
    text-align: center;
  }
  footer .policy-links ul li:nth-child(2) {
    padding: 0 20px;
  }
  footer .policy-links ul a {
    font-size: 16px !important;
  }
  footer .copyright p {
    text-align: center;
    font-size: 16px;
  }
}
.header.inner {
  background-color: transparent;
}
.header.inner .navbar .nav-link {
  color: #07102a;
}

.header {
  position: absolute;
  z-index: 9;
  width: 100%;
  background: linear-gradient(90deg, #fff 9.56%, rgba(255, 255, 255, 0.28) 48.59%, rgba(255, 255, 255, 0) 97.72%);
  border-bottom: 1px solid #fff;
}
.header .navbar {
  padding: 5px 100px;
}
.header .navbar .nav-link.active {
  color: #ffb41f;
  font-weight: 500;
}
.header .navbar .nav-link {
  font-size: 18px;
  font-weight: 400;
  color: #07102a;
  padding-left: 1rem;
  padding-right: 1rem;
}
.header .navbar .nav-link:hover {
  color: #ffb41f;
}
.header .navbar .nav-link:active {
  color: #ffb41f;
}
.header .navbar .nav-link.partner-link {
  background-color: #07102a;
  color: #fff;
  border-radius: 10px;
}
.header .navbar .dropdown-menu li a {
  padding: 10px;
  border-bottom: 1px solid #e7e7e7;
  color: #07102a;
}
.header .navbar .dropdown-menu li a:hover {
  background-color: #07102a;
  color: #fff;
}

@media (max-width: 1199px) and (min-width: 992px) {
  .navbar .nav-link {
    font-size: 14px !important;
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
  }
}
@media (max-width: 991px) {
  .navbar {
    /*.nav-link {
      color: #fff !important;
    }*/
  }
  .navbar .navbar-collapse {
    background: rgba(0, 0, 0, 0.6);
    border-radius: 10px;
  }
}
@media (max-width: 767px) {
  .navbar .nav-link {
    font-size: 14px !important;
    padding-top: 3px;
    padding-bottom: 3px;
  }

  .header.inner .navbar .nav-link,
.header .navbar .nav-link {
    color: #fff;
  }

  .header.inner {
    padding: 0;
  }
}
@media (max-width: 575px) {
  .header {
    position: relative;
    background: linear-gradient(90deg, #a9a9a9 9.56%, rgba(153, 151, 151, 0.28) 48.59%, rgba(255, 255, 255, 0) 97.72%);
  }
  .header .navbar {
    padding: 5px 20px;
  }

  .header.inner {
    background: none;
  }

  .carousel-caption {
    top: 25%;
  }
  .carousel-caption h3 {
    font-size: 25px;
    line-height: 25px;
  }
  .carousel-caption p {
    font-size: 13px;
    margin-bottom: 20px;
  }
  .carousel-caption p:last-child {
    max-width: 40px;
    margin-left: 10px;
  }
  .carousel-caption a {
    font-size: 10px !important;
    padding: 6px 15px;
  }
}
span.partner-link {
  position: fixed;
  z-index: 99;
  display: block;
  background: #07102a;
  right: -37px;
  top: 50%;
  transform: rotate(-90deg);
  text-align: center;
  padding: 10px 15px;
  color: #fff;
  font-size: 20px;
  font-weight: 300;
  line-height: 20px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.carousel .mouse-btn {
  display: block !important;
}
.carousel .mouse-btn a {
  background-image: url(../images/icons/mouse_icon.webp);
  background-repeat: no-repeat;
  bottom: 8%;
  content: "";
  display: block;
  height: 60px;
  left: 50%;
  margin-left: -20px;
  position: absolute;
  width: 40px;
  z-index: 9;
  -webkit-animation-name: floating;
  animation-name: floating;
  -webkit-animation-duration: 3s;
  animation-duration: 3s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
}

.ugi {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  background: linear-gradient(89.96deg, rgba(255, 255, 255, 0.34) -2.13%, rgba(255, 255, 255, 0.46) 102.5%);
  border-bottom: 1px solid rgba(255, 255, 255, 0.95);
  width: 100%;
  top: 9.6%;
  left: 0;
  right: 0;
  padding: 10px 120px;
}
.ugi__global-logo {
  margin-right: 20px;
}
.ugi p {
  font-size: 20px;
  font-weight: 600;
  line-height: 20px;
  margin: 0;
  color: #ff0000;
}

.about-us {
  padding: 100px 0;
  background: #fffaf0;
  position: relative;
  z-index: 0;
}
.about-us .about-img {
  position: relative;
}
.about-us .about-img::after {
  position: absolute;
  content: "";
  background-color: #ffb41f;
  width: 80%;
  height: 100%;
  z-index: -1;
  top: 5%;
  right: 15%;
}
.about-us .about-img h2 {
  transform: rotate(270deg);
  position: absolute;
  right: -60px;
  bottom: 38%;
  color: #fff;
  font-size: 50px;
  font-weight: 600;
}
.about-us .about-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100%;
}
.about-us .about-text h3 {
  font-size: 27px;
  font-weight: 700;
  line-height: 42px;
  color: #2b3758;
  margin-bottom: 50px;
}
.about-us .about-text p {
  font-size: 18px;
  color: #2b3758;
  line-height: 32px;
  font-weight: 400;
}
.about-us .about-text p span {
  font-weight: 600;
}

.home-video {
  background: #fffaf0;
  padding: 100px 0;
  border-top: 1px solid #e1e1e1;
}
.home-video__text p {
  font-size: 18px;
  color: #2b3758;
  line-height: 28px;
  font-weight: 400;
}
.home-video__text p span {
  font-weight: 600;
}
.home-video .swiper-button-prev:after {
  color: #fff;
  font-size: 30px;
}
.home-video .swiper-button-next:after {
  color: #fff;
  font-size: 30px;
}

.short-intro {
  padding: 100px 0;
}
.short-intro p {
  text-align: center;
  font-size: 17px;
  line-height: 28px;
}
.short-intro h4 {
  text-align: center;
  margin-block: 25px;
  font-weight: 600;
}

.home-services {
  padding: 100px 0;
  background-color: #f9f9f9;
}
.home-services h1 {
  font-size: 60px;
  font-weight: 700;
  line-height: 100px;
  color: #2b3758;
  text-align: center;
  margin-bottom: 10px;
}
.home-services h1 span {
  color: #ffb41f;
}
.home-services p {
  text-align: center;
  font-family: Poppins;
  font-size: 20px;
  font-weight: 400;
  max-width: 510px;
  margin: 0 auto;
}
.home-services__service-wrapper {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-top: 50px;
}
.home-services__service-box {
  text-align: center;
  width: 20%;
}
.home-services__service-box img {
  border-radius: 50%;
  margin-bottom: 15px;
  border: 3px dashed #ffb41f;
  padding: 8px;
}
.home-services__service-box h6 {
  font-size: 20px;
  line-height: 26px;
  margin: 0;
}
.home-services__service-box a {
  text-decoration: none;
  color: #2b3758 !important;
}

.solutions {
  padding: 100px 0 0 !important;
}
.solutions .col-xxl-3 {
  width: 20%;
}
.solutions a {
  text-decoration: none;
}
.solutions .text-left h1 {
  font-size: 80px;
  font-weight: 700;
  line-height: 80px;
  color: #2b3758;
  font-size: 73px;
  line-height: 90px;
}
.solutions .text-left h1 span {
  color: #ffb41f;
}
.solutions .text-right p {
  font-size: 20px;
  font-weight: 400;
  line-height: 38px;
  margin-top: 15px;
}
.solutions .solution-box {
  background: none;
  padding: 50px;
  min-height: 325px;
  display: block;
}
.solutions .solution-box:hover h4 {
  color: #fff;
}
.solutions .solution-box:hover p {
  color: #fff;
}
.solutions .solution-box h4 {
  color: #2b3758;
  margin-bottom: 15px;
  font-weight: 600;
  font-size: 20px;
}
.solutions .solution-box p {
  color: #2b3758;
  font-size: 16px;
  line-height: 21px;
  font-weight: 400;
  margin: 0;
}
.solutions .box-one {
  background: url(../images/Business-Operations.webp);
  background-position: center;
  box-shadow: 5px 5px 20px #b7b7b7;
  cursor: pointer;
}
.solutions .box-one h4,
.solutions .box-one p {
  color: #fff;
}
.solutions .box-two:hover {
  background: url(../images/Data-Analytics.webp);
  background-position: center;
  box-shadow: 5px 5px 20px #b7b7b7;
  cursor: pointer;
}
.solutions .box-three:hover {
  background: url(../images/Digital-Engineering.webp);
  background-position: center;
  box-shadow: 5px 5px 20px #b7b7b7;
  cursor: pointer;
}
.solutions .box-four:hover {
  background: url(../images/cloud.webp);
  background-position: center;
  box-shadow: 5px 5px 20px #b7b7b7;
  cursor: pointer;
}
.solutions .top-space {
  margin-top: 50px;
}

.solutions {
  padding-top: 0;
  padding-bottom: 0;
}

.solution-box h4 {
  font-size: 28px;
  /*line-height: 35px;*/
}
.solution-box p {
  font-size: 16px;
  line-height: 25px;
}

.home-add-section .nutri-add {
  padding-top: 80px;
  background: rgba(239, 255, 233, 0.68);
}
.home-add-section .nutri-add .add-img {
  position: relative;
}
.home-add-section .nutri-add .add-img::before {
  position: absolute;
  content: "";
  width: 250px;
  height: 40px;
  left: -14px;
  bottom: 0px;
  background-color: #5dc267;
}
.home-add-section .nutri-add .digi-add-img-holder::before {
  background-color: #0075ff;
}
.home-add-section .nutri-add.add-bg-blue {
  background: #f0fdff;
}
.home-add-section .add-info {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
}
.home-add-section .add-info .add-content {
  padding-left: 75px;
}
.home-add-section .add-info .add-content h1 {
  font-size: 90px;
  font-weight: 700;
  margin-bottom: 25px;
  color: #5dc267;
}
.home-add-section .add-info .add-content h1 span {
  color: #441c29;
}
.home-add-section .add-info .add-content h1.title-blue {
  color: #000;
  font-weight: 300;
}
.home-add-section .add-info .add-content h1.title-blue span {
  color: #0075ff;
  font-weight: 700;
}
.home-add-section .add-info .add-content p {
  font-size: 25px;
  line-height: 33px;
  font-weight: 400;
  margin-bottom: 50px;
}
.home-add-section .add-info .add-content a {
  color: #000;
  border: 2px solid #000;
  text-decoration: none;
  font-size: 20px;
  font-weight: 600;
  padding: 10px 30px;
}

.product-section {
  padding: 100px 0 0;
  position: relative;
  margin-bottom: 100px;
}
.product-section::before {
  position: absolute;
  content: "";
  width: 136px;
  height: 150px;
  background: url(../images/shapes/Ellipse.webp);
  background-position: center;
  left: 5%;
  top: 0;
  background-position: center;
  left: -3%;
  top: 7%;
}
.product-section::after {
  position: absolute;
  content: "";
  width: 100px;
  height: 225px;
  border-radius: 12px;
  background: rgba(255, 180, 31, 0.1);
  right: 0;
  transform: rotate(90deg);
  top: 0;
  right: 0;
  transform: rotate(90deg);
  top: -10%;
}
.product-section h1 {
  font-size: 80px;
  font-weight: 700;
  line-height: 80px;
  color: #2b3758;
  margin-bottom: 70px;
}
.product-section h1 span {
  color: #ffb41f;
}
.product-section .products {
  padding-top: 38px;
}
.product-section .products .bg-img-wrapper {
  background: url(../images/screenshot/Medvantage.webp);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  padding: 100px 0;
}
.product-section .products .bg-img-wrapper .number {
  background-color: #ffb41f;
  height: 100px;
  width: 80px;
  color: #fff;
  font-size: 70px;
  font-weight: 700;
  text-align: center;
  position: absolute;
  top: 0;
  right: 17%;
}
.product-section .products .bg-img-wrapper .detail-container {
  background-color: #fff;
  width: 66%;
  height: auto;
  margin: 0 auto;
  padding: 50px;
  box-shadow: 0 0 8px #07102a;
}
.product-section .products .bg-img-wrapper .detail-container .logo {
  margin-bottom: 20px;
  width: 30%;
  min-height: 50px;
}
.product-section .products .bg-img-wrapper .detail-container .content {
  margin-bottom: 50px;
  font-size: 16px;
  line-height: 30px;
  font-weight: 400;
}
.product-section .products .bg-img-wrapper .detail-container .buttons-wrapper a {
  background-color: #388697;
  color: #fff;
  text-decoration: none;
  border: 1px solid #388697;
  padding: 7px 10px;
}
.product-section .products .bg-img-wrapper .detail-container .buttons-wrapper a:last-child {
  background-color: transparent !important;
  color: #388697;
  font-size: 16px;
  font-weight: 600;
  margin-left: 5px;
}
.product-section .products .swiper-slide-prev {
  position: relative;
}
.product-section .products .swiper-slide-prev::before {
  top: 0;
  left: 0;
  position: absolute;
  content: "";
  background: rgba(255, 255, 255, 0.5490196078);
  width: 100%;
  height: 100%;
}
.product-section .products .swiper-slide-next {
  position: relative;
}
.product-section .products .swiper-slide-next::before {
  top: 0;
  left: 0;
  position: absolute;
  content: "";
  background: rgba(255, 255, 255, 0.5490196078);
  width: 100%;
  height: 100%;
}
.product-section .products .digi-doctor {
  background: url(../images/screenshot/DigiDoctor.webp);
}
.product-section .products .nutri {
  background: url(../images/screenshot/NutriAnalyser.webp);
}
.product-section .products .care {
  background: url(../images/screenshot/Care.webp);
}
.product-section .products .his {
  background: url(../images/screenshot/HIS.webp);
}
.product-section .products .edumation {
  background: url(../images/screenshot/Edumation.webp);
}
.product-section .products .corncall {
  background: url(../images/screenshot/CornCall.webp);
}
.product-section .products .medico {
  background: url(../images/screenshot/MedicoSkills.webp);
}
.product-section .products .lms {
  background: url(../images/screenshot/LMS.webp);
}

.services .home-service-main .services-left li {
  font-size: 25px;
  line-height: 50px;
}
.services h1 {
  font-size: 150px;
  margin-bottom: -37px;
}

/*.sec-achievement-heading {
  padding: 50px 0;
  &::before {
    display: none;
  }
  &::after {
    display: none;
  }
  h1 {
    font-size: 40px;
    line-height: 45px;
    &::before {
      display: none;
    }
    &::after {
      display: none;
    }
  }
}*/
.sec-achievement .swiper-button-prev {
  height: 21px;
  width: 21px;
}
.sec-achievement .swiper-button-prev::before {
  font-size: 12px;
}
.sec-achievement .swiper-button-prev::after {
  font-size: 12px;
}
.sec-achievement .swiper-button-next {
  height: 21px;
  width: 21px;
}
.sec-achievement .swiper-button-next::before {
  font-size: 12px;
}
.sec-achievement .swiper-button-next::after {
  font-size: 12px;
}

.choose h1 {
  font-size: 40px;
  font-weight: 700;
  line-height: 50px;
  color: #2b3758;
  text-align: left;
  margin-bottom: 50px;
}
.choose h1 span {
  color: #ffb41f;
}
.choose__content h4 {
  margin-block: 20px;
  font-size: 22px;
  font-weight: 500;
}
.choose__content h4 span {
  color: #ffb41f;
}
.choose__content p {
  font-size: 16px;
  line-height: 26px;
  font-weight: 400;
  color: #2b3758;
  margin: 0;
}

.sec-client h1 {
  font-size: 55px;
  line-height: 65px;
}
.sec-client h3 {
  font-size: 30px;
}
.sec-client p {
  width: 45%;
}

.Video-testimonial {
  margin: 100px 0;
}
.Video-testimonial h1 {
  font-size: 80px;
  font-weight: 700;
  line-height: 80px;
  color: #2b3758;
  position: relative;
}
.Video-testimonial h1 span {
  color: #ffb41f;
}
.Video-testimonial .video-box {
  width: 70%;
  margin: 0 auto;
}
.Video-testimonial .swiper-button-prev {
  left: 0px !important;
}
.Video-testimonial .swiper-button-prev:after {
  color: #ffb41f;
}
.Video-testimonial .swiper-button-next {
  right: 0px !important;
}
.Video-testimonial .swiper-button-next:after {
  color: #ffb41f;
}

.sec-achievement-heading {
  padding: 100px 0 50px;
  position: relative;
}
.sec-achievement-heading::after {
  position: absolute;
  content: "";
  width: 131px;
  height: 134px;
  background: url(../images/shapes/Ellipse1.webp);
  background-position: center;
  top: 25%;
  right: -2%;
  background-position: center;
  top: 25%;
  left: -3%;
}
.sec-achievement-heading::before {
  position: absolute;
  content: "";
  width: 100px;
  height: 225px;
  border-radius: 12px;
  background: rgba(255, 180, 31, 0.1);
  right: 0;
  transform: rotate(90deg);
  top: 0;
  transform: rotate(90deg);
  top: -10%;
}
.sec-achievement-heading h1 {
  font-size: 80px;
  font-weight: 700;
  line-height: 80px;
  color: #2b3758;
  font-size: 40px;
  line-height: 50px;
}
.sec-achievement-heading h1 span {
  color: #ffb41f;
}

.sec-achievement {
  padding: 0 0 100px 0px;
  position: relative;
}
.sec-achievement::before {
  position: absolute;
  content: "";
  max-width: 320px;
  width: 100%;
  min-height: 454px;
  background: linear-gradient(146.08deg, #2b3758 -0.65%, #2a9d8f 102.53%);
  left: 0;
  top: 0;
  z-index: 9;
  display: none;
}
.sec-achievement .img-box img {
  width: 100%;
  max-width: 267px;
  height: 300px;
  object-fit: cover;
}
.sec-achievement .swiper-button-prev {
  color: #fff;
  background-color: #fff;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  position: relative;
  margin-right: 40px;
}
.sec-achievement .swiper-button-prev::after {
  font-size: 18px;
  color: #000;
  font-weight: 700;
}
.sec-achievement .swiper-button-prev:hover {
  background-color: #ffb41f;
}
.sec-achievement .swiper-button-prev:hover::after {
  color: #fff;
}
.sec-achievement .swiper-button-next {
  color: #fff;
  background-color: #fff;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  position: relative;
}
.sec-achievement .swiper-button-next::after {
  font-size: 18px;
  color: #000;
  font-weight: 700;
}
.sec-achievement .swiper-button-next:hover {
  background-color: #ffb41f;
}
.sec-achievement .swiper-button-next:hover::after {
  color: #fff;
}
.sec-achievement .left-gradient {
  background: linear-gradient(146.08deg, #2b3758 -0.65%, #2a9d8f 102.53%);
  display: flex;
  justify-content: center;
  height: 100%;
  width: 95%;
}

.our-credential {
  padding: 50px 0;
  position: relative;
}
.our-credential h1 {
  font-size: 80px;
  font-weight: 700;
  line-height: 80px;
  color: #2b3758;
  font-size: 40px;
  line-height: 50px;
  padding-bottom: 30px;
}
.our-credential h1 span {
  color: #ffb41f;
}
.our-credential p {
  font-size: 18px;
  font-weight: 400;
}
.our-credential .swiper-slide .logo-box {
  width: 222px;
  text-align: center;
}
.our-credential .swiper-slide .logo-box img {
  width: auto;
  height: 80px;
}

.sec-client {
  padding: 100px 0;
  position: relative;
}
.sec-client::before {
  position: absolute;
  content: "";
  width: 100px;
  height: 225px;
  border-radius: 12px;
  background: rgba(255, 180, 31, 0.1);
  right: 0;
  transform: rotate(90deg);
  top: 0;
  left: 0%;
  top: 20%;
  transform: rotate(0deg);
  height: 350px;
}
.sec-client::after {
  position: absolute;
  content: "";
  width: 175px;
  height: 175px;
  border-radius: 50%;
  background: rgba(187, 189, 194, 0.6);
  right: -4%;
  top: 25%;
  right: -4%;
  top: 25%;
}
.sec-client h1 {
  font-size: 80px;
  font-weight: 700;
  line-height: 80px;
  color: #2b3758;
  position: relative;
}
.sec-client h1 span {
  color: #ffb41f;
}
.sec-client h3 {
  font-size: 40px;
  font-weight: 400;
}
.sec-client p {
  width: 60%;
  text-align: center;
  margin: 0 auto;
  font-size: 18px;
  font-weight: 400;
  line-height: 30px;
}

.success-message {
  color: #5dc267;
  font-size: 20px;
  border: 1px solid #5dc267;
  border-radius: 5px;
  padding: 5px 10px;
}

.error-message {
  color: #ff0000;
  font-size: 20px;
  border: 1px solid #ff0000;
  border-radius: 5px;
  padding: 5px 10px;
}

.healthcare {
  position: relative;
}
.healthcare__healthcare-content-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.healthcare__healthcare-content {
  background-color: #fff;
  position: absolute;
  left: 5%;
  width: 50%;
  padding: 50px;
}
.healthcare__healthcare-content h3 {
  color: #006b60;
  font-size: 30px;
  font-weight: 500;
}
.healthcare__healthcare-content p {
  color: #2b3758;
  font-size: 20px;
  font-weight: 400;
}

@media (max-width: 1440px) and (min-width: 1366px) {
  .solutions .text-left h1 {
    font-size: 59px !important;
    line-height: 80px !important;
  }
  .solutions .text-right p {
    font-size: 16px;
    line-height: 32px;
  }
  .solutions .solution-box {
    padding: 40px;
  }
  .solutions .solution-box h4 {
    font-size: 16px;
    line-height: 20px;
  }
  .solutions .solution-box p {
    font-size: 14px;
    line-height: 18px;
  }
}
@media (max-width: 1399px) and (min-width: 1200px) {
  .about-us .about-img:after {
    width: 90%;
    left: 7%;
  }

  .about-us .about-text p {
    line-height: 27px;
  }

  .carousel-caption {
    left: 8% !important;
    top: 65% !important;
  }

  .solutions .text-left h1 {
    font-size: 70px;
    line-height: 80px;
  }

  .solutions .text-right p {
    font-size: 16px;
    font-weight: 400;
    line-height: 29px;
    margin-top: 15px;
    padding-left: 60px;
  }

  .about-us .about-img h2 {
    right: -138px;
    font-size: 55px;
  }

  .about-us .about-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 100%;
    padding-left: 50px;
  }

  .six-products__title-content h1 span {
    font-size: 75px !important;
  }

  .Video-testimonial .video-box {
    width: 60%;
  }
  .Video-testimonial .swiper-button-prev {
    left: 200px !important;
  }
  .Video-testimonial .swiper-button-next {
    right: 200px !important;
  }

  .logo-arab-health {
    width: 250px;
  }

  .home-services__service-box h6 {
    font-size: 18px;
  }
}
@media (max-width: 1199px) and (min-width: 992px) {
  .about-us .about-img h2 {
    font-size: 40px;
    right: -96px;
  }

  .about-us .about-img:after {
    right: 4%;
  }
  .about-us .about-text p {
    font-size: 16px;
    line-height: 24px;
  }

  .solutions .solution-box h4 {
    font-size: 16px;
    line-height: 20px;
  }

  .solutions .solution-box p {
    font-size: 14px;
    line-height: 20px;
  }

  .solutions .text-right p {
    font-size: 14px;
    line-height: 22px;
    padding-left: 0;
  }

  .services h1 {
    font-size: 150px !important;
    margin-bottom: -37px !important;
  }

  .services .home-service-main .services-left li {
    font-size: 25px !important;
    line-height: 50px !important;
  }

  .services .services-img img {
    height: auto;
  }

  .product-section .buttons-wrapper a {
    font-size: 14px !important;
  }

  .six-products__title-content h1 {
    font-size: 60px !important;
  }
  .six-products__title-content h1 span {
    font-size: 65px !important;
  }
  .six-products__yellow-circle {
    width: 300px !important;
    height: 300px !important;
  }
  .six-products .mdvntg-img,
.six-products .endo-img,
.six-products .intravenous-img {
    height: 150px !important;
  }
  .six-products .ams-img {
    height: 200px !important;
  }
  .six-products .vs-img {
    height: 280px !important;
  }
  .six-products .dg-ste {
    height: 420px !important;
  }
  .six-products .iv-fluid-img {
    height: 270px !important;
  }
  .six-products .amblnc-img {
    height: 135px !important;
  }
  .six-products .oxgn-img {
    height: 280px !important;
  }
  .six-products .urn-img {
    height: 250px !important;
  }
  .six-products .in-pump-img {
    height: 310px !important;
  }
  .six-products .foot-img {
    height: 140px !important;
  }
  .six-products .hotler-img {
    height: 230px !important;
  }
  .six-products .oxgn-flow-img {
    height: 170px !important;
  }
  .six-products .patient-img {
    height: 200px !important;
  }
  .six-products .bmi-img {
    height: 250px !important;
  }

  .content-centered p {
    font-size: 20px !important;
  }

  .points-wrapper__points ul li {
    font-size: 16px !important;
  }

  .home-video__text p {
    font-size: 16px;
    line-height: 24px;
  }

  .Video-testimonial .video-box {
    width: 70%;
  }
  .Video-testimonial .swiper-button-prev {
    left: 80px !important;
  }
  .Video-testimonial .swiper-button-next {
    right: 80px !important;
  }

  .logo-arab-health {
    width: 200px;
  }

  .btn-outline-white {
    width: 180px !important;
    height: 50px !important;
    font-size: 22px !important;
    line-height: 50px !important;
  }

  .home-services__service-box h6 {
    font-size: 16px;
  }

  .healthcare__healthcare-content {
    padding: 25px 50px;
    left: 0;
  }
  .healthcare__healthcare-content h3 {
    font-size: 24px;
  }
  .healthcare__healthcare-content p {
    font-size: 16px;
  }
}
@media (max-width: 991px) and (min-width: 768px) {
  .sec-client h1 {
    font-size: 55px;
    line-height: 65px;
  }

  .about-us .about-text h3 {
    font-size: 18px;
    line-height: 25px;
    margin-bottom: 30px;
  }

  .about-us .about-text p {
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 10px !important;
  }

  .sec-client p {
    width: 45%;
  }

  .about-us .about-img h2 {
    font-size: 30px;
    right: -83px;
  }

  .solutions .text-left h1 {
    font-size: 67px;
    line-height: 90px;
    margin-bottom: 20px;
  }

  .about-us .about-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 100%;
    padding-left: 50px;
  }

  .solutions .text-right p {
    font-size: 16px;
    line-height: 28px;
    padding-left: 0;
    padding-right: 0;
  }

  .solutions .col-xxl-3 {
    width: 33%;
  }
  .solutions .solution-box {
    padding: 40px 20px;
  }

  .product-section .products .bg-img-wrapper {
    padding: 35px 0;
  }
  .product-section .products .bg-img-wrapper .number {
    height: 35px;
    width: 35px;
    font-size: 25px;
  }
  .product-section .products .bg-img-wrapper .detail-container {
    padding: 25px;
  }
  .product-section .products .bg-img-wrapper .detail-container .content {
    font-size: 14px;
    line-height: 22px;
    margin-bottom: 25px;
  }
  .product-section .products .bg-img-wrapper .detail-container .logo {
    width: 50%;
  }
  .product-section h1 {
    font-size: 50px;
    line-height: 55px;
  }

  .six-products__title-content h1 {
    font-size: 40px !important;
  }
  .six-products__title-content h1 span {
    font-size: 50px !important;
  }
  .six-products__title-content h3 {
    font-size: 35px !important;
  }
  .six-products__yellow-circle {
    width: 235px !important;
    height: 235px !important;
  }
  .six-products .mdvntg-img,
.six-products .endo-img,
.six-products .intravenous-img {
    height: 120px !important;
  }
  .six-products .ams-img {
    height: 150px !important;
  }
  .six-products .pct-img {
    height: 200px !important;
  }
  .six-products .pk-img {
    width: 45px !important;
    height: 250px !important;
  }
  .six-products .vs-img {
    height: 280px !important;
  }
  .six-products .dg-ste {
    height: 330px !important;
  }
  .six-products .iv-fluid-img {
    height: 210px !important;
  }
  .six-products .amblnc-img {
    height: 110px !important;
  }
  .six-products .oxgn-img {
    height: 200px !important;
  }
  .six-products .urn-img {
    height: 200px !important;
  }
  .six-products .in-pump-img {
    height: 245px !important;
  }
  .six-products .foot-img {
    height: 110px !important;
  }
  .six-products .hotler-img {
    height: 180px !important;
  }
  .six-products .oxgn-flow-img {
    height: 130px !important;
  }
  .six-products .patient-img {
    height: 160px !important;
  }
  .six-products .bmi-img {
    height: 180px !important;
  }

  .content-centered p {
    font-size: 18px !important;
  }

  .points-wrapper__box {
    display: block !important;
  }
  .points-wrapper__points {
    width: 100% !important;
  }

  .home-video__text p {
    font-size: 14px;
    line-height: 20px;
  }

  .Video-testimonial h1 {
    font-size: 55px;
    line-height: 65px;
  }
  .Video-testimonial .video-box {
    width: 60%;
  }
  .Video-testimonial .swiper-button-prev {
    left: 80px !important;
  }
  .Video-testimonial .swiper-button-next {
    right: 80px !important;
  }

  .logo-arab-health {
    width: 200px;
    top: 80%;
  }

  .ugi {
    top: 16%;
  }

  .home-services h1 {
    font-size: 50px;
    line-height: 70px;
  }
  .home-services__service-wrapper {
    row-gap: 40px;
  }
  .home-services__service-box {
    width: 30%;
  }
  .home-services__service-box h6 {
    font-size: 16px;
  }

  .choose h1 {
    font-size: 40px;
    margin-bottom: 50px;
  }
  .choose__content h4 {
    font-size: 17px;
    line-height: 22px;
    font-weight: 500;
  }
  .choose__content p {
    font-size: 14px;
    line-height: 22px;
  }

  .healthcare__healthcare-content {
    padding: 25px 50px;
    bottom: -55%;
    left: 0;
    right: 0;
    margin: 0 auto;
  }
  .healthcare__healthcare-content h3 {
    font-size: 22px;
  }
  .healthcare__healthcare-content p {
    font-size: 15px;
  }

  .Video-testimonial {
    margin-top: 400px;
  }
}
@media (max-width: 767px) {
  .home-banner__single-img img {
    height: auto !important;
  }

  .about-us .about-img:after {
    right: 11%;
  }

  .solutions .col-xxl-3 {
    width: 50%;
  }

  .sec-achievement-heading {
    padding: 50px 0;
  }

  .sec-client {
    padding-top: 0;
    padding-bottom: 50px;
  }

  .solutions .text-right p {
    font-size: 16px;
    line-height: 25px;
    padding-left: 0;
  }

  .about-us .about-text {
    margin-top: 60px;
    padding-left: 0;
  }

  .solutions {
    padding-bottom: 0 !important;
  }

  .about-us {
    padding-bottom: 50px;
    padding-top: 50px;
  }

  .sec-achievement-heading {
    position: relative;
  }
  .sec-achievement-heading::after {
    display: none;
  }
  .sec-achievement-heading::before {
    display: none;
  }

  .sec-client::after {
    display: none;
  }
  .sec-client::before {
    display: none;
  }

  .sec-client h1::after {
    display: none;
  }
  .sec-client h1::before {
    display: none;
  }

  .about-us .about-img h2 {
    font-size: 50px;
    right: -82px;
  }

  .sec-client h3 {
    font-size: 20px;
  }

  .sec-client p {
    width: 80%;
  }

  .sec-client h1 {
    font-size: 35px;
    line-height: 45px;
  }

  .sec-achievement-heading h1 {
    font-size: 40px;
    line-height: 45px;
  }

  .carousel-caption {
    top: 30%;
  }
  .carousel-caption h3 {
    font-size: 40px;
    line-height: 60px;
  }
  .carousel-caption p {
    font-size: 18px;
  }
  .carousel-caption p:last-child {
    max-width: 70px;
  }
  .carousel-caption a {
    font-size: 13px !important;
  }

  .product-section {
    padding-bottom: 0;
  }
  .product-section .products .bg-img-wrapper {
    padding: 30px 0;
  }
  .product-section .products .bg-img-wrapper .number {
    height: 30px;
    width: 30px;
    font-size: 22px;
    right: 8%;
  }
  .product-section .products .bg-img-wrapper .detail-container {
    width: 85%;
    padding: 20px;
  }
  .product-section .products .bg-img-wrapper .detail-container .logo {
    width: 60%;
  }
  .product-section .products .bg-img-wrapper .detail-container .content {
    margin-bottom: 25px;
    font-size: 13px;
    line-height: 20px;
  }
  .product-section::after {
    display: none;
  }
  .product-section::before {
    display: none;
  }
  .product-section h1 {
    font-size: 35px;
    line-height: 38px;
    margin-bottom: 50px;
  }

  .inner-solution {
    padding-bottom: 0;
  }
  .inner-solution::before {
    display: none;
  }
  .inner-solution h4 {
    font-size: 28px;
    line-height: 38px;
    margin-top: 25px;
  }
  .inner-solution p {
    font-size: 18px;
    line-height: 25px;
  }
  .inner-solution .inner-solution-content {
    margin-top: 50px;
  }

  .inner-about {
    padding-top: 50px;
  }
  .inner-about::before {
    display: none;
  }
  .inner-about::after {
    display: none;
  }
  .inner-about h1 {
    font-size: 65px;
    letter-spacing: 0;
    margin-bottom: -75px;
  }
  .inner-about h3 {
    font-size: 25px;
    line-height: 35px;
    margin-bottom: 50px;
    margin-bottom: 0;
  }

  .boost {
    padding: 100px 0;
  }
  .boost h3 {
    font-size: 25px;
    line-height: 45px;
  }
  .boost a {
    font-size: 14px;
  }

  .solutions .text-left h1 {
    font-size: 55px;
    line-height: 65px;
  }

  .services img {
    height: auto !important;
  }

  .six-products::before {
    display: none;
  }
  .six-products__title-content h1 span {
    font-size: 60px !important;
  }
  .six-products__title-content h3 {
    font-size: 30px !important;
    margin-bottom: 50px;
  }
  .six-products__yellow-circle {
    margin: 50px auto 0;
  }

  .content-centered p {
    font-size: 18px !important;
  }

  .points-wrapper__box {
    display: block !important;
    padding-left: 0 !important;
  }
  .points-wrapper__points {
    width: 100% !important;
  }
  .points-wrapper__points ul li {
    text-align: center;
  }
  .points-wrapper__points ul li p {
    padding: 0 !important;
  }
  .points-wrapper__points ul li i {
    float: none !important;
    display: block;
  }
  .points-wrapper::after {
    display: none;
  }

  .Video-testimonial h1 {
    font-size: 35px;
    line-height: 45px;
  }
  .Video-testimonial .video-box {
    width: 60%;
  }
  .Video-testimonial .swiper-button-prev {
    left: 80px !important;
  }
  .Video-testimonial .swiper-button-next {
    right: 80px !important;
  }

  .logo-arab-health {
    display: none;
  }

  .home-services h1 {
    font-size: 35px;
    line-height: 38px;
  }
  .home-services__service-wrapper {
    row-gap: 40px;
  }
  .home-services__service-box {
    width: 100%;
  }

  .choose h1 {
    font-size: 40px;
    line-height: 50px;
    margin-bottom: 50px;
  }
  .choose__content {
    text-align: center;
    margin-block-end: 25px;
  }
  .choose__picture {
    text-align: center;
  }

  .healthcare__healthcare-content {
    padding: 25px 50px;
    bottom: -55%;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 80%;
  }
  .healthcare__healthcare-content h3 {
    font-size: 22px;
  }
  .healthcare__healthcare-content p {
    font-size: 15px;
  }

  .Video-testimonial {
    margin-top: 350px;
  }
}
@media (max-width: 575px) {
  .inner-business h3 {
    font-size: 23px;
    line-height: 32px;
  }

  .about-us .about-img h2 {
    right: -89px;
    font-size: 40px;
  }

  .about-us .about-img:after {
    width: 50%;
    right: 6%;
  }

  .carousel-caption h3 {
    font-size: 25px;
    line-height: 25px;
  }

  .carousel-caption {
    top: 25%;
  }

  .carousel .mouse-btn {
    display: none !important;
  }

  .sec-client p {
    font-size: 15px;
    line-height: 22px;
    padding: 0 10px;
  }

  .sec-achievement .swiper-button-prev {
    height: 25px;
    width: 25px;
    margin-right: 25px;
  }
  .sec-achievement .swiper-button-prev::before {
    font-size: 12px;
  }
  .sec-achievement .swiper-button-prev::after {
    font-size: 12px;
  }
  .sec-achievement .swiper-button-next {
    height: 25px;
    width: 25px;
  }
  .sec-achievement .swiper-button-next::before {
    font-size: 12px;
  }
  .sec-achievement .swiper-button-next::after {
    font-size: 12px;
  }

  .product-section .products .bg-img-wrapper {
    padding: 25px 0;
  }
  .product-section .products .bg-img-wrapper .number {
    height: 25px;
    width: 25px;
    font-size: 18px;
  }
  .product-section .products .bg-img-wrapper .detail-container {
    padding-top: 30px;
  }
  .product-section .products .bg-img-wrapper .detail-container .content {
    margin-bottom: 25px;
    font-size: 13px;
  }

  .home-video {
    padding: 50px 0;
  }
  .home-video__text p {
    font-size: 15px;
    line-height: 25px;
  }

  .content-centered p {
    font-size: 16px !important;
  }

  .six-products__title-content p {
    font-size: 16px !important;
  }
}
@media (max-width: 516px) and (min-width: 375px) {
  .solutions .solution-box {
    min-height: auto;
    padding: 30px;
  }
  .solutions .solution-box h4 {
    font-size: 18px;
    line-height: 28px;
  }
  .solutions .solution-box p {
    font-size: 13px;
    line-height: 20px;
  }

  .six-products__title-content h1 {
    font-size: 35px !important;
  }
  .six-products__title-content h1 span {
    font-size: 36px !important;
  }
  .six-products__title-content h3 {
    font-size: 20px !important;
  }
  .six-products__yellow-circle {
    width: 250px !important;
    height: 250px !important;
  }
  .six-products .mdvntg-img,
.six-products .endo-img,
.six-products .intravenous-img {
    height: 125px !important;
  }
  .six-products .ams-img {
    height: 150px !important;
  }
  .six-products .pct-img {
    height: 200px !important;
  }
  .six-products .pk-img {
    width: 45px !important;
    height: 250px !important;
  }
  .six-products .vs-img {
    height: 280px !important;
  }
  .six-products .dg-ste {
    height: 330px !important;
  }
  .six-products .iv-fluid-img {
    height: 225px !important;
  }
  .six-products .amblnc-img {
    height: 115px !important;
  }
  .six-products .oxgn-img {
    height: 200px !important;
  }
  .six-products .urn-img {
    height: 200px !important;
  }
  .six-products .in-pump-img {
    height: 250px !important;
  }
  .six-products .foot-img {
    height: 120px !important;
  }
  .six-products .hotler-img {
    height: 180px !important;
  }
  .six-products .oxgn-flow-img {
    height: 140px !important;
  }
  .six-products .patient-img {
    height: 170px !important;
  }
  .six-products .bmi-img {
    height: 200px !important;
  }

  .Video-testimonial h1 {
    font-size: 25px;
    line-height: 40px;
  }
  .Video-testimonial .video-box {
    width: 75%;
  }
  .Video-testimonial .swiper-button-prev {
    left: 20px !important;
  }
  .Video-testimonial .swiper-button-prev:after {
    font-size: 25px !important;
  }
  .Video-testimonial .swiper-button-next {
    right: 20px !important;
  }
  .Video-testimonial .swiper-button-next:after {
    font-size: 25px !important;
  }
}
@media (max-width: 414px) {
  .ugi {
    top: 0;
    padding: 10px 20px;
  }
  .ugi p {
    font-size: 10px;
  }

  .solutions .col-xxl-3 {
    width: 100%;
  }
  .solutions .box-one {
    background-size: cover;
  }

  .home-services h1 {
    font-size: 25px;
    line-height: 35px;
  }
  .home-services p {
    font-size: 16px;
    line-height: 25px;
  }

  .healthcare__healthcare-content {
    bottom: -80%;
    padding: 20px;
  }
  .healthcare__healthcare-content h3 {
    font-size: 18px;
  }
  .healthcare__healthcare-content p {
    font-size: 13px;
  }

  .Video-testimonial {
    margin-top: 300px;
  }
}
@media (max-width: 375px) {
  .solutions .text-left h1 {
    font-size: 26px !important;
    line-height: 42px !important;
  }

  .inner-solution h4 {
    text-align: center;
    font-size: 25px;
    line-height: 35px;
  }

  .solutions .solution-box p {
    font-size: 16px;
    line-height: 25px;
  }

  .solutions .solution-box h4 {
    font-size: 20px;
    /*line-height: 35px;*/
  }

  .carousel-caption h3 {
    margin: 0;
  }
  .carousel-caption p {
    margin-bottom: 0;
  }
  .carousel-caption a {
    padding: 2px 10px;
  }

  .product-section .products .bg-img-wrapper {
    padding: 35px 0;
  }
  .product-section .products .bg-img-wrapper .number {
    height: 35px;
    width: 40px;
    font-size: 22px;
  }
  .product-section .products .bg-img-wrapper .detail-container {
    padding-bottom: 20px !important;
  }

  .about-us .about-img h2 {
    font-size: 30px;
    right: -64px;
  }
  .about-us .about-text h3 {
    font-size: 19px !important;
  }

  .home-add-section .add-content {
    width: 65% !important;
  }

  .boost h3 {
    font-size: 18px;
    line-height: 30px;
  }

  .Video-testimonial .video-box {
    width: 80%;
  }
  .Video-testimonial .swiper-button-prev {
    left: 5px !important;
  }
  .Video-testimonial .swiper-button-prev:after {
    font-size: 25px !important;
  }
  .Video-testimonial .swiper-button-next {
    right: 5px !important;
  }
  .Video-testimonial .swiper-button-next:after {
    font-size: 25px !important;
  }

  .six-products .six-products__title-content h1 {
    font-size: 25px !important;
  }
  .six-products .six-products__title-content h1 span {
    font-size: 30px !important;
  }
  .six-products .six-products__title-content h3 {
    font-size: 20px !important;
  }
  .six-products__yellow-circle {
    width: 250px !important;
    height: 250px !important;
  }
  .six-products .mdvntg-img,
.six-products .endo-img,
.six-products .intravenous-img {
    height: 120px !important;
  }
  .six-products .ams-img {
    height: 150px !important;
  }
  .six-products .pct-img {
    height: 200px !important;
  }
  .six-products .pk-img {
    width: 45px !important;
    height: 250px !important;
  }
  .six-products .vs-img {
    height: 280px !important;
  }
  .six-products .dg-ste {
    height: 330px !important;
  }
  .six-products .iv-fluid-img {
    height: 225px !important;
  }
  .six-products .amblnc-img {
    height: 115px !important;
  }
  .six-products .oxgn-img {
    height: 200px !important;
  }
  .six-products .urn-img {
    height: 200px !important;
  }
  .six-products .in-pump-img {
    height: 250px !important;
  }
  .six-products .foot-img {
    height: 120px !important;
  }
  .six-products .hotler-img {
    height: 180px !important;
  }
  .six-products .oxgn-flow-img {
    height: 140px !important;
  }
  .six-products .patient-img {
    height: 170px !important;
  }
  .six-products .bmi-img {
    height: 200px !important;
  }

  .healthcare__healthcare-content {
    bottom: -100%;
  }
}
@media (max-width: 320px) {
  .Video-testimonial h1 {
    font-size: 25px;
    line-height: 40px;
  }
  .Video-testimonial .video-box {
    width: 80%;
  }
  .Video-testimonial .swiper-button-prev {
    left: 0 !important;
  }
  .Video-testimonial .swiper-button-next {
    right: 0 !important;
  }

  .home-services h1 {
    font-size: 22px;
    line-height: 32px;
  }

  .healthcare__healthcare-content {
    bottom: -130%;
  }

  .Video-testimonial {
    margin-top: 350px;
  }
}
.inner-about h3 {
  font-size: 18px;
  line-height: 30px;
}
.inner-about h1 {
  font-size: 50px;
  margin-bottom: -78px;
}

.mission-vision {
  background: linear-gradient(94.83deg, #008070 -15.89%, #2a9d8f 49.97%, #2b3758 122.9%);
  padding: 150px 0 50px;
  margin-top: -100px;
  position: relative;
}
.mission-vision::before {
  position: absolute;
  content: "";
  width: 136px;
  height: 150px;
  background-position: center;
  background: url(../images/shapes/Ellipse-green.webp);
  left: 0;
  top: 3%;
  transform: rotate(10deg);
}
.mission-vision::after {
  position: absolute;
  content: "";
  width: 131px;
  height: 134px;
  background: url(../images/shapes/Ellipse1.webp);
  background-position: center;
  top: 25%;
  right: -2%;
  top: 82%;
  right: -1%;
  transform: rotate(140deg);
}
.mission-vision .mission-vision-content h2 {
  color: #ffb41f;
  font-size: 40px;
  font-weight: 600;
  line-height: 55px;
  margin-bottom: 10px;
  text-transform: uppercase;
}
.mission-vision .mission-vision-content p {
  color: #fff;
  font-size: 20px;
  font-weight: 300;
  line-height: 30px;
}
.mission-vision .divider {
  width: 70%;
  background-color: #fff;
  height: 1px;
  margin: 50px auto;
}
.mission-vision svg {
  transition: 0.5s;
}
.mission-vision svg:hover {
  transform: scale(1.3);
  cursor: pointer;
}

.inner-business h3 {
  font-size: 40px;
  line-height: 60px;
}

.inner-about {
  padding: 100px 0;
  position: relative;
  z-index: 1;
}
.inner-about::before {
  position: absolute;
  content: "";
  width: 136px;
  height: 150px;
  background: url(../images/shapes/Ellipse.webp);
  background-position: center;
  left: 5%;
  top: 0;
  background-position: center;
  left: -15px;
  top: 50px;
}
.inner-about::after {
  position: absolute;
  content: "";
  width: 100px;
  height: 225px;
  border-radius: 12px;
  background: rgba(255, 180, 31, 0.1);
  right: 0;
  transform: rotate(90deg);
  top: 0;
  right: 0%;
  top: 15%;
  transform: rotate(0deg);
}
.inner-about h3 {
  font-size: 55px;
  font-weight: 600;
  line-height: 80px;
  margin-bottom: 120px;
}
.inner-about h3 span {
  display: block;
}
.inner-about h3 span span {
  color: #ffb41f;
  display: inline-block;
  font-weight: 800;
}
.inner-about h1 {
  font-size: 180px;
  font-weight: 700;
  text-transform: uppercase;
  color: #e7e7e7;
  line-height: 180px;
  margin-top: -25px;
  letter-spacing: -10px;
  margin-bottom: -49px;
  position: relative;
  z-index: -2;
}

.inner-solution {
  padding: 100px 0 40px;
  position: relative;
}
.inner-solution::before {
  position: absolute;
  content: "";
  width: 100px;
  height: 225px;
  border-radius: 12px;
  background: rgba(255, 180, 31, 0.1);
  right: 0;
  transform: rotate(90deg);
  top: 0;
  left: 0;
  top: 3%;
  transform: rotate(0);
}
.inner-solution .inner-solution-img {
  position: relative;
  margin-top: 50px;
}
.inner-solution .inner-solution-img::before {
  position: absolute;
  content: "";
  background: linear-gradient(146.08deg, #2b3758 -0.65%, #2a9d8f 102.53%);
  z-index: -1;
  width: 70%;
  height: 100%;
  left: 0;
  top: 25px;
}
.inner-solution .inner-solution-img img {
  margin-left: 25px;
}
.inner-solution h4 {
  font-size: 40px;
  line-height: 60px;
  font-weight: 600;
  margin-bottom: 25px;
}
.inner-solution h4 span {
  color: #ffb41f;
}
.inner-solution p {
  font-size: 20px;
  font-weight: 400;
  line-height: 35px;
}

.inner-services {
  padding: 100px 0;
  position: relative;
}
.inner-services::before {
  position: absolute;
  content: "";
  width: 100px;
  height: 225px;
  border-radius: 12px;
  background: rgba(255, 180, 31, 0.1);
  right: 0;
  transform: rotate(90deg);
  top: 0;
  left: 0;
  top: 0%;
  transform: rotate(0);
}
.inner-services::after {
  position: absolute;
  content: "";
  width: 136px;
  height: 150px;
  background: url(../images/shapes/Ellipse.webp);
  background-position: center;
  left: 5%;
  top: 0;
  background-position: center;
  left: 2%;
  top: 80%;
}
.inner-services h5 {
  background: linear-gradient(89.94deg, #2b3758 -3.98%, #2a9d8f 103.74%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  font-weight: 600;
  font-size: 25px;
  margin-bottom: 20px;
}
.inner-services h3 {
  font-size: 50px;
  font-weight: 600;
  position: relative;
}
.inner-services h3::after {
  position: absolute;
  content: "";
  width: 141px;
  height: 191px;
  background: url(../images/shapes/Ellipse2.webp);
  background-position: center;
  top: -150px;
  right: -23%;
}
.inner-services h3 span {
  color: #ffb41f;
  font-weight: 700;
}
.inner-services .flip-card-holder {
  display: flex;
  flex-wrap: wrap;
}
.inner-services .flip-card-holder .flip-card {
  flex: 0 0 calc(33.33% - 30px);
  margin: 0 15px 30px;
  perspective: 1000px;
}
.inner-services .flip-card-holder .flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
}
.inner-services .flip-card-holder .flip-card .flip-card-inner {
  position: relative;
  transform-style: preserve-3d;
  transition: all 1s ease;
}
.inner-services .flip-card-holder .flip-card .flip-card-inner .flip-card-front img {
  width: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}
.inner-services .flip-card-holder .flip-card .flip-card-inner .flip-card-back {
  background: linear-gradient(146.08deg, #2b3758 -0.65%, #2a9d8f 102.53%);
  position: absolute;
  left: 0;
  top: 0;
  padding: 15px;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  transform: rotateY(180deg);
  backface-visibility: hidden;
}
.inner-services .flip-card-holder .flip-card .flip-card-inner .flip-card-back h4 {
  color: #ffb41f;
  text-transform: uppercase;
}
.inner-services a {
  color: #388697;
  border: 2px solid #388697;
  text-decoration: none;
  padding: 10px 20px;
  font-size: 20px;
  font-weight: 600;
  text-transform: uppercase;
}

.inner-business {
  padding: 100px 0;
}
.inner-business h5 {
  background: linear-gradient(89.94deg, #2b3758 -3.98%, #2a9d8f 103.74%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 600;
  margin-bottom: 20px;
}
.inner-business h3 {
  font-size: 48px;
  font-weight: 600;
  line-height: 70px;
  color: #2b3758;
  margin-bottom: 45px;
}
.inner-business h3 span {
  color: #ffb41f;
  font-weight: 700;
}
.inner-business a {
  background: linear-gradient(146.08deg, #2b3758 -0.65%, #2a9d8f 102.53%);
  padding: 20px 30px;
  color: #fff;
  text-decoration: none;
  font-size: 20px;
  font-weight: 700;
  text-transform: uppercase;
}
.inner-business a:hover {
  box-shadow: 0 0 10px #8d8d8d;
}

@media (max-width: 1399px) and (min-width: 1200px) {
  .about-us .about-img::after {
    width: 90%;
    left: 16%;
  }
  .about-us .about-img h2 {
    right: -138px;
    font-size: 55px;
  }

  .mission-vision::before {
    display: none;
  }
}
@media (max-width: 1199px) and (min-width: 992px) {
  .about-us img {
    width: 85%;
  }
  .about-us .about-img::after {
    width: 60%;
    right: 0;
  }
  .about-us .about-img h2 {
    font-size: 55px;
    right: -107px;
  }

  .mission-vision::before {
    display: none;
  }

  .home-add-section .nutri-add {
    padding-top: 50px;
  }
  .home-add-section .add-content h1 {
    font-size: 70px !important;
  }
  .home-add-section .add-content p {
    font-size: 20px !important;
    line-height: 28px !important;
    margin-bottom: 35px !important;
  }
  .home-add-section .add-content a {
    font-size: 18px !important;
    padding: 8px 25px !important;
  }
}
@media (max-width: 991px) and (min-width: 768px) {
  .about-us img {
    width: 85%;
  }
  .about-us .about-img::after {
    width: 60%;
    right: 0 !important;
  }
  .about-us .about-img h2 {
    font-size: 45px;
    right: -88px;
  }
  .about-us .about-text h3 {
    font-size: 18px;
    line-height: 25px;
    margin-bottom: 30px;
  }
  .about-us .about-text p {
    font-size: 14px;
    line-height: 20px;
  }

  .inner-business h3 {
    font-size: 40px !important;
    line-height: 60px !important;
  }

  .mission-vision::before {
    display: none;
  }

  .home-add-section .nutri-add {
    padding-top: 50px;
  }
  .home-add-section .add-content {
    padding-left: 50px;
  }
  .home-add-section .add-content h1 {
    font-size: 60px !important;
    margin-bottom: 15px !important;
  }
  .home-add-section .add-content p {
    font-size: 18px !important;
    line-height: 25px !important;
    margin-bottom: 30px !important;
  }
  .home-add-section .add-content a {
    font-size: 16px !important;
    padding: 6px 20px !important;
  }

  .inner-solution h4 {
    font-size: 19px;
    line-height: 30px;
  }
  .inner-solution p {
    font-size: 15px;
    line-height: 22px;
  }
  .inner-solution .inner-solution-content {
    padding-left: 30px;
  }

  .inner-about::before {
    display: none;
  }
  .inner-about::after {
    display: none;
  }
  .inner-about h1 {
    font-size: 145px;
    margin-bottom: -60px;
  }
  .inner-about h3 {
    font-size: 42px;
    line-height: 58px;
  }
}
@media (max-width: 767px) {
  .inner-services-page {
    padding-bottom: 50px !important;
  }
  .inner-services-page .left-img-holder img {
    display: block;
    margin: 10px auto;
  }
  .inner-services-page .sliding-side {
    margin-top: 30px;
  }
  .inner-services-page .sliding-side h4 {
    text-align: center;
    margin-bottom: 20px;
    font-size: 25px;
  }
  .inner-services-page .sliding-side p {
    text-align: center;
    font-size: 13px;
    line-height: 22px;
  }

  .inner-services h3 {
    font-size: 28px !important;
  }

  .inner-services {
    padding-top: 50px;
  }
  .inner-services::before {
    display: none;
  }
  .inner-services::after {
    display: none;
  }
  .inner-services h3 {
    font-size: 30px;
  }
  .inner-services h3::after {
    display: none;
  }
  .inner-services h5 {
    font-size: 15px;
  }
  .inner-services .flip-card {
    flex: 0 0 calc(100% - 30px) !important;
  }

  .about-us {
    padding-bottom: 50px;
    padding-top: 50px;
  }
  .about-us .about-img img {
    width: 80%;
  }
  .about-us .about-img::after {
    width: 60%;
  }
  .about-us .about-img h2 {
    font-size: 50px;
    right: -82px;
  }
  .about-us .about-text {
    margin-top: 60px;
    padding-left: 0;
  }

  .mission-vision {
    padding-bottom: 50px;
  }
  .mission-vision .divider {
    margin: 50px 0;
    width: 100%;
  }
  .mission-vision .mission-vision-content h2 {
    font-size: 35px;
  }
  .mission-vision .mission-vision-content p {
    font-size: 20px;
    line-height: 32px;
  }
  .mission-vision::before {
    display: none;
  }
  .mission-vision::after {
    display: none;
  }

  .home-add-section {
    margin-top: 50px;
  }
  .home-add-section .add-content {
    padding-left: 0 !important;
    width: 50%;
    margin: 0 auto 50px;
  }
  .home-add-section .add-content h1 {
    font-size: 30px !important;
    margin-bottom: 15px !important;
  }
  .home-add-section .add-content p {
    font-size: 16px !important;
    line-height: 22px !important;
    margin-bottom: 30px !important;
  }
  .home-add-section .add-content a {
    font-size: 16px !important;
    padding: 6px 20px !important;
  }
}
@media (max-width: 575px) {
  .about-us .about-img::after {
    width: 50%;
    right: 6%;
  }
  .about-us .about-img .img-fluid {
    max-width: 80% !important;
  }
  .about-us .about-img h2 {
    right: -64px;
  }

  .about-text h3 {
    font-size: 25px !important;
    line-height: 35px !important;
  }
  .about-text p {
    font-size: 15px !important;
    line-height: 25px !important;
  }

  .sec-client p {
    font-size: 15px;
    line-height: 22px;
    padding: 0 10px;
  }

  .sec-achievement .swiper-button-prev {
    height: 25px;
    width: 25px;
    margin-right: 25px;
  }
  .sec-achievement .swiper-button-prev::before {
    font-size: 12px;
  }
  .sec-achievement .swiper-button-prev::after {
    font-size: 12px;
  }
  .sec-achievement .swiper-button-next {
    height: 25px;
    width: 25px;
  }
  .sec-achievement .swiper-button-next::before {
    font-size: 12px;
  }
  .sec-achievement .swiper-button-next::after {
    font-size: 12px;
  }

  .product-section .products .bg-img-wrapper {
    padding: 25px 0;
  }
  .product-section .products .bg-img-wrapper .number {
    height: 25px;
    width: 25px;
    font-size: 18px;
  }
  .product-section .products .bg-img-wrapper .detail-container {
    padding-top: 30px;
  }
  .product-section .products .bg-img-wrapper .detail-container .content {
    margin-bottom: 25px;
    font-size: 13px;
  }

  .inner-business h3 {
    font-size: 25px;
    line-height: 35px;
  }

  .inner-solution .inner-solution-img img {
    margin-left: 15px;
  }
}
@media (max-width: 375px) {
  .inner-about h1 {
    font-size: 50px !important;
    margin-bottom: -78px !important;
  }

  .inner-about h3 {
    font-size: 18px !important;
    line-height: 30px !important;
  }

  .mission-vision .mission-vision-content h4 {
    font-size: 30px;
  }
  .mission-vision .mission-vision-content p {
    font-size: 16px;
    line-height: 25px;
  }

  .inner-business h3 {
    font-size: 17px !important;
    line-height: 30px !important;
  }
  .inner-business h5 {
    font-size: 14px;
  }
  .inner-business a {
    padding: 10px 20px;
    font-size: 14px;
  }

  .inner-solution h4 {
    text-align: center;
    font-size: 25px;
    line-height: 35px;
  }
  .inner-solution p {
    text-align: center;
  }
  .inner-solution .inner-solution-img img {
    width: 95%;
  }
}
.top-banner h1 {
  font-size: 180px;
  font-weight: 700;
  text-transform: uppercase;
  color: #e7e7e7;
  line-height: 180px;
  margin-top: -25px;
}
.top-banner .solution-pages-main-title {
  position: absolute;
  width: 100%;
  text-align: center;
  top: 45%;
}
.top-banner .solution-pages-main-title h2 {
  color: #fff;
  font-size: 100px;
  line-height: 90px;
  text-transform: uppercase;
  font-weight: 600;
}
.top-banner .breadcrumb {
  justify-content: flex-end;
  margin-right: 115px;
}
.top-banner .breadcrumb a {
  color: #000;
  text-decoration: none;
  font-size: 16px;
  font-weight: 400;
}
.top-banner .breadcrumb i {
  color: #000;
  padding: 0 10px;
}

.back-btn {
  margin-top: 50px;
}
.back-btn ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.back-btn ul li {
  display: inline-block;
}
.back-btn ul li a {
  text-decoration: none;
  color: #000;
}

.it-solutions {
  padding: 100px 0;
  position: relative;
}
.it-solutions::after {
  position: absolute;
  content: "";
  width: 131px;
  height: 134px;
  background: url(../images/shapes/Ellipse1.webp);
  background-position: center;
  top: 25%;
  right: -2%;
  background-position: center;
  top: -1%;
  right: -1%;
  transform: rotate(110deg);
}
.it-solutions::before {
  position: absolute;
  content: "";
  width: 100px;
  height: 225px;
  border-radius: 12px;
  background: rgba(255, 180, 31, 0.1);
  right: 0;
  transform: rotate(90deg);
  top: 0;
  right: 0;
  transform: rotate(90deg);
  top: 93%;
}
.it-solutions .flip-card {
  /*img {
    width: 348px;
    height: 437px;
  }
  img.bit-large {
    width: 610px;
    height: 691px;
  }*/
}
.it-solutions .flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
}
.it-solutions .flip-card .flip-card-inner {
  position: relative;
  transform-style: preserve-3d;
  transition: all 1s ease;
  /*width: 80%;*/
  margin: 0 auto;
}
.it-solutions .flip-card .flip-card-inner .flip-card-front {
  background-color: #bbb;
  color: #000;
  background-size: contain;
  background-repeat: no-repeat;
}
.it-solutions .flip-card .front-four {
  background: url(../images/solutions/education.webp);
}
.it-solutions .flip-card .front-five {
  background: url(../images/solutions/health.webp);
}
.it-solutions .flip-card .front-six {
  background: url(../images/solutions/medical.webp);
}
.it-solutions .flip-card .front-seven {
  background: url(../images/solutions/enhanced.webp);
}
.it-solutions .sliding-side {
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: center;
}
.it-solutions .sliding-side h2 {
  font-size: 40px;
  font-weight: 600;
  line-height: 60px;
  margin-bottom: 20px;
}
.it-solutions .sliding-side h2 span {
  color: #ffb41f;
}
.it-solutions .sliding-side p {
  font-size: 18px;
  font-weight: 400;
  line-height: 32px;
}
.it-solutions .sliding-side p span {
  color: #006b60;
  font-weight: 600;
}
.it-solutions .solution-slider-wrapper .overlay {
  position: relative;
  display: inline-block;
  cursor: pointer;
}
.it-solutions .solution-slider-wrapper .overlay h4 {
  position: absolute;
  color: #ffb41f;
  top: 50%;
  width: 100%;
  text-align: center;
  z-index: 1;
  display: none;
  text-transform: uppercase;
  display: block;
  color: #fff;
  top: 40%;
  font-size: 16px;
  line-height: 18px;
  font-weight: 700;
}
.it-solutions .solution-slider-wrapper .overlay:after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: none;
  background: -webkit-linear-gradient(top, rgba(43, 55, 88, 0.9) 0%, rgba(42, 157, 143, 0.9) 100%);
  display: block;
}
.it-solutions .solution-slider-wrapper .overlay:hover::after {
  display: none;
}
.it-solutions .solution-slider-wrapper .overlay:hover h4 {
  display: none;
}
.it-solutions .solution-slider-wrapper .overlay img {
  display: block;
  border: 1px solid #2b3758;
}
.it-solutions .inner-solution-slide {
  margin-top: 50px;
}
.it-solutions .swiper-button-next {
  color: #fff;
  background-color: #fff;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  position: relative;
  position: relative;
  top: 15%;
  left: 85%;
  display: inline-flex;
  background-color: #ccc;
}
.it-solutions .swiper-button-next::after {
  font-size: 18px;
  color: #919090;
  font-weight: 100;
  content: "\f061" !important;
  font-family: "FontAwesome" !important;
}
.it-solutions .swiper-button-next:hover {
  background-color: #ffb41f;
}
.it-solutions .swiper-button-prev {
  color: #fff;
  background-color: #fff;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  position: relative;
  position: relative;
  top: 15%;
  left: 85%;
  display: inline-flex;
  background-color: #ccc;
}
.it-solutions .swiper-button-prev::after {
  font-size: 18px;
  color: #919090;
  font-weight: 100;
  content: "\f060" !important;
  font-family: "FontAwesome" !important;
}
.it-solutions .swiper-button-prev:hover {
  background-color: #ffb41f;
}
.it-solutions #ui-dev {
  position: relative;
}
.it-solutions #ui-dev::after {
  position: absolute;
  content: "";
  width: 239px;
  height: 241px;
  background: url(../images/shapes/Ellipse-large.webp);
  background-position: center;
  left: 30%;
  bottom: -40%;
}

.inner-solu .flip-card {
  flex: 0 0 calc(33.33% - 30px);
  margin: 0 15px 30px;
  perspective: 1000px;
}
.inner-solu .flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
}
.inner-solu .flip-card .flip-card-inner {
  position: relative;
  transform-style: preserve-3d;
  transition: all 1s ease;
  width: 80%;
  margin: 0 auto;
}
.inner-solu .flip-card .flip-card-inner .flip-card-front img {
  width: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}
.inner-solu .flip-card .flip-card-inner .flip-card-back {
  background: linear-gradient(146.08deg, #2b3758 -0.65%, #2a9d8f 102.53%);
  position: absolute;
  left: 0;
  top: 0;
  padding: 15px;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  transform: rotateY(180deg);
  backface-visibility: hidden;
}
.inner-solu .flip-card .flip-card-inner .flip-card-back h4 {
  color: #ffb41f;
  text-transform: uppercase;
}

@media (max-width: 1399px) and (min-width: 1200px) {
  .solutions .text-left h1 {
    font-size: 70px;
  }

  .inner-solu .sliding-side h4 {
    margin-bottom: 20px;
  }
  .inner-solu .inner-solution-slide {
    margin-top: 30px;
  }
  .inner-solu .solution-slider-wrapper .overlay h4 {
    font-size: 18px;
    line-height: 18px;
  }
  .inner-solu .swiper-button-next {
    top: 10%;
  }
  .inner-solu .swiper-button-prev {
    top: 10%;
  }
}
@media (max-width: 1199px) and (min-width: 992px) {
  .solutions .solution-box {
    padding: 20px 15px !important;
  }
  .solutions .solution-box h4 {
    font-size: 19px;
  }
  .solutions .solution-box p {
    font-size: 16px;
  }
  .solutions .text-left h1 {
    font-size: 44px !important;
    line-height: 65px !important;
  }

  .inner-solution h4 {
    font-size: 28px;
    line-height: 38px;
  }
  .inner-solution p {
    font-size: 16px;
  }
  .inner-solution .inner-solution-content {
    padding-left: 50px;
  }

  .it-solutions .sliding-side {
    margin-top: 10px;
  }
  .it-solutions .sliding-side h2 {
    font-size: 30px;
    line-height: 40px;
    margin-bottom: 20px;
  }
  .it-solutions .sliding-side p {
    font-size: 15px;
    line-height: 25px;
  }
  .it-solutions .inner-solution-slide {
    margin-top: 50px;
  }
  .it-solutions .swiper-button-prev {
    top: 8%;
  }
  .it-solutions .swiper-button-next {
    top: 8%;
  }

  .inner-solu .flip-card-inner {
    width: 100% !important;
  }

  .achievements h3 {
    font-size: 80px;
  }

  .form-wrapper .form-check-input {
    margin-bottom: 30px;
  }

  .solution-pages-main-title h2 {
    font-size: 60px !important;
  }
}
@media (max-width: 991px) and (min-width: 768px) {
  .solutions .text-left h1 {
    font-size: 80px;
    line-height: 80px;
    margin-bottom: 50px;
  }
  .solutions .text-right p {
    font-size: 16px;
    line-height: 28px;
    padding-left: 0;
    padding-right: 200px;
  }

  .it-solutions .inner-solution-slide {
    margin-top: 0;
  }
  .it-solutions .sliding-side {
    margin-top: 10px;
  }
  .it-solutions .sliding-side h2 {
    font-size: 25px;
    line-height: 30px;
    margin-bottom: 10px;
  }
  .it-solutions .sliding-side p {
    font-size: 14px;
    line-height: 22px;
  }
}
@media (max-width: 991px) {
  .inner-solu .flip-card-inner {
    width: 100% !important;
  }

  .solution-slider-wrapper .overlay h4 {
    font-size: 18px !important;
    line-height: 18px !important;
  }
}
@media (max-width: 767px) {
  .it-solutions .inner-solution-slide {
    margin-top: 30px;
  }

  .top-banner h1 {
    font-size: 50px;
    margin-top: -70px;
  }

  .it-solutions.inner-services-page::before {
    display: none;
  }
  .it-solutions.inner-services-page::after {
    display: none;
  }

  .inner-allsolution-pages h1 {
    font-size: 50px !important;
    line-height: 50px !important;
  }

  .inner-business h3 {
    font-size: 33px;
    line-height: 50px;
  }

  .it-solutions {
    padding-top: 0;
  }
  .it-solutions::after {
    display: none;
  }
  .it-solutions::before {
    display: none;
  }
  .it-solutions .flip-card-front {
    text-align: center;
  }
  .it-solutions .flip-card-front img {
    float: none !important;
    margin: 0 auto;
  }
  .it-solutions .sliding-side {
    margin-bottom: 50px;
    margin-top: 0;
  }

  .it-solutions #medical-innovations {
    margin-bottom: 100px;
  }

  .it-solutions #health-it-solutions {
    margin-bottom: 100px;
  }

  .it-solutions #enhanced-productivity {
    margin-bottom: 100px;
  }

  .it-solutions #educational-it-solutions {
    margin-bottom: 100px;
  }

  .it-solutions .swiper-button-prev {
    top: 5%;
    left: 75%;
  }

  .it-solutions .swiper-button-next {
    top: 5%;
    left: 75%;
  }
}
@media (max-width: 575px) {
  .it-solutions .solution-slider-wrapper h4 {
    font-size: 12px !important;
  }

  .solutions .text-left h1 {
    font-size: 34px !important;
    line-height: 50px !important;
  }

  .inner-allsolution-pages {
    padding: 100px 0;
  }

  .top-banner .breadcrumb {
    right: 40px;
  }
  .top-banner .breadcrumb a {
    font-size: 11px;
  }
  .top-banner .solution-pages-main-title {
    width: 92%;
  }
  .top-banner .solution-pages-main-title h2 {
    font-size: 18px !important;
    line-height: 35px !important;
  }
}
@media (max-width: 375px) {
  .top-banner h1 {
    font-size: 35px !important;
  }

  .solutions .text-left h1 {
    font-size: 35px;
    line-height: 42px;
  }
  .solutions .top-space .col-6 {
    width: 100%;
  }

  .it-solutions .solution-slider-wrapper h4 {
    font-size: 9px !important;
  }

  .inner-allsolution-pages h1 {
    font-size: 30px;
    line-height: 40px;
  }
}
@media (max-width: 320px) {
  .it-solutions .swiper-button-next,
.it-solutions .swiper-button-prev {
    left: 70%;
  }
}
.form-wrapper {
  padding: 100px 0;
  position: relative;
}
.form-wrapper::before {
  position: absolute;
  content: "";
  width: 100px;
  height: 225px;
  border-radius: 12px;
  background: rgba(255, 180, 31, 0.1);
  right: 0;
  transform: rotate(90deg);
  top: 0;
  transform: rotate(90deg);
  top: 0;
  right: 95%;
}
.form-wrapper::after {
  position: absolute;
  content: "";
  width: 131px;
  height: 134px;
  background: url(../images/shapes/Ellipse1.webp);
  background-position: center;
  top: 25%;
  right: -2%;
  background-position: center;
  right: -2%;
  transform: rotate(130deg);
  top: 8%;
}
.form-wrapper h2 {
  font-size: 70px;
  font-weight: 600;
  text-transform: uppercase;
  line-height: 70px;
  margin-bottom: 100px;
  font-size: 40px;
  line-height: 40px;
  margin-block-end: 30px;
}
.form-wrapper h2 span {
  color: #ffb41f;
}
.form-wrapper__contact-box {
  background: #f2f2f2;
  text-align: center;
  padding: 45px 30px;
  border-radius: 10px;
  min-height: 295px;
}
.form-wrapper__contact-box h5 {
  margin-block-end: 20px;
  font-weight: 600;
}
.form-wrapper__contact-box a {
  color: #000;
  text-decoration: none;
}
.form-wrapper__contact-box:hover i {
  background-color: #ffb41f;
  color: #000;
}
.form-wrapper__icon-box i {
  font-size: 40px;
  color: #ffb41f;
  background-color: #000;
  display: inline-block;
  text-align: center;
  border-radius: 100%;
  width: 80px;
  height: 80px;
  line-height: 80px;
  transition: all 0.3s ease-in-out;
  margin-block-end: 20px;
}
.form-wrapper input[type=text],
.form-wrapper input[type=email],
.form-wrapper input[type=file],
.form-wrapper input[type=password],
.form-wrapper select {
  border: 2px solid #388697;
  height: 50px;
  border-radius: 0;
}
.form-wrapper input[type=file] {
  padding: 0.7rem 0.75rem;
}
.form-wrapper select {
  font-size: 1rem;
  color: #757574;
}
.form-wrapper textarea {
  border: 2px solid #388697;
  height: 245px;
  border-radius: 0;
  margin-bottom: 20px;
}
.form-wrapper .btn-success {
  background: linear-gradient(146.08deg, #2b3758 -0.65%, #2a9d8f 102.53%);
  border-radius: 0;
  padding: 12px 75px;
  color: #fff !important;
  text-decoration: none;
  float: right;
  text-transform: uppercase;
  font-size: 18px;
  font-weight: 600;
}
.form-wrapper .btn-success:hover {
  box-shadow: 0 0 10px #8d8d8d;
  cursor: pointer;
}
.form-wrapper .form-check-input {
  width: 3em;
  height: 3em;
  border: 2px solid #006b60;
  border-radius: 0;
  margin-right: 25px;
}
.form-wrapper .form-check-input:checked {
  background-color: #006b60;
  border-color: #006b60;
}
.form-wrapper::-moz-placeholder {
  color: #388697;
}
.form-wrapper:-ms-input-placeholder {
  color: #388697;
}
.form-wrapper::placeholder {
  color: #388697;
}
.form-wrapper .form-check-label {
  font-size: 15px;
  font-weight: 600;
}
.form-wrapper .success-message {
  color: #5dc267;
  font-size: 20px;
  border: 1px solid #5dc267;
  border-radius: 5px;
  padding: 5px 10px;
}
.form-wrapper .error-message {
  color: #ff0000;
  font-size: 20px;
  border: 1px solid #ff0000;
  border-radius: 5px;
  padding: 5px 10px;
}
.form-wrapper .create-account a {
  color: #388697 !important;
  text-decoration: none;
}
.form-wrapper .toast {
  position: fixed;
  inset-block-start: 10%;
  inset-inline-end: 0%;
  z-index: 999;
}

.join-our-team {
  padding: 150px 0;
  position: relative;
}
.join-our-team::before {
  position: absolute;
  content: "";
  width: 100px;
  height: 225px;
  border-radius: 12px;
  background: rgba(255, 180, 31, 0.1);
  right: 0;
  transform: rotate(90deg);
  top: 0;
  transform: rotate(90deg);
  right: 94%;
  top: 15%;
}
.join-our-team::after {
  position: absolute;
  content: "";
  width: 136px;
  height: 150px;
  background: url(../images/shapes/Ellipse.webp);
  background-position: center;
  left: 5%;
  top: 0;
  background-position: center;
  left: 80%;
  top: 70%;
  transform: rotate(180deg);
}
.join-our-team h1 {
  font-size: 180px;
  font-weight: 700;
  text-transform: uppercase;
  color: #e7e7e7;
  line-height: 180px;
  margin-top: -25px;
  text-align: right;
  position: relative;
  z-index: -2;
}
.join-our-team .career-img {
  position: relative;
}
.join-our-team .career-img h2 {
  transform: rotate(270deg);
  position: absolute;
  right: -415px;
  bottom: 42%;
  color: #fff;
  font-size: 90px;
  font-weight: 700;
}
.join-our-team .career-img::after {
  position: absolute;
  content: "";
  background: linear-gradient(146.08deg, #2b3758 -0.65%, #2a9d8f 102.53%);
  z-index: -1;
  width: 100%;
  height: 110%;
  left: 15%;
  top: -5%;
}

.career-form::before {
  position: absolute;
  content: "";
  width: 131px;
  height: 134px;
  background: url(../images/shapes/Ellipse1.webp);
  background-position: center;
  top: 25%;
  right: -2%;
  background-position: center;
  top: 0;
  right: 85%;
  transform: rotate(145deg);
}
.career-form::after {
  display: none;
}

.current-opening {
  margin-bottom: 50px;
}
.current-opening__opening-wrapper {
  border: 1px solid #388697;
  margin-bottom: 50px;
}
.current-opening__opening-wrapper ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.current-opening__header-area {
  padding: 15px;
  border-bottom: 1px solid #388697;
}
.current-opening__header-area h3 {
  font-size: 25px;
  line-height: 25px;
  font-weight: 500;
  margin: 0;
  color: #388697;
}
.current-opening__job-description {
  padding: 15px 15px 50px 15px;
}
.current-opening__job-description li {
  font-size: 18px;
  line-height: 18px;
  font-weight: 400;
  margin-bottom: 15px;
}
.current-opening__footer-area {
  padding: 10px 15px;
  border-top: 1px solid #388697;
}
.current-opening__footer-area ul {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.current-opening__footer-area p {
  margin: 0;
  color: #388697;
  font-size: 16px;
  font-weight: 400;
}
.current-opening__footer-area a {
  font-size: 18px;
  line-height: 18px;
  text-decoration: none;
  color: #388697;
  font-weight: 600;
}

.job-detail {
  margin: 50px 0;
}
.job-detail h3 {
  font-size: 25px;
  line-height: 25px;
  font-weight: 700;
  border-bottom: 1px solid #388697;
  padding-bottom: 20px;
  margin-bottom: 20px;
}
.job-detail h4 {
  font-size: 20px;
  line-height: 20px;
  font-weight: 600;
  margin: 30px 0;
}
.job-detail p,
.job-detail li {
  font-size: 18px;
  line-height: 18px;
  font-weight: 400;
}
.job-detail li {
  margin-bottom: 15px;
}

.old-text {
  margin: 50px 0;
}
.old-text h3 {
  font-size: 30px;
  font-weight: 600;
  line-height: 30px;
  margin-bottom: 20px;
}
.old-text h3 span {
  color: #ffb41f;
  display: inline-block;
  font-weight: 800;
}
.old-text p {
  font-size: 20px;
  margin-bottom: 50px;
}
.old-text ul {
  list-style-type: disc;
  padding-left: 50px;
}
.old-text ul li {
  font-size: 20px;
  margin-bottom: 10px;
}

@media (max-width: 1399px) and (min-width: 1200px) {
  .join-our-team .career-img h2 {
    right: -333px;
    font-size: 70px;
  }
}
@media (max-width: 1199px) and (min-width: 992px) {
  .join-our-team h1 {
    font-size: 110px;
    bottom: -40px;
  }

  .join-our-team .career-img h2 {
    font-size: 60px;
    right: -283px;
  }
}
@media (max-width: 991px) and (min-width: 768px) {
  .join-our-team h1 {
    font-size: 110px;
    bottom: -40px;
  }

  .join-our-team .career-img h2 {
    font-size: 40px;
    right: -194px;
  }

  .top-banner h1 {
    font-size: 50px;
    margin-top: -70px;
  }
  .top-banner .solution-pages-main-title h2 {
    font-size: 30px;
    line-height: 40px;
  }

  .office-address-buttons {
    padding: 50px 0;
  }
  .office-address-buttons::after {
    display: none;
  }
  .office-address-buttons::before {
    display: none;
  }

  .career-form h3 {
    margin-bottom: 50px;
  }
  .career-form textarea {
    margin-top: 50px;
  }

  .form-wrapper__contact-box {
    margin-top: 20px;
  }
}
@media (max-width: 767px) {
  .join-our-team h1 {
    font-size: 100px;
    bottom: -50px;
  }

  .join-our-team:after {
    display: none;
  }

  .join-our-team .career-img:after {
    width: 90%;
  }

  .join-our-team .career-img h2 {
    font-size: 45px;
    right: -178px;
  }

  .join-our-team .career-img h2 {
    font-size: 45px;
    right: -178px;
  }

  .join-our-team .career-img img {
    max-width: 90%;
  }

  .join-our-team .career-img:after {
    width: 90%;
  }

  .career-form textarea {
    margin-top: 50px;
  }
}
@media (max-width: 575px) {
  .join-our-team .career-img h2 {
    right: -108px;
    font-size: 32px;
  }

  .join-our-team .career-img:after {
    width: 85% !important;
  }

  .join-our-team h1 {
    font-size: 50px !important;
    bottom: -70px !important;
  }

  .join-our-team {
    padding-top: 0;
    padding-bottom: 75px;
  }
  .join-our-team::before {
    display: none;
  }
  .join-our-team::after {
    display: none;
  }
  .join-our-team h1 {
    font-size: 100px;
    bottom: -50px;
  }
  .join-our-team .career-img::after {
    width: 90%;
  }
  .join-our-team .career-img img {
    max-width: 90%;
  }

  .current-opening__footer-area .btn-success {
    padding: 8px 15px;
    font-size: 15px;
  }

  .old-text h3 {
    font-size: 20px;
    line-height: 30px;
  }
  .old-text p,
.old-text li {
    font-size: 16px !important;
    line-height: 25px;
  }
}
@media (max-width: 375px) {
  .join-our-team .career-img h2 {
    right: -85px;
    font-size: 25px;
  }

  .join-our-team h1 {
    font-size: 50px;
    bottom: -70px;
  }
  .join-our-team .career-img h2 {
    right: -85px;
    font-size: 25px;
  }

  .join-our-team {
    padding-top: 0;
    padding-bottom: 75px;
  }
  .join-our-team::before {
    display: none;
  }
  .join-our-team::after {
    display: none;
  }
  .join-our-team h1 {
    font-size: 100px;
    bottom: -50px;
  }
  .join-our-team .career-img::after {
    width: 90%;
  }
  .join-our-team .career-img img {
    max-width: 90%;
  }
  .join-our-team .career-img h2 {
    right: -85px;
    font-size: 25px;
  }
}
.office-address-buttons .office-button a {
  min-height: 270px;
}
.office-address-buttons .office-button .office-address {
  min-height: 150px;
  padding: 20px 20px;
}

.office-address-buttons {
  padding: 0 0 100px;
  position: relative;
}
.office-address-buttons::before {
  position: absolute;
  content: "";
  width: 100px;
  height: 225px;
  border-radius: 12px;
  background: rgba(255, 180, 31, 0.1);
  right: 0;
  transform: rotate(90deg);
  top: 0;
  transform: rotate(90deg);
  top: 0;
  right: 95%;
}
.office-address-buttons::after {
  position: absolute;
  content: "";
  width: 131px;
  height: 134px;
  background: url(../images/shapes/Ellipse1.webp);
  background-position: center;
  top: 25%;
  right: -2%;
  background-position: center;
  right: -2%;
  top: 60%;
}
.office-address-buttons .button-holder {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
.office-address-buttons .office-button {
  background: url(../images/contact-bg.webp);
  background-position: top center;
  background-size: contain;
  background-repeat: no-repeat;
  max-width: 344px;
  width: 100%;
  min-height: 304px;
  position: relative;
  margin: 10px;
}
.office-address-buttons .office-button a {
  text-decoration: none;
  color: #ffb41f;
  text-transform: uppercase;
  font-size: 30px;
  font-weight: 700;
  display: flex;
  min-height: 304px;
  justify-content: center;
  align-items: center;
}
.office-address-buttons .office-button .office-address {
  background-color: #fff;
  width: 100%;
  min-height: 304px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9;
  display: none;
  box-shadow: 0 0 10px #ccc;
  padding: 33px 20px;
}
.office-address-buttons .office-button .office-address h4 {
  font-size: 25px;
  font-weight: 600;
  color: #006b60;
  padding-bottom: 30px;
  border-bottom: 1px solid #006b60;
  margin-bottom: 20px;
}
.office-address-buttons .office-button .office-address ul {
  list-style-type: none;
  padding: 0;
}
.office-address-buttons .office-button .office-address ul li {
  margin-bottom: 18px;
  font-size: 14px;
  line-height: 20px;
  padding-left: 25px;
  position: relative;
}
.office-address-buttons .office-button .office-address ul li:first-child::before {
  position: absolute;
  left: 0;
  content: url(../images/icons/location-contact.webp);
}
.office-address-buttons .office-button .office-address ul li:nth-child(2):before {
  position: absolute;
  left: 0;
  content: url(../images/icons/mail.webp);
}
.office-address-buttons .office-button .office-address ul li:nth-child(3)::before {
  position: absolute;
  left: 0;
  content: url(../images/icons/mob.webp);
}
.office-address-buttons .office-button:hover .office-address {
  display: block;
  cursor: pointer;
}

@media (max-width: 767px) {
  .contact {
    display: block !important;
    text-align: center;
  }
  .contact div:last-child {
    margin-top: 20px;
  }

  .office-address-buttons:before {
    display: none;
  }

  .office-address-buttons:after {
    display: none;
  }
}
@media (max-width: 320px) {
  .office-address-buttons .office-button a {
    min-height: 240px;
    font-size: 22px;
  }
  .office-address-buttons .button-holder {
    padding: 0;
  }
}
.media-achievements .overlay {
  position: relative;
  display: inline-block;
  cursor: pointer;
}
.media-achievements .overlay p {
  position: absolute;
  color: #ffb41f;
  top: 50%;
  width: 100%;
  text-align: center;
  z-index: 1;
  display: none;
  text-transform: uppercase;
  color: #fff;
  text-transform: capitalize;
  font-size: 20px;
  font-weight: 400;
  top: 60%;
}
.media-achievements .overlay:after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: none;
  background: -webkit-linear-gradient(top, rgba(43, 55, 88, 0.7) 0%, rgba(42, 157, 143, 0.7) 100%);
}
.media-achievements .overlay:hover::after {
  display: inline-block;
}
.media-achievements .overlay:hover p {
  display: block;
}
.media-achievements .overlay img {
  display: block;
}
.media-achievements::before {
  position: absolute;
  content: "";
  width: 136px;
  height: 150px;
  background: url(../images/shapes/Ellipse.webp);
  background-position: center;
  left: 5%;
  top: 0;
  background: url(../images/shapes/Ellipse.webp) !important;
  background-position: center !important;
  left: 5% !important;
  transform: rotate(0deg) !important;
  top: 8% !important;
  width: 136px !important;
  height: 150px !important;
}
.media-achievements::after {
  display: none;
}
.media-achievements h2 {
  background: linear-gradient(89.94deg, #2b3758 -3.98%, #2a9d8f 103.74%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 600;
  font-size: 100px;
  line-height: 132px;
  text-transform: capitalize;
  margin-bottom: 100px;
}

.media-achievements.pb-250 {
  padding-bottom: 250px;
}

.certificate {
  position: relative;
  background: linear-gradient(146.08deg, #2b3758 -0.65%, #2a9d8f 102.53%);
  padding: 70px 0 0;
}
.certificate::before {
  position: absolute;
  content: "";
  width: 100px;
  height: 225px;
  border-radius: 12px;
  background: rgba(255, 180, 31, 0.1);
  right: 0;
  transform: rotate(90deg);
  top: 0;
  transform: rotate(90deg);
  left: 0;
  top: -250px;
}
.certificate::after {
  position: absolute;
  content: "";
  width: 131px;
  height: 134px;
  background: url(../images/shapes/Ellipse1.webp);
  background-position: center;
  top: 25%;
  right: -2%;
  background-position: center;
  right: 0;
  top: -150px;
}
.certificate h1 {
  color: #fff;
  font-size: 150px;
  font-weight: 600;
  line-height: 150px;
  margin-bottom: -23px;
}

.certificate-image {
  padding: 200px 0;
  position: relative;
}
.certificate-image::after {
  position: absolute;
  content: "";
  width: 100px;
  height: 225px;
  border-radius: 12px;
  background: rgba(255, 180, 31, 0.1);
  right: 0;
  transform: rotate(90deg);
  top: 0;
  right: 0;
  transform: rotate(90deg);
  top: 0;
}
.certificate-image::before {
  position: absolute;
  content: "";
  width: 131px;
  height: 134px;
  background: url(../images/shapes/Ellipse1.webp);
  background-position: center;
  top: 25%;
  right: -2%;
  background-position: center;
  left: 10% !important;
  top: 65%;
  transform: rotate(140deg);
}
.certificate-image .certificate-img-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100%;
}

.media {
  margin-top: 150px;
  position: relative;
}
.media h1 {
  position: relative;
  z-index: -1;
}
.media::after {
  position: absolute;
  content: "";
  width: 100px;
  height: 225px;
  border-radius: 12px;
  background: rgba(255, 180, 31, 0.1);
  right: 0;
  transform: rotate(90deg);
  top: 0;
  right: 0;
  transform: rotate(90deg);
  top: -80px;
}
.media .banner-holder h1 {
  margin-top: 0;
  margin-bottom: -27px;
}

.media-slides {
  position: relative;
  padding-top: 0;
  padding-bottom: 200px;
}
.media-slides .overlay {
  position: relative;
  display: inline-block;
  cursor: pointer;
}
.media-slides .overlay p {
  position: absolute;
  color: #ffb41f;
  top: 50%;
  width: 100%;
  text-align: center;
  z-index: 1;
  display: none;
  text-transform: uppercase;
  color: #fff;
  text-transform: capitalize;
  font-size: 20px;
  font-weight: 400;
  top: 70%;
}
.media-slides .overlay:after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: none;
  background: -webkit-linear-gradient(top, rgba(43, 55, 88, 0.7) 0%, rgba(42, 157, 143, 0.7) 100%);
}
.media-slides .overlay:hover::after {
  display: inline-block;
}
.media-slides .overlay:hover p {
  display: block;
}
.media-slides .overlay img {
  display: block;
}
.media-slides::before {
  position: absolute;
  content: "";
  width: 136px;
  height: 150px;
  background: url(../images/shapes/Ellipse.webp);
  background-position: center;
  left: 5%;
  top: 0;
  background-position: center;
  left: 5%;
  top: 82%;
  transform: rotate(0deg);
}
.media-slides::after {
  position: absolute;
  content: "";
  width: 100px;
  height: 225px;
  border-radius: 12px;
  background: rgba(255, 180, 31, 0.1);
  right: 0;
  transform: rotate(90deg);
  top: 0;
  right: 0;
  transform: rotate(90deg);
  top: 78%;
}
.media-slides h3 {
  background: linear-gradient(89.94deg, #2b3758 -3.98%, #2a9d8f 103.74%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 600;
  font-size: 100px;
  line-height: 132px;
  text-transform: capitalize;
  margin-bottom: 50px;
}
.media-slides .bg-light {
  padding: 100px 0;
  background-color: #e7e7e7 !important;
}

@media (max-width: 991px) and (min-width: 768px) {
  .media .banner-holder h1 {
    margin-bottom: -55px;
    font-size: 150px;
  }

  .certificate h1 {
    font-size: 100px;
    margin-bottom: -40px;
  }
}
@media (max-width: 767px) {
  .certificate-image {
    padding: 50px 0;
  }
  .certificate-image::before {
    display: none;
  }
  .certificate-image::after {
    display: none;
  }
  .certificate-image .certificate-img-wrapper {
    margin: 25px 0;
  }

  .media {
    margin-top: 0 !important;
  }
  .media .banner-holder h1 {
    margin-bottom: -65px;
    font-size: 70px !important;
  }
  .media::after {
    display: none;
  }

  .media-achievements {
    padding-top: 50px !important;
  }

  .media-achievements.pb-250 {
    padding-bottom: 50px;
  }

  .media-slides {
    padding-bottom: 0;
  }

  .certificate h1 {
    font-size: 50px;
    margin-bottom: -58px;
  }
  .certificate::before {
    display: none;
  }
  .certificate::after {
    display: none;
  }
}
@media (max-width: 575px) {
  .media-achievements h2 {
    font-size: 35px;
    margin-bottom: 0;
  }
}
.it-solutions.inner-services-page {
  padding-bottom: 250px;
}
.it-solutions.inner-services-page .flip-card {
  flex: 0 0 calc(33.33% - 30px);
  margin: 0 15px 30px;
  perspective: 1000px;
}
.it-solutions.inner-services-page .flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
}
.it-solutions.inner-services-page .flip-card .flip-card-inner {
  position: relative;
  transform-style: preserve-3d;
  transition: all 1s ease;
}
.it-solutions.inner-services-page .flip-card .flip-card-inner .flip-card-front img {
  width: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}
.it-solutions.inner-services-page .flip-card .flip-card-inner .flip-card-back {
  background: linear-gradient(146.08deg, #2b3758 -0.65%, #2a9d8f 102.53%);
  position: absolute;
  left: 0;
  top: 0;
  padding: 15px;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  transform: rotateY(180deg);
  backface-visibility: hidden;
}
.it-solutions.inner-services-page .flip-card .flip-card-inner .flip-card-back h4 {
  color: #ffb41f;
  text-transform: uppercase;
}

.services-top h3 {
  font-size: 40px;
  font-weight: 600;
  line-height: 50px;
  text-align: center;
  margin-bottom: 100px;
}
.services-top h3 span {
  color: #ffb41f;
}
.services-top p {
  font-size: 18px;
  line-height: 35px;
}
.services-top p span {
  color: #ffb41f;
}

.new-beginning h5 {
  font-size: 12px;
  line-height: 17px;
  font-weight: 600;
}
.new-beginning .active svg {
  fill: #ffb41f;
}
.new-beginning .active h5 {
  color: #ffb41f;
}

.new-beginning {
  padding: 150px 0 75px;
  position: relative;
}
.new-beginning::before {
  position: absolute;
  content: "";
  width: 131px;
  height: 134px;
  background: url(../images/shapes/Ellipse1.webp);
  background-position: center;
  top: 25%;
  right: -2%;
  background-position: center;
  top: 15%;
  left: 0;
  transform: rotate(140deg);
}
.new-beginning::after {
  position: absolute;
  content: "";
  width: 100px;
  height: 225px;
  border-radius: 12px;
  background: rgba(255, 180, 31, 0.1);
  right: 0;
  transform: rotate(90deg);
  top: 0;
  right: 0;
  top: 0;
  transform: rotate(90deg);
}
.new-beginning p {
  max-width: 500px;
  margin: 0 auto;
  font-size: 20px;
  font-weight: 400;
  text-align: center;
}
.new-beginning h3 {
  font-size: 70px;
  font-weight: 600;
  text-transform: uppercase;
  line-height: 70px;
  margin-bottom: 100px;
  font-size: 50px;
  font-weight: 500;
  text-transform: uppercase;
  line-height: 60px;
  margin-bottom: 60px;
}
.new-beginning h3 span {
  display: block !important;
  color: #ffb41f;
}
.new-beginning h5 {
  background: linear-gradient(89.94deg, #2b3758 -3.98%, #2a9d8f 103.74%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  margin-bottom: 20px;
}
.new-beginning .icon-wrapper {
  text-align: center;
}
.new-beginning .icon-wrapper a {
  text-decoration: none;
  color: none;
}
.new-beginning .icon-wrapper:hover {
  cursor: pointer;
}
.new-beginning .icon-wrapper:hover svg {
  filter: invert(27%) sepia(63%) saturate(2884%) hue-rotate(296deg) brightness(120%) contrast(95%);
}
.new-beginning .icon-wrapper svg {
  width: 51px;
  height: 46px;
  margin-bottom: 30px;
}
.new-beginning .col-lg-3,
.new-beginning .col-xl-3,
.new-beginning .col-xxl-3 {
  width: 20%;
}

.new-beginning.hide-after::after {
  display: none;
}
.new-beginning.hide-after h3 span {
  display: inline-block;
}

.educational-solution {
  padding: 100px 0;
  border-bottom: 1px solid #006b60;
}
.educational-solution__short-description {
  text-align: center;
  margin-bottom: 60px;
}
.educational-solution__short-description h2 {
  font-size: 40px;
  font-weight: 600;
  margin-bottom: 40px;
}
.educational-solution__short-description h2 span {
  color: #ffb41f;
}
.educational-solution__short-description p {
  font-size: 20px;
  line-height: 30px;
  font-weight: 400;
}
.educational-solution__product-logo {
  display: flex;
  flex-wrap: wrap;
  column-gap: 55px;
  row-gap: 150px;
}
.educational-solution__product-logo--details {
  gap: 45px !important;
}
.educational-solution__overlay {
  position: relative;
  cursor: pointer;
  width: 100%;
  max-width: 290px;
  border: 1px solid #2b3758;
}
.educational-solution__overlay h4 {
  position: absolute;
  color: #ffb41f;
  top: 50%;
  width: 100%;
  text-align: center;
  z-index: 1;
  text-transform: uppercase;
  display: none;
  color: #fff;
  top: 40%;
  font-size: 16px;
  line-height: 18px;
  font-weight: 700;
}
.educational-solution__overlay:after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: none;
  background: -webkit-linear-gradient(top, rgba(43, 55, 88, 0.9) 0%, rgba(42, 157, 143, 0.9) 100%);
}
.educational-solution__overlay:hover::after {
  display: block;
}
.educational-solution__overlay:hover h4 {
  display: block;
}
.educational-solution__overlay img {
  display: block;
  padding: 0 10px;
}
.educational-solution__view-more {
  width: 100%;
  max-width: 290px;
}

.boost {
  background: url(../images/boost.webp) no-repeat top center;
  padding: 190px 0;
  background-size: cover;
}
.boost h3 {
  font-size: 40px;
  font-weight: 600;
  color: #fff;
  line-height: 60px;
  margin-bottom: 60px;
}
.boost h3 span {
  display: block;
  color: #ffb41f;
}
.boost a {
  color: #fff;
  border: 2px solid #fff;
  padding: 10px 25px;
  text-decoration: none;
  float: right;
  font-size: 20px;
  text-transform: uppercase;
  font-weight: 600;
  padding: 14px 20px;
  float: none;
}

.it-solutions.inner-services-page {
  padding-bottom: 250px;
}
.it-solutions.inner-services-page .flip-card {
  flex: 0 0 calc(33.33% - 30px);
  margin: 0 15px 30px;
  perspective: 1000px;
}
.it-solutions.inner-services-page .flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
}
.it-solutions.inner-services-page .flip-card .flip-card-inner {
  position: relative;
  transform-style: preserve-3d;
  transition: all 1s ease;
}
.it-solutions.inner-services-page .flip-card .flip-card-inner .flip-card-front img {
  width: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}
.it-solutions.inner-services-page .flip-card .flip-card-inner .flip-card-back {
  background: linear-gradient(146.08deg, #2b3758 -0.65%, #2a9d8f 102.53%);
  position: absolute;
  left: 0;
  top: 0;
  padding: 15px;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  transform: rotateY(180deg);
  backface-visibility: hidden;
}
.it-solutions.inner-services-page .flip-card .flip-card-inner .flip-card-back h4 {
  color: #ffb41f;
  text-transform: uppercase;
}

.services {
  padding: 0;
  position: relative;
}
.services .home-service-main {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}
.services .home-service-main .services-left {
  background: linear-gradient(146.08deg, #2b3758 -0.65%, #2a9d8f 102.53%);
  min-height: 100%;
  width: 40%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.services .home-service-main .services-left li {
  font-size: 35px;
  font-weight: 400;
  line-height: 70px;
  color: #fff;
}
.services .home-service-main .services-left li a {
  text-decoration: none;
  color: #fff;
  text-transform: uppercase;
}
.services .home-service-main .services-img {
  width: 60%;
}
.services h1 {
  text-align: right;
  font-size: 180px;
  font-weight: 600;
  color: #e7e7e7;
  margin-bottom: -43px;
  position: relative;
  z-index: -1;
}
.services img {
  width: 100%;
  height: 800px;
  object-fit: cover;
}

.services-top h1 {
  font-size: 180px;
  font-weight: 700;
  text-transform: uppercase;
  color: #e7e7e7;
  line-height: 180px;
  margin-top: -25px;
}
.services-top h2 {
  font-size: 40px;
  margin-bottom: 25px;
  line-height: 60px;
  font-weight: 600;
}
.services-top h2 span {
  color: #ffb41f;
}
.services-top p {
  font-size: 25px;
  line-height: 46px;
  font-weight: 400;
}
.services-top .img-right {
  position: relative;
  margin-top: -50px;
}
.services-top .img-right::after {
  position: absolute;
  content: "";
  background: linear-gradient(146.08deg, #2b3758 -0.65%, #2a9d8f 102.53%);
  width: 100%;
  height: 100%;
  left: 4%;
  z-index: -1;
  top: 5%;
}

.inner-services-page .left-img-holder img {
  margin: 0;
}

@media (max-width: 1399px) and (min-width: 1200px) {
  .services .home-service-main .services-left li {
    font-size: 30px;
    line-height: 60px;
  }

  .educational-solution__product-logo {
    row-gap: 190px;
  }
  .educational-solution__overlay, .educational-solution__view-more {
    max-width: 245px;
  }
}
@media (max-width: 1199px) and (min-width: 992px) {
  .services-top h3 {
    font-size: 32px;
    line-height: 50px;
  }
  .services-top p {
    font-size: 18px;
    line-height: 35px;
  }

  .inner-services-page .sliding-side h4 {
    font-size: 30px;
    line-height: 45px;
    margin-bottom: 20px;
  }
  .inner-services-page .sliding-side p {
    font-size: 15px;
    line-height: 25px;
  }

  .inner-services .flip-card {
    min-height: 372px;
  }

  .educational-solution__product-logo {
    column-gap: 25px;
  }
  .educational-solution__overlay, .educational-solution__view-more {
    max-width: 215px;
  }
}
@media (max-width: 991px) and (min-width: 768px) {
  .services .home-service-main .services-left li {
    font-size: 20px;
    line-height: 40px;
  }
  .services .services-img img {
    height: auto;
  }
  .services h1 {
    font-size: 120px;
    margin-bottom: -28px;
  }
  .services h1::before {
    top: -75%;
  }

  .services-top h1 {
    font-size: 125px;
  }
  .services-top h2 {
    font-size: 25px;
    line-height: 35px;
    margin-bottom: 15px;
  }
  .services-top p {
    font-size: 15px;
    line-height: 25px;
  }
  .services-top .img-right {
    margin-top: -60px;
  }

  .inner-services-page .sliding-side {
    justify-content: flex-start;
  }
  .inner-services-page .sliding-side h4 {
    font-size: 25px;
    margin-bottom: 0;
  }
  .inner-services-page .sliding-side p {
    font-size: 14px;
    line-height: 22px;
  }
  .inner-services-page .left-img-holder {
    margin-bottom: 30px;
  }

  .btn-outline-white {
    width: 150px !important;
    height: 50px !important;
    font-size: 18px !important;
    line-height: 50px !important;
    margin-top: 20px !important;
  }

  .educational-solution__short-description p {
    font-size: 16px;
    line-height: 24px;
  }
  .educational-solution__product-logo {
    column-gap: 15px;
    row-gap: 115px;
  }
  .educational-solution__overlay, .educational-solution__view-more {
    max-width: 160px;
  }
}
@media (max-width: 516px) and (min-width: 375px) {
  .services .services-left li {
    font-size: 20px !important;
    line-height: 40px !important;
  }

  .btn-outline-white {
    width: 150px !important;
    height: 45px !important;
    font-size: 18px !important;
    line-height: 44px !important;
    margin-top: 25px !important;
  }

  .educational-solution__product-logo {
    justify-content: center;
    row-gap: 15px !important;
  }
}
@media (max-width: 767px) {
  .it-solutions .sliding-side p {
    font-size: 16px;
    line-height: 24px;
    text-align: center;
  }

  .new-beginning::before {
    display: none;
  }
  .new-beginning .col-lg-3,
.new-beginning .col-xl-3,
.new-beginning .col-xxl-3 {
    width: 50%;
  }

  .new-beginning::after {
    display: none;
  }

  .it-solutions .sliding-side h2 {
    font-size: 25px;
    text-align: center;
    line-height: 25px;
    margin-bottom: 20px;
  }

  .boost a {
    font-size: 14px;
  }

  .boost {
    padding: 100px 0 !important;
  }

  .it-solutions .sliding-side p {
    font-size: 16px !important;
    line-height: 24px;
    text-align: center;
  }

  .new-beginning {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .new-beginning h3 {
    font-size: 22px;
    line-height: 32px;
    margin-bottom: 20px;
  }

  .services {
    padding-top: 50px;
    padding-bottom: 0;
  }
  .services::before {
    display: none;
  }
  .services .home-service-main {
    flex-direction: row-reverse;
    flex-wrap: wrap-reverse;
  }
  .services .home-service-main .services-left {
    padding: 30px 0;
    width: 100%;
    min-height: auto;
    justify-content: flex-start;
    padding-left: 20px;
  }
  .services .home-service-main .services-left li {
    font-size: 25px;
    line-height: 50px;
  }
  .services .home-service-main .services-img {
    width: 100%;
    padding: 0 20px;
  }
  .services h1 {
    font-size: 80px;
    margin-bottom: -19px;
  }
  .services h1::before {
    display: none;
  }

  .services-top {
    padding-top: 20px;
    padding-bottom: 75px;
  }
  .services-top .w-reverse {
    flex-wrap: wrap-reverse;
  }
  .services-top h1 {
    font-size: 85px;
  }
  .services-top h2 {
    font-size: 25px;
    line-height: 35px;
  }
  .services-top h3 {
    font-size: 22px;
    line-height: 32px;
  }
  .services-top p {
    font-size: 16px;
    line-height: 28px;
  }
  .services-top .img-right {
    margin-top: -75px;
    margin-bottom: 60px;
  }

  #cms-dev {
    flex-wrap: wrap-reverse;
    margin: 50px 0;
  }

  .boost h3 {
    font-size: 25px;
    line-height: 45px;
  }

  .educational-solution__short-description p {
    font-size: 16px;
    line-height: 24px;
  }
  .educational-solution__short-description h2 {
    font-size: 30px;
  }
  .educational-solution__product-logo {
    column-gap: 25px;
    row-gap: 50px;
    margin-block-start: 50px;
  }
  .educational-solution__overlay, .educational-solution__view-more {
    max-width: 245px;
  }

  .reverse {
    flex-wrap: wrap-reverse;
  }
}
@media (max-width: 575px) {
  .new-beginning h5 {
    font-size: 12px;
    line-height: 17px;
    font-weight: 600;
  }

  .services-top h1 {
    font-size: 50px;
    font-weight: 800;
  }
  .services-top .img-right {
    margin-top: -85px;
  }

  .services-top h1 {
    font-size: 50px;
    font-weight: 800;
  }
  .services-top .img-right {
    margin-top: -85px;
  }
}
@media (max-width: 375px) {
  .boost h3 {
    font-size: 18px;
    line-height: 30px;
  }

  .services .services-left li {
    font-size: 18px !important;
    line-height: 36px !important;
  }
  .services h1 {
    font-size: 55px;
    margin-bottom: -14px;
  }

  .btn-outline-white {
    width: 150px !important;
    height: 45px !important;
    font-size: 18px !important;
    line-height: 44px !important;
  }

  .educational-solution__product-logo {
    justify-content: center;
    row-gap: 15px !important;
  }
}