@import 'https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap';
/*font-family: 'Poppins', sans-serif;*/
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  line-height: 1.1;
  font-family: "Poppins", sans-serif;
}

p {
  font-family: "Poppins", sans-serif;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}

html {
  overflow-x: hidden;
  font-family: "Poppins", sans-serif;
}

body {
  overflow-x: hidden;
  font-family: "Poppins", sans-serif;
}

/*****Breadcrumb Section Starts*****/
.page-breadcrumb {
  padding: 0 115px;
}
.page-breadcrumb .breadcrumb {
  justify-content: flex-end;
  margin-top: 1rem;
}
.page-breadcrumb .breadcrumb a {
  color: #000;
  text-decoration: none;
  font-size: 16px;
  font-weight: 300;
}
.page-breadcrumb .breadcrumb i {
  padding: 0 10px;
}

.page-breadcrumb {
  padding: 0 115px;
}
.page-breadcrumb .breadcrumb {
  justify-content: flex-end;
  margin-top: 1rem;
}
.page-breadcrumb .breadcrumb a {
  color: #000;
  text-decoration: none;
  font-size: 16px;
  font-weight: 300;
}
.page-breadcrumb .breadcrumb i {
  padding: 0 10px;
}

/*****Breadcrumb Section Ends*****/
/*****Home Carousel Style Starts*****/
.carousel-control-next {
  opacity: 0;
}
.carousel-control-next:focus {
  opacity: 0;
}
.carousel-control-next:hover {
  opacity: 0;
}

.carousel-control-prev {
  opacity: 0;
}
.carousel-control-prev:focus {
  opacity: 0;
}
.carousel-control-prev:hover {
  opacity: 0;
}

.carousel-caption {
  text-align: left;
  left: 5%;
  top: 55%;
}
.carousel-caption h3 {
  font-size: 120px;
  line-height: 120px;
  font-weight: 700;
  color: #07102a;
}
.carousel-caption p {
  font-size: 50px;
  font-weight: 400;
  color: #fff;
  margin-bottom: 50px;
}
.carousel-caption p:last-child {
  max-width: 300px;
  width: 100%;
  background: #ffb41f;
  height: 2px;
  margin-left: 25px;
}
.carousel-caption a {
  color: #fff;
  border: 2px solid #fff;
  padding: 10px 25px;
  text-decoration: none;
  float: right;
  font-size: 20px;
  text-transform: uppercase;
  font-weight: 600;
  float: none !important;
}
.carousel-caption .banner-text {
  display: flex;
  align-items: center;
}

.carousel-indicators {
  display: none;
}

/*****Home Carousel Style Ends*****/
.home-banner {
  overflow: hidden;
  position: relative;
}
.home-banner__single-img img {
  width: 100%;
  height: 950px;
  object-fit: cover;
  animation: zoom-in-zoom-out 55s ease infinite;
}
.home-banner__single-img .mouse-btn {
  display: block !important;
}
.home-banner__single-img .mouse-btn a {
  background-image: url(../images/icons/mouse_icon.webp);
  background-repeat: no-repeat;
  bottom: 8%;
  content: "";
  display: block;
  height: 60px;
  left: 50%;
  margin-left: -20px;
  position: absolute;
  width: 40px;
  z-index: 9;
  -webkit-animation-name: floating;
  animation-name: floating;
  -webkit-animation-duration: 3s;
  animation-duration: 3s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
}
@keyframes zoom-in-zoom-out {
  0% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(1.1, 1.1);
  }
  100% {
    transform: scale(1, 1);
  }
}
.home-banner__health-exibition {
  max-width: 450px;
  width: 100%;
  height: auto;
  position: absolute;
  inset-block-start: 30%;
  inset-inline-end: 5%;
  background-color: rgba(255, 255, 255, 0.5);
  box-sizing: border-box;
  padding: 20px;
  box-shadow: 0 0 5px #2b3758;
  border-radius: 3px;
}
.home-banner__exibition-content h1 {
  font-size: 27px;
  line-height: 27px;
  font-weight: 600;
  margin-block-end: 15px;
}
.home-banner__exibition-content h3 {
  font-size: 20px;
  line-height: 26px;
  font-weight: 500;
}
.home-banner__exibition-content p {
  font-size: 16px;
  line-height: 25px;
  font-weight: 500;
}

.spinner-grow {
  width: 3rem;
  height: 3rem;
  visibility: hidden;
}

.spinner-grow.spinnershow {
  width: 3rem;
  height: 3rem;
  visibility: visible;
}

.term::after {
  top: 1% !important;
}

@media (max-width: 1199px) and (min-width: 992px) {
  .know-more h1 {
    font-size: 28px;
  }

  .carousel-caption {
    top: 65%;
    left: 10%;
  }
  .carousel-caption h3 {
    font-size: 90px;
  }
  .carousel-caption p {
    font-size: 35px;
  }
  .carousel-caption p:last-child {
    max-width: 250px;
  }
}
@media (max-width: 991px) and (min-width: 768px) {
  .home-banner__single-img img {
    height: auto;
  }

  .carousel-caption {
    top: 55%;
  }
  .carousel-caption h3 {
    font-size: 60px;
    line-height: 90px;
  }
  .carousel-caption p {
    font-size: 22px;
  }
  .carousel-caption p:last-child {
    max-width: 170px;
  }

  .buttons-wrapper a {
    display: block;
    text-align: center;
    margin: 10px 0;
  }

  .top-banner h1 {
    font-size: 100px;
    margin-top: -53px;
  }
  .top-banner .solution-pages-main-title {
    top: 40%;
  }
  .top-banner .solution-pages-main-title h2 {
    font-size: 60px !important;
    line-height: 60px;
  }

  .form-wrapper .form-check-input {
    margin-bottom: 61px;
  }

  .sec-achievement .img-box img {
    height: auto;
  }

  .home-banner__health-exibition {
    inset-block-start: 25%;
    inset-inline-end: 2%;
  }
}
@media (max-width: 516px) and (min-width: 375px) {
  .know-more a {
    width: 60% !important;
  }

  .carousel-caption {
    top: 30%;
  }
  .carousel-caption p {
    font-size: 18px;
  }
  .carousel-caption a {
    font-size: 13px !important;
  }

  .buttons-wrapper a {
    display: block;
    text-align: center;
    margin: 10px 0;
  }

  .form-wrapper .form-check-input {
    margin-bottom: 55px;
  }
}
@media (max-width: 767px) {
  .know-more {
    margin-bottom: 50px;
  }
  .know-more h1 {
    text-align: center;
    margin-bottom: 20px;
  }
  .know-more a {
    float: none;
    margin: 20px auto 0;
    display: block;
    width: 40%;
    text-align: center;
  }

  .left-img-holder.float-end {
    float: none !important;
  }

  .form-wrapper {
    padding-top: 0;
    padding-bottom: 50px;
  }
  .form-wrapper::before {
    display: none;
  }
  .form-wrapper::after {
    display: none;
  }
  .form-wrapper h2 {
    font-size: 40px;
    line-height: 45px !important;
    line-height: 30px;
  }
  .form-wrapper .form-check {
    margin-bottom: 30px;
  }
  .form-wrapper a {
    float: left;
  }
  .form-wrapper label {
    font-size: 14px;
  }
  .form-wrapper__contact-box {
    margin-top: 20px;
  }

  .hide-in-mobile {
    display: none;
  }

  .carousel-caption {
    top: 50%;
  }
  .carousel-caption h3 {
    font-size: 40px;
    line-height: 60px;
  }
  .carousel-caption p {
    font-size: 18px;
  }
  .carousel-caption p:last-child {
    max-width: 70px;
  }
  .carousel-caption a {
    font-size: 13px !important;
  }

  .home-banner__health-exibition {
    max-width: 100%;
    position: relative;
    inset-inline: 0;
    margin: 70px auto 50px;
    background: linear-gradient(146.08deg, #2b3758 -0.65%, #2a9d8f 102.53%);
    padding: 25px;
  }
  .home-banner__exibition-content {
    max-width: 450px;
    width: 100%;
    margin: 0 auto;
    background-color: rgba(255, 255, 255, 0.2);
    padding: 20px;
  }
  .home-banner__exibition-content h1,
.home-banner__exibition-content h3,
.home-banner__exibition-content p {
    color: #fff;
  }
}
@media (max-width: 575px) {
  .container {
    padding: 0 25px;
  }

  .carousel-caption h3 {
    font-size: 25px;
    line-height: 25px;
  }

  .carousel-caption p {
    font-size: 13px;
    margin-bottom: 20px;
  }

  .carousel-caption p:last-child {
    max-width: 40px;
    margin-left: 10px;
  }

  .form-wrapper h2 {
    font-size: 25px;
    line-height: 30px !important;
    margin-bottom: 50px;
  }
}
@media (max-width: 375px) {
  .know-more a {
    width: 70%;
  }

  .navbar-brand {
    max-width: 50% !important;
  }

  .buttons-wrapper a {
    display: block;
    text-align: center;
    margin: 10px 0;
    font-size: 14px;
  }

  .carousel-caption h3 {
    margin: 0;
  }
  .carousel-caption p {
    margin-bottom: 0;
  }
  .carousel-caption a {
    padding: 2px 10px;
  }

  .form-wrapper .form-check-input {
    margin-bottom: 55px;
  }
  .form-wrapper .form-check-label {
    font-size: 13px;
  }
  .form-wrapper__contact-box p {
    font-size: 14px;
  }

  .top-banner h1 {
    font-size: 35px;
    margin-top: -77px;
  }
  .top-banner .solution-pages-main-title h2 {
    font-size: 30px;
  }

  .page-breadcrumb {
    padding: 0 30px;
  }
}
.btn-outline-white {
  text-decoration: none;
  color: #fff;
  width: 267px;
  height: 67px;
  border: 1px solid #fff;
  display: block;
  font-size: 30px;
  font-weight: 300;
  font-family: Poppins, sans-serif;
  text-align: center;
  line-height: 65px;
  margin-top: 25px;
}
.btn-outline-white:hover {
  color: #fff;
}
.btn-outline-white--font-border {
  border-color: #006b60;
  color: #006b60;
  margin: 50px auto 0;
  font-size: 18px;
  font-weight: 500;
  width: 150px;
  line-height: 50px;
  height: 50px;
}
.btn-outline-white--font-border:hover {
  background-color: #ffb41f;
  border-color: #ffb41f;
  color: #000;
}

span.partner-link {
  position: fixed;
  z-index: 99;
  display: block;
  background: #07102a;
  right: -37px;
  top: 50%;
  transform: rotate(-90deg);
  text-align: center;
  padding: 10px 15px;
  color: #fff;
  font-size: 20px;
  font-weight: 300;
  line-height: 20px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.carousel .mouse-btn {
  display: block !important;
}
.carousel .mouse-btn a {
  background-image: url(../images/icons/mouse_icon.webp);
  background-repeat: no-repeat;
  bottom: 8%;
  content: "";
  display: block;
  height: 60px;
  left: 50%;
  margin-left: -20px;
  position: absolute;
  width: 40px;
  z-index: 9;
  -webkit-animation-name: floating;
  animation-name: floating;
  -webkit-animation-duration: 3s;
  animation-duration: 3s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
}

.ugi {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  background: linear-gradient(89.96deg, rgba(255, 255, 255, 0.34) -2.13%, rgba(255, 255, 255, 0.46) 102.5%);
  border-bottom: 1px solid rgba(255, 255, 255, 0.95);
  width: 100%;
  top: 9.6%;
  left: 0;
  right: 0;
  padding: 10px 120px;
}
.ugi__global-logo {
  margin-right: 20px;
}
.ugi p {
  font-size: 20px;
  font-weight: 600;
  line-height: 20px;
  margin: 0;
  color: #ff0000;
}

.about-us {
  padding: 100px 0;
  background: #fffaf0;
  position: relative;
  z-index: 0;
}
.about-us .about-img {
  position: relative;
}
.about-us .about-img::after {
  position: absolute;
  content: "";
  background-color: #ffb41f;
  width: 80%;
  height: 100%;
  z-index: -1;
  top: 5%;
  right: 15%;
}
.about-us .about-img h2 {
  transform: rotate(270deg);
  position: absolute;
  right: -60px;
  bottom: 38%;
  color: #fff;
  font-size: 50px;
  font-weight: 600;
}
.about-us .about-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100%;
}
.about-us .about-text h3 {
  font-size: 27px;
  font-weight: 700;
  line-height: 42px;
  color: #2b3758;
  margin-bottom: 50px;
}
.about-us .about-text p {
  font-size: 18px;
  color: #2b3758;
  line-height: 32px;
  font-weight: 400;
}
.about-us .about-text p span {
  font-weight: 600;
}

.home-video {
  background: #fffaf0;
  padding: 100px 0;
  border-top: 1px solid #e1e1e1;
}
.home-video__text p {
  font-size: 18px;
  color: #2b3758;
  line-height: 28px;
  font-weight: 400;
}
.home-video__text p span {
  font-weight: 600;
}
.home-video .swiper-button-prev:after {
  color: #fff;
  font-size: 30px;
}
.home-video .swiper-button-next:after {
  color: #fff;
  font-size: 30px;
}

.short-intro {
  padding: 100px 0;
}
.short-intro p {
  text-align: center;
  font-size: 17px;
  line-height: 28px;
}
.short-intro h4 {
  text-align: center;
  margin-block: 25px;
  font-weight: 600;
}

.home-services {
  padding: 100px 0;
  background-color: #f9f9f9;
}
.home-services h1 {
  font-size: 60px;
  font-weight: 700;
  line-height: 100px;
  color: #2b3758;
  text-align: center;
  margin-bottom: 10px;
}
.home-services h1 span {
  color: #ffb41f;
}
.home-services p {
  text-align: center;
  font-family: Poppins;
  font-size: 20px;
  font-weight: 400;
  max-width: 510px;
  margin: 0 auto;
}
.home-services__service-wrapper {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-top: 50px;
}
.home-services__service-box {
  text-align: center;
  width: 20%;
}
.home-services__service-box img {
  border-radius: 50%;
  margin-bottom: 15px;
  border: 3px dashed #ffb41f;
  padding: 8px;
}
.home-services__service-box h6 {
  font-size: 20px;
  line-height: 26px;
  margin: 0;
}
.home-services__service-box a {
  text-decoration: none;
  color: #2b3758 !important;
}

.solutions {
  padding: 100px 0 0 !important;
}
.solutions .col-xxl-3 {
  width: 20%;
}
.solutions a {
  text-decoration: none;
}
.solutions .text-left h1 {
  font-size: 80px;
  font-weight: 700;
  line-height: 80px;
  color: #2b3758;
  font-size: 73px;
  line-height: 90px;
}
.solutions .text-left h1 span {
  color: #ffb41f;
}
.solutions .text-right p {
  font-size: 20px;
  font-weight: 400;
  line-height: 38px;
  margin-top: 15px;
}
.solutions .solution-box {
  background: none;
  padding: 50px;
  min-height: 325px;
  display: block;
}
.solutions .solution-box:hover h4 {
  color: #fff;
}
.solutions .solution-box:hover p {
  color: #fff;
}
.solutions .solution-box h4 {
  color: #2b3758;
  margin-bottom: 15px;
  font-weight: 600;
  font-size: 20px;
}
.solutions .solution-box p {
  color: #2b3758;
  font-size: 16px;
  line-height: 21px;
  font-weight: 400;
  margin: 0;
}
.solutions .box-one {
  background: url(../images/Business-Operations.webp);
  background-position: center;
  box-shadow: 5px 5px 20px #b7b7b7;
  cursor: pointer;
}
.solutions .box-one h4,
.solutions .box-one p {
  color: #fff;
}
.solutions .box-two:hover {
  background: url(../images/Data-Analytics.webp);
  background-position: center;
  box-shadow: 5px 5px 20px #b7b7b7;
  cursor: pointer;
}
.solutions .box-three:hover {
  background: url(../images/Digital-Engineering.webp);
  background-position: center;
  box-shadow: 5px 5px 20px #b7b7b7;
  cursor: pointer;
}
.solutions .box-four:hover {
  background: url(../images/cloud.webp);
  background-position: center;
  box-shadow: 5px 5px 20px #b7b7b7;
  cursor: pointer;
}
.solutions .top-space {
  margin-top: 50px;
}

.solutions {
  padding-top: 0;
  padding-bottom: 0;
}

.solution-box h4 {
  font-size: 28px;
  /*line-height: 35px;*/
}
.solution-box p {
  font-size: 16px;
  line-height: 25px;
}

.home-add-section .nutri-add {
  padding-top: 80px;
  background: rgba(239, 255, 233, 0.68);
}
.home-add-section .nutri-add .add-img {
  position: relative;
}
.home-add-section .nutri-add .add-img::before {
  position: absolute;
  content: "";
  width: 250px;
  height: 40px;
  left: -14px;
  bottom: 0px;
  background-color: #5dc267;
}
.home-add-section .nutri-add .digi-add-img-holder::before {
  background-color: #0075ff;
}
.home-add-section .nutri-add.add-bg-blue {
  background: #f0fdff;
}
.home-add-section .add-info {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
}
.home-add-section .add-info .add-content {
  padding-left: 75px;
}
.home-add-section .add-info .add-content h1 {
  font-size: 90px;
  font-weight: 700;
  margin-bottom: 25px;
  color: #5dc267;
}
.home-add-section .add-info .add-content h1 span {
  color: #441c29;
}
.home-add-section .add-info .add-content h1.title-blue {
  color: #000;
  font-weight: 300;
}
.home-add-section .add-info .add-content h1.title-blue span {
  color: #0075ff;
  font-weight: 700;
}
.home-add-section .add-info .add-content p {
  font-size: 25px;
  line-height: 33px;
  font-weight: 400;
  margin-bottom: 50px;
}
.home-add-section .add-info .add-content a {
  color: #000;
  border: 2px solid #000;
  text-decoration: none;
  font-size: 20px;
  font-weight: 600;
  padding: 10px 30px;
}

.product-section {
  padding: 100px 0 0;
  position: relative;
  margin-bottom: 100px;
}
.product-section::before {
  position: absolute;
  content: "";
  width: 136px;
  height: 150px;
  background: url(../images/shapes/Ellipse.webp);
  background-position: center;
  left: 5%;
  top: 0;
  background-position: center;
  left: -3%;
  top: 7%;
}
.product-section::after {
  position: absolute;
  content: "";
  width: 100px;
  height: 225px;
  border-radius: 12px;
  background: rgba(255, 180, 31, 0.1);
  right: 0;
  transform: rotate(90deg);
  top: 0;
  right: 0;
  transform: rotate(90deg);
  top: -10%;
}
.product-section h1 {
  font-size: 80px;
  font-weight: 700;
  line-height: 80px;
  color: #2b3758;
  margin-bottom: 70px;
}
.product-section h1 span {
  color: #ffb41f;
}
.product-section .products {
  padding-top: 38px;
}
.product-section .products .bg-img-wrapper {
  background: url(../images/screenshot/Medvantage.webp);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  padding: 100px 0;
}
.product-section .products .bg-img-wrapper .number {
  background-color: #ffb41f;
  height: 100px;
  width: 80px;
  color: #fff;
  font-size: 70px;
  font-weight: 700;
  text-align: center;
  position: absolute;
  top: 0;
  right: 17%;
}
.product-section .products .bg-img-wrapper .detail-container {
  background-color: #fff;
  width: 66%;
  height: auto;
  margin: 0 auto;
  padding: 50px;
  box-shadow: 0 0 8px #07102a;
}
.product-section .products .bg-img-wrapper .detail-container .logo {
  margin-bottom: 20px;
  width: 30%;
  min-height: 50px;
}
.product-section .products .bg-img-wrapper .detail-container .content {
  margin-bottom: 50px;
  font-size: 16px;
  line-height: 30px;
  font-weight: 400;
}
.product-section .products .bg-img-wrapper .detail-container .buttons-wrapper a {
  background-color: #388697;
  color: #fff;
  text-decoration: none;
  border: 1px solid #388697;
  padding: 7px 10px;
}
.product-section .products .bg-img-wrapper .detail-container .buttons-wrapper a:last-child {
  background-color: transparent !important;
  color: #388697;
  font-size: 16px;
  font-weight: 600;
  margin-left: 5px;
}
.product-section .products .swiper-slide-prev {
  position: relative;
}
.product-section .products .swiper-slide-prev::before {
  top: 0;
  left: 0;
  position: absolute;
  content: "";
  background: rgba(255, 255, 255, 0.5490196078);
  width: 100%;
  height: 100%;
}
.product-section .products .swiper-slide-next {
  position: relative;
}
.product-section .products .swiper-slide-next::before {
  top: 0;
  left: 0;
  position: absolute;
  content: "";
  background: rgba(255, 255, 255, 0.5490196078);
  width: 100%;
  height: 100%;
}
.product-section .products .digi-doctor {
  background: url(../images/screenshot/DigiDoctor.webp);
}
.product-section .products .nutri {
  background: url(../images/screenshot/NutriAnalyser.webp);
}
.product-section .products .care {
  background: url(../images/screenshot/Care.webp);
}
.product-section .products .his {
  background: url(../images/screenshot/HIS.webp);
}
.product-section .products .edumation {
  background: url(../images/screenshot/Edumation.webp);
}
.product-section .products .corncall {
  background: url(../images/screenshot/CornCall.webp);
}
.product-section .products .medico {
  background: url(../images/screenshot/MedicoSkills.webp);
}
.product-section .products .lms {
  background: url(../images/screenshot/LMS.webp);
}

.services .home-service-main .services-left li {
  font-size: 25px;
  line-height: 50px;
}
.services h1 {
  font-size: 150px;
  margin-bottom: -37px;
}

/*.sec-achievement-heading {
  padding: 50px 0;
  &::before {
    display: none;
  }
  &::after {
    display: none;
  }
  h1 {
    font-size: 40px;
    line-height: 45px;
    &::before {
      display: none;
    }
    &::after {
      display: none;
    }
  }
}*/
.sec-achievement .swiper-button-prev {
  height: 21px;
  width: 21px;
}
.sec-achievement .swiper-button-prev::before {
  font-size: 12px;
}
.sec-achievement .swiper-button-prev::after {
  font-size: 12px;
}
.sec-achievement .swiper-button-next {
  height: 21px;
  width: 21px;
}
.sec-achievement .swiper-button-next::before {
  font-size: 12px;
}
.sec-achievement .swiper-button-next::after {
  font-size: 12px;
}

.choose h1 {
  font-size: 40px;
  font-weight: 700;
  line-height: 50px;
  color: #2b3758;
  text-align: left;
  margin-bottom: 50px;
}
.choose h1 span {
  color: #ffb41f;
}
.choose__content h4 {
  margin-block: 20px;
  font-size: 22px;
  font-weight: 500;
}
.choose__content h4 span {
  color: #ffb41f;
}
.choose__content p {
  font-size: 16px;
  line-height: 26px;
  font-weight: 400;
  color: #2b3758;
  margin: 0;
}

.sec-client h1 {
  font-size: 55px;
  line-height: 65px;
}
.sec-client h3 {
  font-size: 30px;
}
.sec-client p {
  width: 45%;
}

.Video-testimonial {
  margin: 100px 0;
}
.Video-testimonial h1 {
  font-size: 80px;
  font-weight: 700;
  line-height: 80px;
  color: #2b3758;
  position: relative;
}
.Video-testimonial h1 span {
  color: #ffb41f;
}
.Video-testimonial .video-box {
  width: 70%;
  margin: 0 auto;
}
.Video-testimonial .swiper-button-prev {
  left: 0px !important;
}
.Video-testimonial .swiper-button-prev:after {
  color: #ffb41f;
}
.Video-testimonial .swiper-button-next {
  right: 0px !important;
}
.Video-testimonial .swiper-button-next:after {
  color: #ffb41f;
}

.sec-achievement-heading {
  padding: 100px 0 50px;
  position: relative;
}
.sec-achievement-heading::after {
  position: absolute;
  content: "";
  width: 131px;
  height: 134px;
  background: url(../images/shapes/Ellipse1.webp);
  background-position: center;
  top: 25%;
  right: -2%;
  background-position: center;
  top: 25%;
  left: -3%;
}
.sec-achievement-heading::before {
  position: absolute;
  content: "";
  width: 100px;
  height: 225px;
  border-radius: 12px;
  background: rgba(255, 180, 31, 0.1);
  right: 0;
  transform: rotate(90deg);
  top: 0;
  transform: rotate(90deg);
  top: -10%;
}
.sec-achievement-heading h1 {
  font-size: 80px;
  font-weight: 700;
  line-height: 80px;
  color: #2b3758;
  font-size: 40px;
  line-height: 50px;
}
.sec-achievement-heading h1 span {
  color: #ffb41f;
}

.sec-achievement {
  padding: 0 0 100px 0px;
  position: relative;
}
.sec-achievement::before {
  position: absolute;
  content: "";
  max-width: 320px;
  width: 100%;
  min-height: 454px;
  background: linear-gradient(146.08deg, #2b3758 -0.65%, #2a9d8f 102.53%);
  left: 0;
  top: 0;
  z-index: 9;
  display: none;
}
.sec-achievement .img-box img {
  width: 100%;
  max-width: 267px;
  height: 300px;
  object-fit: cover;
}
.sec-achievement .swiper-button-prev {
  color: #fff;
  background-color: #fff;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  position: relative;
  margin-right: 40px;
}
.sec-achievement .swiper-button-prev::after {
  font-size: 18px;
  color: #000;
  font-weight: 700;
}
.sec-achievement .swiper-button-prev:hover {
  background-color: #ffb41f;
}
.sec-achievement .swiper-button-prev:hover::after {
  color: #fff;
}
.sec-achievement .swiper-button-next {
  color: #fff;
  background-color: #fff;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  position: relative;
}
.sec-achievement .swiper-button-next::after {
  font-size: 18px;
  color: #000;
  font-weight: 700;
}
.sec-achievement .swiper-button-next:hover {
  background-color: #ffb41f;
}
.sec-achievement .swiper-button-next:hover::after {
  color: #fff;
}
.sec-achievement .left-gradient {
  background: linear-gradient(146.08deg, #2b3758 -0.65%, #2a9d8f 102.53%);
  display: flex;
  justify-content: center;
  height: 100%;
  width: 95%;
}

.our-credential {
  padding: 50px 0;
  position: relative;
}
.our-credential h1 {
  font-size: 80px;
  font-weight: 700;
  line-height: 80px;
  color: #2b3758;
  font-size: 40px;
  line-height: 50px;
  padding-bottom: 30px;
}
.our-credential h1 span {
  color: #ffb41f;
}
.our-credential p {
  font-size: 18px;
  font-weight: 400;
}
.our-credential .swiper-slide .logo-box {
  width: 222px;
  text-align: center;
}
.our-credential .swiper-slide .logo-box img {
  width: auto;
  height: 80px;
}

.sec-client {
  padding: 100px 0;
  position: relative;
}
.sec-client::before {
  position: absolute;
  content: "";
  width: 100px;
  height: 225px;
  border-radius: 12px;
  background: rgba(255, 180, 31, 0.1);
  right: 0;
  transform: rotate(90deg);
  top: 0;
  left: 0%;
  top: 20%;
  transform: rotate(0deg);
  height: 350px;
}
.sec-client::after {
  position: absolute;
  content: "";
  width: 175px;
  height: 175px;
  border-radius: 50%;
  background: rgba(187, 189, 194, 0.6);
  right: -4%;
  top: 25%;
  right: -4%;
  top: 25%;
}
.sec-client h1 {
  font-size: 80px;
  font-weight: 700;
  line-height: 80px;
  color: #2b3758;
  position: relative;
}
.sec-client h1 span {
  color: #ffb41f;
}
.sec-client h3 {
  font-size: 40px;
  font-weight: 400;
}
.sec-client p {
  width: 60%;
  text-align: center;
  margin: 0 auto;
  font-size: 18px;
  font-weight: 400;
  line-height: 30px;
}

.success-message {
  color: #5dc267;
  font-size: 20px;
  border: 1px solid #5dc267;
  border-radius: 5px;
  padding: 5px 10px;
}

.error-message {
  color: #ff0000;
  font-size: 20px;
  border: 1px solid #ff0000;
  border-radius: 5px;
  padding: 5px 10px;
}

.healthcare {
  position: relative;
}
.healthcare__healthcare-content-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.healthcare__healthcare-content {
  background-color: #fff;
  position: absolute;
  left: 5%;
  width: 50%;
  padding: 50px;
}
.healthcare__healthcare-content h3 {
  color: #006b60;
  font-size: 30px;
  font-weight: 500;
}
.healthcare__healthcare-content p {
  color: #2b3758;
  font-size: 20px;
  font-weight: 400;
}

@media (max-width: 1440px) and (min-width: 1366px) {
  .solutions .text-left h1 {
    font-size: 59px !important;
    line-height: 80px !important;
  }
  .solutions .text-right p {
    font-size: 16px;
    line-height: 32px;
  }
  .solutions .solution-box {
    padding: 40px;
  }
  .solutions .solution-box h4 {
    font-size: 16px;
    line-height: 20px;
  }
  .solutions .solution-box p {
    font-size: 14px;
    line-height: 18px;
  }
}
@media (max-width: 1399px) and (min-width: 1200px) {
  .about-us .about-img:after {
    width: 90%;
    left: 7%;
  }

  .about-us .about-text p {
    line-height: 27px;
  }

  .carousel-caption {
    left: 8% !important;
    top: 65% !important;
  }

  .solutions .text-left h1 {
    font-size: 70px;
    line-height: 80px;
  }

  .solutions .text-right p {
    font-size: 16px;
    font-weight: 400;
    line-height: 29px;
    margin-top: 15px;
    padding-left: 60px;
  }

  .about-us .about-img h2 {
    right: -138px;
    font-size: 55px;
  }

  .about-us .about-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 100%;
    padding-left: 50px;
  }

  .six-products__title-content h1 span {
    font-size: 75px !important;
  }

  .Video-testimonial .video-box {
    width: 60%;
  }
  .Video-testimonial .swiper-button-prev {
    left: 200px !important;
  }
  .Video-testimonial .swiper-button-next {
    right: 200px !important;
  }

  .logo-arab-health {
    width: 250px;
  }

  .home-services__service-box h6 {
    font-size: 18px;
  }
}
@media (max-width: 1199px) and (min-width: 992px) {
  .about-us .about-img h2 {
    font-size: 40px;
    right: -96px;
  }

  .about-us .about-img:after {
    right: 4%;
  }
  .about-us .about-text p {
    font-size: 16px;
    line-height: 24px;
  }

  .solutions .solution-box h4 {
    font-size: 16px;
    line-height: 20px;
  }

  .solutions .solution-box p {
    font-size: 14px;
    line-height: 20px;
  }

  .solutions .text-right p {
    font-size: 14px;
    line-height: 22px;
    padding-left: 0;
  }

  .services h1 {
    font-size: 150px !important;
    margin-bottom: -37px !important;
  }

  .services .home-service-main .services-left li {
    font-size: 25px !important;
    line-height: 50px !important;
  }

  .services .services-img img {
    height: auto;
  }

  .product-section .buttons-wrapper a {
    font-size: 14px !important;
  }

  .six-products__title-content h1 {
    font-size: 60px !important;
  }
  .six-products__title-content h1 span {
    font-size: 65px !important;
  }
  .six-products__yellow-circle {
    width: 300px !important;
    height: 300px !important;
  }
  .six-products .mdvntg-img,
.six-products .endo-img,
.six-products .intravenous-img {
    height: 150px !important;
  }
  .six-products .ams-img {
    height: 200px !important;
  }
  .six-products .vs-img {
    height: 280px !important;
  }
  .six-products .dg-ste {
    height: 420px !important;
  }
  .six-products .iv-fluid-img {
    height: 270px !important;
  }
  .six-products .amblnc-img {
    height: 135px !important;
  }
  .six-products .oxgn-img {
    height: 280px !important;
  }
  .six-products .urn-img {
    height: 250px !important;
  }
  .six-products .in-pump-img {
    height: 310px !important;
  }
  .six-products .foot-img {
    height: 140px !important;
  }
  .six-products .hotler-img {
    height: 230px !important;
  }
  .six-products .oxgn-flow-img {
    height: 170px !important;
  }
  .six-products .patient-img {
    height: 200px !important;
  }
  .six-products .bmi-img {
    height: 250px !important;
  }

  .content-centered p {
    font-size: 20px !important;
  }

  .points-wrapper__points ul li {
    font-size: 16px !important;
  }

  .home-video__text p {
    font-size: 16px;
    line-height: 24px;
  }

  .Video-testimonial .video-box {
    width: 70%;
  }
  .Video-testimonial .swiper-button-prev {
    left: 80px !important;
  }
  .Video-testimonial .swiper-button-next {
    right: 80px !important;
  }

  .logo-arab-health {
    width: 200px;
  }

  .btn-outline-white {
    width: 180px !important;
    height: 50px !important;
    font-size: 22px !important;
    line-height: 50px !important;
  }

  .home-services__service-box h6 {
    font-size: 16px;
  }

  .healthcare__healthcare-content {
    padding: 25px 50px;
    left: 0;
  }
  .healthcare__healthcare-content h3 {
    font-size: 24px;
  }
  .healthcare__healthcare-content p {
    font-size: 16px;
  }
}
@media (max-width: 991px) and (min-width: 768px) {
  .sec-client h1 {
    font-size: 55px;
    line-height: 65px;
  }

  .about-us .about-text h3 {
    font-size: 18px;
    line-height: 25px;
    margin-bottom: 30px;
  }

  .about-us .about-text p {
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 10px !important;
  }

  .sec-client p {
    width: 45%;
  }

  .about-us .about-img h2 {
    font-size: 30px;
    right: -83px;
  }

  .solutions .text-left h1 {
    font-size: 67px;
    line-height: 90px;
    margin-bottom: 20px;
  }

  .about-us .about-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 100%;
    padding-left: 50px;
  }

  .solutions .text-right p {
    font-size: 16px;
    line-height: 28px;
    padding-left: 0;
    padding-right: 0;
  }

  .solutions .col-xxl-3 {
    width: 33%;
  }
  .solutions .solution-box {
    padding: 40px 20px;
  }

  .product-section .products .bg-img-wrapper {
    padding: 35px 0;
  }
  .product-section .products .bg-img-wrapper .number {
    height: 35px;
    width: 35px;
    font-size: 25px;
  }
  .product-section .products .bg-img-wrapper .detail-container {
    padding: 25px;
  }
  .product-section .products .bg-img-wrapper .detail-container .content {
    font-size: 14px;
    line-height: 22px;
    margin-bottom: 25px;
  }
  .product-section .products .bg-img-wrapper .detail-container .logo {
    width: 50%;
  }
  .product-section h1 {
    font-size: 50px;
    line-height: 55px;
  }

  .six-products__title-content h1 {
    font-size: 40px !important;
  }
  .six-products__title-content h1 span {
    font-size: 50px !important;
  }
  .six-products__title-content h3 {
    font-size: 35px !important;
  }
  .six-products__yellow-circle {
    width: 235px !important;
    height: 235px !important;
  }
  .six-products .mdvntg-img,
.six-products .endo-img,
.six-products .intravenous-img {
    height: 120px !important;
  }
  .six-products .ams-img {
    height: 150px !important;
  }
  .six-products .pct-img {
    height: 200px !important;
  }
  .six-products .pk-img {
    width: 45px !important;
    height: 250px !important;
  }
  .six-products .vs-img {
    height: 280px !important;
  }
  .six-products .dg-ste {
    height: 330px !important;
  }
  .six-products .iv-fluid-img {
    height: 210px !important;
  }
  .six-products .amblnc-img {
    height: 110px !important;
  }
  .six-products .oxgn-img {
    height: 200px !important;
  }
  .six-products .urn-img {
    height: 200px !important;
  }
  .six-products .in-pump-img {
    height: 245px !important;
  }
  .six-products .foot-img {
    height: 110px !important;
  }
  .six-products .hotler-img {
    height: 180px !important;
  }
  .six-products .oxgn-flow-img {
    height: 130px !important;
  }
  .six-products .patient-img {
    height: 160px !important;
  }
  .six-products .bmi-img {
    height: 180px !important;
  }

  .content-centered p {
    font-size: 18px !important;
  }

  .points-wrapper__box {
    display: block !important;
  }
  .points-wrapper__points {
    width: 100% !important;
  }

  .home-video__text p {
    font-size: 14px;
    line-height: 20px;
  }

  .Video-testimonial h1 {
    font-size: 55px;
    line-height: 65px;
  }
  .Video-testimonial .video-box {
    width: 60%;
  }
  .Video-testimonial .swiper-button-prev {
    left: 80px !important;
  }
  .Video-testimonial .swiper-button-next {
    right: 80px !important;
  }

  .logo-arab-health {
    width: 200px;
    top: 80%;
  }

  .ugi {
    top: 16%;
  }

  .home-services h1 {
    font-size: 50px;
    line-height: 70px;
  }
  .home-services__service-wrapper {
    row-gap: 40px;
  }
  .home-services__service-box {
    width: 30%;
  }
  .home-services__service-box h6 {
    font-size: 16px;
  }

  .choose h1 {
    font-size: 40px;
    margin-bottom: 50px;
  }
  .choose__content h4 {
    font-size: 17px;
    line-height: 22px;
    font-weight: 500;
  }
  .choose__content p {
    font-size: 14px;
    line-height: 22px;
  }

  .healthcare__healthcare-content {
    padding: 25px 50px;
    bottom: -55%;
    left: 0;
    right: 0;
    margin: 0 auto;
  }
  .healthcare__healthcare-content h3 {
    font-size: 22px;
  }
  .healthcare__healthcare-content p {
    font-size: 15px;
  }

  .Video-testimonial {
    margin-top: 400px;
  }
}
@media (max-width: 767px) {
  .home-banner__single-img img {
    height: auto !important;
  }

  .about-us .about-img:after {
    right: 11%;
  }

  .solutions .col-xxl-3 {
    width: 50%;
  }

  .sec-achievement-heading {
    padding: 50px 0;
  }

  .sec-client {
    padding-top: 0;
    padding-bottom: 50px;
  }

  .solutions .text-right p {
    font-size: 16px;
    line-height: 25px;
    padding-left: 0;
  }

  .about-us .about-text {
    margin-top: 60px;
    padding-left: 0;
  }

  .solutions {
    padding-bottom: 0 !important;
  }

  .about-us {
    padding-bottom: 50px;
    padding-top: 50px;
  }

  .sec-achievement-heading {
    position: relative;
  }
  .sec-achievement-heading::after {
    display: none;
  }
  .sec-achievement-heading::before {
    display: none;
  }

  .sec-client::after {
    display: none;
  }
  .sec-client::before {
    display: none;
  }

  .sec-client h1::after {
    display: none;
  }
  .sec-client h1::before {
    display: none;
  }

  .about-us .about-img h2 {
    font-size: 50px;
    right: -82px;
  }

  .sec-client h3 {
    font-size: 20px;
  }

  .sec-client p {
    width: 80%;
  }

  .sec-client h1 {
    font-size: 35px;
    line-height: 45px;
  }

  .sec-achievement-heading h1 {
    font-size: 40px;
    line-height: 45px;
  }

  .carousel-caption {
    top: 30%;
  }
  .carousel-caption h3 {
    font-size: 40px;
    line-height: 60px;
  }
  .carousel-caption p {
    font-size: 18px;
  }
  .carousel-caption p:last-child {
    max-width: 70px;
  }
  .carousel-caption a {
    font-size: 13px !important;
  }

  .product-section {
    padding-bottom: 0;
  }
  .product-section .products .bg-img-wrapper {
    padding: 30px 0;
  }
  .product-section .products .bg-img-wrapper .number {
    height: 30px;
    width: 30px;
    font-size: 22px;
    right: 8%;
  }
  .product-section .products .bg-img-wrapper .detail-container {
    width: 85%;
    padding: 20px;
  }
  .product-section .products .bg-img-wrapper .detail-container .logo {
    width: 60%;
  }
  .product-section .products .bg-img-wrapper .detail-container .content {
    margin-bottom: 25px;
    font-size: 13px;
    line-height: 20px;
  }
  .product-section::after {
    display: none;
  }
  .product-section::before {
    display: none;
  }
  .product-section h1 {
    font-size: 35px;
    line-height: 38px;
    margin-bottom: 50px;
  }

  .inner-solution {
    padding-bottom: 0;
  }
  .inner-solution::before {
    display: none;
  }
  .inner-solution h4 {
    font-size: 28px;
    line-height: 38px;
    margin-top: 25px;
  }
  .inner-solution p {
    font-size: 18px;
    line-height: 25px;
  }
  .inner-solution .inner-solution-content {
    margin-top: 50px;
  }

  .inner-about {
    padding-top: 50px;
  }
  .inner-about::before {
    display: none;
  }
  .inner-about::after {
    display: none;
  }
  .inner-about h1 {
    font-size: 65px;
    letter-spacing: 0;
    margin-bottom: -75px;
  }
  .inner-about h3 {
    font-size: 25px;
    line-height: 35px;
    margin-bottom: 50px;
    margin-bottom: 0;
  }

  .boost {
    padding: 100px 0;
  }
  .boost h3 {
    font-size: 25px;
    line-height: 45px;
  }
  .boost a {
    font-size: 14px;
  }

  .solutions .text-left h1 {
    font-size: 55px;
    line-height: 65px;
  }

  .services img {
    height: auto !important;
  }

  .six-products::before {
    display: none;
  }
  .six-products__title-content h1 span {
    font-size: 60px !important;
  }
  .six-products__title-content h3 {
    font-size: 30px !important;
    margin-bottom: 50px;
  }
  .six-products__yellow-circle {
    margin: 50px auto 0;
  }

  .content-centered p {
    font-size: 18px !important;
  }

  .points-wrapper__box {
    display: block !important;
    padding-left: 0 !important;
  }
  .points-wrapper__points {
    width: 100% !important;
  }
  .points-wrapper__points ul li {
    text-align: center;
  }
  .points-wrapper__points ul li p {
    padding: 0 !important;
  }
  .points-wrapper__points ul li i {
    float: none !important;
    display: block;
  }
  .points-wrapper::after {
    display: none;
  }

  .Video-testimonial h1 {
    font-size: 35px;
    line-height: 45px;
  }
  .Video-testimonial .video-box {
    width: 60%;
  }
  .Video-testimonial .swiper-button-prev {
    left: 80px !important;
  }
  .Video-testimonial .swiper-button-next {
    right: 80px !important;
  }

  .logo-arab-health {
    display: none;
  }

  .home-services h1 {
    font-size: 35px;
    line-height: 38px;
  }
  .home-services__service-wrapper {
    row-gap: 40px;
  }
  .home-services__service-box {
    width: 100%;
  }

  .choose h1 {
    font-size: 40px;
    line-height: 50px;
    margin-bottom: 50px;
  }
  .choose__content {
    text-align: center;
    margin-block-end: 25px;
  }
  .choose__picture {
    text-align: center;
  }

  .healthcare__healthcare-content {
    padding: 25px 50px;
    bottom: -55%;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 80%;
  }
  .healthcare__healthcare-content h3 {
    font-size: 22px;
  }
  .healthcare__healthcare-content p {
    font-size: 15px;
  }

  .Video-testimonial {
    margin-top: 350px;
  }
}
@media (max-width: 575px) {
  .inner-business h3 {
    font-size: 23px;
    line-height: 32px;
  }

  .about-us .about-img h2 {
    right: -89px;
    font-size: 40px;
  }

  .about-us .about-img:after {
    width: 50%;
    right: 6%;
  }

  .carousel-caption h3 {
    font-size: 25px;
    line-height: 25px;
  }

  .carousel-caption {
    top: 25%;
  }

  .carousel .mouse-btn {
    display: none !important;
  }

  .sec-client p {
    font-size: 15px;
    line-height: 22px;
    padding: 0 10px;
  }

  .sec-achievement .swiper-button-prev {
    height: 25px;
    width: 25px;
    margin-right: 25px;
  }
  .sec-achievement .swiper-button-prev::before {
    font-size: 12px;
  }
  .sec-achievement .swiper-button-prev::after {
    font-size: 12px;
  }
  .sec-achievement .swiper-button-next {
    height: 25px;
    width: 25px;
  }
  .sec-achievement .swiper-button-next::before {
    font-size: 12px;
  }
  .sec-achievement .swiper-button-next::after {
    font-size: 12px;
  }

  .product-section .products .bg-img-wrapper {
    padding: 25px 0;
  }
  .product-section .products .bg-img-wrapper .number {
    height: 25px;
    width: 25px;
    font-size: 18px;
  }
  .product-section .products .bg-img-wrapper .detail-container {
    padding-top: 30px;
  }
  .product-section .products .bg-img-wrapper .detail-container .content {
    margin-bottom: 25px;
    font-size: 13px;
  }

  .home-video {
    padding: 50px 0;
  }
  .home-video__text p {
    font-size: 15px;
    line-height: 25px;
  }

  .content-centered p {
    font-size: 16px !important;
  }

  .six-products__title-content p {
    font-size: 16px !important;
  }
}
@media (max-width: 516px) and (min-width: 375px) {
  .solutions .solution-box {
    min-height: auto;
    padding: 30px;
  }
  .solutions .solution-box h4 {
    font-size: 18px;
    line-height: 28px;
  }
  .solutions .solution-box p {
    font-size: 13px;
    line-height: 20px;
  }

  .six-products__title-content h1 {
    font-size: 35px !important;
  }
  .six-products__title-content h1 span {
    font-size: 36px !important;
  }
  .six-products__title-content h3 {
    font-size: 20px !important;
  }
  .six-products__yellow-circle {
    width: 250px !important;
    height: 250px !important;
  }
  .six-products .mdvntg-img,
.six-products .endo-img,
.six-products .intravenous-img {
    height: 125px !important;
  }
  .six-products .ams-img {
    height: 150px !important;
  }
  .six-products .pct-img {
    height: 200px !important;
  }
  .six-products .pk-img {
    width: 45px !important;
    height: 250px !important;
  }
  .six-products .vs-img {
    height: 280px !important;
  }
  .six-products .dg-ste {
    height: 330px !important;
  }
  .six-products .iv-fluid-img {
    height: 225px !important;
  }
  .six-products .amblnc-img {
    height: 115px !important;
  }
  .six-products .oxgn-img {
    height: 200px !important;
  }
  .six-products .urn-img {
    height: 200px !important;
  }
  .six-products .in-pump-img {
    height: 250px !important;
  }
  .six-products .foot-img {
    height: 120px !important;
  }
  .six-products .hotler-img {
    height: 180px !important;
  }
  .six-products .oxgn-flow-img {
    height: 140px !important;
  }
  .six-products .patient-img {
    height: 170px !important;
  }
  .six-products .bmi-img {
    height: 200px !important;
  }

  .Video-testimonial h1 {
    font-size: 25px;
    line-height: 40px;
  }
  .Video-testimonial .video-box {
    width: 75%;
  }
  .Video-testimonial .swiper-button-prev {
    left: 20px !important;
  }
  .Video-testimonial .swiper-button-prev:after {
    font-size: 25px !important;
  }
  .Video-testimonial .swiper-button-next {
    right: 20px !important;
  }
  .Video-testimonial .swiper-button-next:after {
    font-size: 25px !important;
  }
}
@media (max-width: 414px) {
  .ugi {
    top: 0;
    padding: 10px 20px;
  }
  .ugi p {
    font-size: 10px;
  }

  .solutions .col-xxl-3 {
    width: 100%;
  }
  .solutions .box-one {
    background-size: cover;
  }

  .home-services h1 {
    font-size: 25px;
    line-height: 35px;
  }
  .home-services p {
    font-size: 16px;
    line-height: 25px;
  }

  .healthcare__healthcare-content {
    bottom: -80%;
    padding: 20px;
  }
  .healthcare__healthcare-content h3 {
    font-size: 18px;
  }
  .healthcare__healthcare-content p {
    font-size: 13px;
  }

  .Video-testimonial {
    margin-top: 300px;
  }
}
@media (max-width: 375px) {
  .solutions .text-left h1 {
    font-size: 26px !important;
    line-height: 42px !important;
  }

  .inner-solution h4 {
    text-align: center;
    font-size: 25px;
    line-height: 35px;
  }

  .solutions .solution-box p {
    font-size: 16px;
    line-height: 25px;
  }

  .solutions .solution-box h4 {
    font-size: 20px;
    /*line-height: 35px;*/
  }

  .carousel-caption h3 {
    margin: 0;
  }
  .carousel-caption p {
    margin-bottom: 0;
  }
  .carousel-caption a {
    padding: 2px 10px;
  }

  .product-section .products .bg-img-wrapper {
    padding: 35px 0;
  }
  .product-section .products .bg-img-wrapper .number {
    height: 35px;
    width: 40px;
    font-size: 22px;
  }
  .product-section .products .bg-img-wrapper .detail-container {
    padding-bottom: 20px !important;
  }

  .about-us .about-img h2 {
    font-size: 30px;
    right: -64px;
  }
  .about-us .about-text h3 {
    font-size: 19px !important;
  }

  .home-add-section .add-content {
    width: 65% !important;
  }

  .boost h3 {
    font-size: 18px;
    line-height: 30px;
  }

  .Video-testimonial .video-box {
    width: 80%;
  }
  .Video-testimonial .swiper-button-prev {
    left: 5px !important;
  }
  .Video-testimonial .swiper-button-prev:after {
    font-size: 25px !important;
  }
  .Video-testimonial .swiper-button-next {
    right: 5px !important;
  }
  .Video-testimonial .swiper-button-next:after {
    font-size: 25px !important;
  }

  .six-products .six-products__title-content h1 {
    font-size: 25px !important;
  }
  .six-products .six-products__title-content h1 span {
    font-size: 30px !important;
  }
  .six-products .six-products__title-content h3 {
    font-size: 20px !important;
  }
  .six-products__yellow-circle {
    width: 250px !important;
    height: 250px !important;
  }
  .six-products .mdvntg-img,
.six-products .endo-img,
.six-products .intravenous-img {
    height: 120px !important;
  }
  .six-products .ams-img {
    height: 150px !important;
  }
  .six-products .pct-img {
    height: 200px !important;
  }
  .six-products .pk-img {
    width: 45px !important;
    height: 250px !important;
  }
  .six-products .vs-img {
    height: 280px !important;
  }
  .six-products .dg-ste {
    height: 330px !important;
  }
  .six-products .iv-fluid-img {
    height: 225px !important;
  }
  .six-products .amblnc-img {
    height: 115px !important;
  }
  .six-products .oxgn-img {
    height: 200px !important;
  }
  .six-products .urn-img {
    height: 200px !important;
  }
  .six-products .in-pump-img {
    height: 250px !important;
  }
  .six-products .foot-img {
    height: 120px !important;
  }
  .six-products .hotler-img {
    height: 180px !important;
  }
  .six-products .oxgn-flow-img {
    height: 140px !important;
  }
  .six-products .patient-img {
    height: 170px !important;
  }
  .six-products .bmi-img {
    height: 200px !important;
  }

  .healthcare__healthcare-content {
    bottom: -100%;
  }
}
@media (max-width: 320px) {
  .Video-testimonial h1 {
    font-size: 25px;
    line-height: 40px;
  }
  .Video-testimonial .video-box {
    width: 80%;
  }
  .Video-testimonial .swiper-button-prev {
    left: 0 !important;
  }
  .Video-testimonial .swiper-button-next {
    right: 0 !important;
  }

  .home-services h1 {
    font-size: 22px;
    line-height: 32px;
  }

  .healthcare__healthcare-content {
    bottom: -130%;
  }

  .Video-testimonial {
    margin-top: 350px;
  }
}