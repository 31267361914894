.header.inner {
  background-color: transparent;
}
.header.inner .navbar .nav-link {
  color: #07102a;
}

.header {
  position: absolute;
  z-index: 9;
  width: 100%;
  background: linear-gradient(90deg, #fff 9.56%, rgba(255, 255, 255, 0.28) 48.59%, rgba(255, 255, 255, 0) 97.72%);
  border-bottom: 1px solid #fff;
}
.header .navbar {
  padding: 5px 100px;
}
.header .navbar .nav-link.active {
  color: #ffb41f;
  font-weight: 500;
}
.header .navbar .nav-link {
  font-size: 18px;
  font-weight: 400;
  color: #07102a;
  padding-left: 1rem;
  padding-right: 1rem;
}
.header .navbar .nav-link:hover {
  color: #ffb41f;
}
.header .navbar .nav-link:active {
  color: #ffb41f;
}
.header .navbar .nav-link.partner-link {
  background-color: #07102a;
  color: #fff;
  border-radius: 10px;
}
.header .navbar .dropdown-menu li a {
  padding: 10px;
  border-bottom: 1px solid #e7e7e7;
  color: #07102a;
}
.header .navbar .dropdown-menu li a:hover {
  background-color: #07102a;
  color: #fff;
}

@media (max-width: 1199px) and (min-width: 992px) {
  .navbar .nav-link {
    font-size: 14px !important;
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
  }
}
@media (max-width: 991px) {
  .navbar {
    /*.nav-link {
      color: #fff !important;
    }*/
  }
  .navbar .navbar-collapse {
    background: rgba(0, 0, 0, 0.6);
    border-radius: 10px;
  }
}
@media (max-width: 767px) {
  .navbar .nav-link {
    font-size: 14px !important;
    padding-top: 3px;
    padding-bottom: 3px;
  }

  .header.inner .navbar .nav-link,
.header .navbar .nav-link {
    color: #fff;
  }

  .header.inner {
    padding: 0;
  }
}
@media (max-width: 575px) {
  .header {
    position: relative;
    background: linear-gradient(90deg, #a9a9a9 9.56%, rgba(153, 151, 151, 0.28) 48.59%, rgba(255, 255, 255, 0) 97.72%);
  }
  .header .navbar {
    padding: 5px 20px;
  }

  .header.inner {
    background: none;
  }

  .carousel-caption {
    top: 25%;
  }
  .carousel-caption h3 {
    font-size: 25px;
    line-height: 25px;
  }
  .carousel-caption p {
    font-size: 13px;
    margin-bottom: 20px;
  }
  .carousel-caption p:last-child {
    max-width: 40px;
    margin-left: 10px;
  }
  .carousel-caption a {
    font-size: 10px !important;
    padding: 6px 15px;
  }
}