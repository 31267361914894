@import 'https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap';
/*font-family: 'Poppins', sans-serif;*/
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  line-height: 1.1;
  font-family: "Poppins", sans-serif;
}

p {
  font-family: "Poppins", sans-serif;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}

html {
  overflow-x: hidden;
  font-family: "Poppins", sans-serif;
}

body {
  overflow-x: hidden;
  font-family: "Poppins", sans-serif;
}

/*****Breadcrumb Section Starts*****/
.page-breadcrumb {
  padding: 0 115px;
}
.page-breadcrumb .breadcrumb {
  justify-content: flex-end;
  margin-top: 1rem;
}
.page-breadcrumb .breadcrumb a {
  color: #000;
  text-decoration: none;
  font-size: 16px;
  font-weight: 300;
}
.page-breadcrumb .breadcrumb i {
  padding: 0 10px;
}

.page-breadcrumb {
  padding: 0 115px;
}
.page-breadcrumb .breadcrumb {
  justify-content: flex-end;
  margin-top: 1rem;
}
.page-breadcrumb .breadcrumb a {
  color: #000;
  text-decoration: none;
  font-size: 16px;
  font-weight: 300;
}
.page-breadcrumb .breadcrumb i {
  padding: 0 10px;
}

/*****Breadcrumb Section Ends*****/
/*****Home Carousel Style Starts*****/
.carousel-control-next {
  opacity: 0;
}
.carousel-control-next:focus {
  opacity: 0;
}
.carousel-control-next:hover {
  opacity: 0;
}

.carousel-control-prev {
  opacity: 0;
}
.carousel-control-prev:focus {
  opacity: 0;
}
.carousel-control-prev:hover {
  opacity: 0;
}

.carousel-caption {
  text-align: left;
  left: 5%;
  top: 55%;
}
.carousel-caption h3 {
  font-size: 120px;
  line-height: 120px;
  font-weight: 700;
  color: #07102a;
}
.carousel-caption p {
  font-size: 50px;
  font-weight: 400;
  color: #fff;
  margin-bottom: 50px;
}
.carousel-caption p:last-child {
  max-width: 300px;
  width: 100%;
  background: #ffb41f;
  height: 2px;
  margin-left: 25px;
}
.carousel-caption a {
  color: #fff;
  border: 2px solid #fff;
  padding: 10px 25px;
  text-decoration: none;
  float: right;
  font-size: 20px;
  text-transform: uppercase;
  font-weight: 600;
  float: none !important;
}
.carousel-caption .banner-text {
  display: flex;
  align-items: center;
}

.carousel-indicators {
  display: none;
}

/*****Home Carousel Style Ends*****/
.home-banner {
  overflow: hidden;
  position: relative;
}
.home-banner__single-img img {
  width: 100%;
  height: 950px;
  object-fit: cover;
  animation: zoom-in-zoom-out 55s ease infinite;
}
.home-banner__single-img .mouse-btn {
  display: block !important;
}
.home-banner__single-img .mouse-btn a {
  background-image: url(../images/icons/mouse_icon.webp);
  background-repeat: no-repeat;
  bottom: 8%;
  content: "";
  display: block;
  height: 60px;
  left: 50%;
  margin-left: -20px;
  position: absolute;
  width: 40px;
  z-index: 9;
  -webkit-animation-name: floating;
  animation-name: floating;
  -webkit-animation-duration: 3s;
  animation-duration: 3s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
}
@keyframes zoom-in-zoom-out {
  0% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(1.1, 1.1);
  }
  100% {
    transform: scale(1, 1);
  }
}
.home-banner__health-exibition {
  max-width: 450px;
  width: 100%;
  height: auto;
  position: absolute;
  inset-block-start: 30%;
  inset-inline-end: 5%;
  background-color: rgba(255, 255, 255, 0.5);
  box-sizing: border-box;
  padding: 20px;
  box-shadow: 0 0 5px #2b3758;
  border-radius: 3px;
}
.home-banner__exibition-content h1 {
  font-size: 27px;
  line-height: 27px;
  font-weight: 600;
  margin-block-end: 15px;
}
.home-banner__exibition-content h3 {
  font-size: 20px;
  line-height: 26px;
  font-weight: 500;
}
.home-banner__exibition-content p {
  font-size: 16px;
  line-height: 25px;
  font-weight: 500;
}

.spinner-grow {
  width: 3rem;
  height: 3rem;
  visibility: hidden;
}

.spinner-grow.spinnershow {
  width: 3rem;
  height: 3rem;
  visibility: visible;
}

.term::after {
  top: 1% !important;
}

@media (max-width: 1199px) and (min-width: 992px) {
  .know-more h1 {
    font-size: 28px;
  }

  .carousel-caption {
    top: 65%;
    left: 10%;
  }
  .carousel-caption h3 {
    font-size: 90px;
  }
  .carousel-caption p {
    font-size: 35px;
  }
  .carousel-caption p:last-child {
    max-width: 250px;
  }
}
@media (max-width: 991px) and (min-width: 768px) {
  .home-banner__single-img img {
    height: auto;
  }

  .carousel-caption {
    top: 55%;
  }
  .carousel-caption h3 {
    font-size: 60px;
    line-height: 90px;
  }
  .carousel-caption p {
    font-size: 22px;
  }
  .carousel-caption p:last-child {
    max-width: 170px;
  }

  .buttons-wrapper a {
    display: block;
    text-align: center;
    margin: 10px 0;
  }

  .top-banner h1 {
    font-size: 100px;
    margin-top: -53px;
  }
  .top-banner .solution-pages-main-title {
    top: 40%;
  }
  .top-banner .solution-pages-main-title h2 {
    font-size: 60px !important;
    line-height: 60px;
  }

  .form-wrapper .form-check-input {
    margin-bottom: 61px;
  }

  .sec-achievement .img-box img {
    height: auto;
  }

  .home-banner__health-exibition {
    inset-block-start: 25%;
    inset-inline-end: 2%;
  }
}
@media (max-width: 516px) and (min-width: 375px) {
  .know-more a {
    width: 60% !important;
  }

  .carousel-caption {
    top: 30%;
  }
  .carousel-caption p {
    font-size: 18px;
  }
  .carousel-caption a {
    font-size: 13px !important;
  }

  .buttons-wrapper a {
    display: block;
    text-align: center;
    margin: 10px 0;
  }

  .form-wrapper .form-check-input {
    margin-bottom: 55px;
  }
}
@media (max-width: 767px) {
  .know-more {
    margin-bottom: 50px;
  }
  .know-more h1 {
    text-align: center;
    margin-bottom: 20px;
  }
  .know-more a {
    float: none;
    margin: 20px auto 0;
    display: block;
    width: 40%;
    text-align: center;
  }

  .left-img-holder.float-end {
    float: none !important;
  }

  .form-wrapper {
    padding-top: 0;
    padding-bottom: 50px;
  }
  .form-wrapper::before {
    display: none;
  }
  .form-wrapper::after {
    display: none;
  }
  .form-wrapper h2 {
    font-size: 40px;
    line-height: 45px !important;
    line-height: 30px;
  }
  .form-wrapper .form-check {
    margin-bottom: 30px;
  }
  .form-wrapper a {
    float: left;
  }
  .form-wrapper label {
    font-size: 14px;
  }
  .form-wrapper__contact-box {
    margin-top: 20px;
  }

  .hide-in-mobile {
    display: none;
  }

  .carousel-caption {
    top: 50%;
  }
  .carousel-caption h3 {
    font-size: 40px;
    line-height: 60px;
  }
  .carousel-caption p {
    font-size: 18px;
  }
  .carousel-caption p:last-child {
    max-width: 70px;
  }
  .carousel-caption a {
    font-size: 13px !important;
  }

  .home-banner__health-exibition {
    max-width: 100%;
    position: relative;
    inset-inline: 0;
    margin: 70px auto 50px;
    background: linear-gradient(146.08deg, #2b3758 -0.65%, #2a9d8f 102.53%);
    padding: 25px;
  }
  .home-banner__exibition-content {
    max-width: 450px;
    width: 100%;
    margin: 0 auto;
    background-color: rgba(255, 255, 255, 0.2);
    padding: 20px;
  }
  .home-banner__exibition-content h1,
.home-banner__exibition-content h3,
.home-banner__exibition-content p {
    color: #fff;
  }
}
@media (max-width: 575px) {
  .container {
    padding: 0 25px;
  }

  .carousel-caption h3 {
    font-size: 25px;
    line-height: 25px;
  }

  .carousel-caption p {
    font-size: 13px;
    margin-bottom: 20px;
  }

  .carousel-caption p:last-child {
    max-width: 40px;
    margin-left: 10px;
  }

  .form-wrapper h2 {
    font-size: 25px;
    line-height: 30px !important;
    margin-bottom: 50px;
  }
}
@media (max-width: 375px) {
  .know-more a {
    width: 70%;
  }

  .navbar-brand {
    max-width: 50% !important;
  }

  .buttons-wrapper a {
    display: block;
    text-align: center;
    margin: 10px 0;
    font-size: 14px;
  }

  .carousel-caption h3 {
    margin: 0;
  }
  .carousel-caption p {
    margin-bottom: 0;
  }
  .carousel-caption a {
    padding: 2px 10px;
  }

  .form-wrapper .form-check-input {
    margin-bottom: 55px;
  }
  .form-wrapper .form-check-label {
    font-size: 13px;
  }
  .form-wrapper__contact-box p {
    font-size: 14px;
  }

  .top-banner h1 {
    font-size: 35px;
    margin-top: -77px;
  }
  .top-banner .solution-pages-main-title h2 {
    font-size: 30px;
  }

  .page-breadcrumb {
    padding: 0 30px;
  }
}
.btn-outline-white {
  text-decoration: none;
  color: #fff;
  width: 267px;
  height: 67px;
  border: 1px solid #fff;
  display: block;
  font-size: 30px;
  font-weight: 300;
  font-family: Poppins, sans-serif;
  text-align: center;
  line-height: 65px;
  margin-top: 25px;
}
.btn-outline-white:hover {
  color: #fff;
}
.btn-outline-white--font-border {
  border-color: #006b60;
  color: #006b60;
  margin: 50px auto 0;
  font-size: 18px;
  font-weight: 500;
  width: 150px;
  line-height: 50px;
  height: 50px;
}
.btn-outline-white--font-border:hover {
  background-color: #ffb41f;
  border-color: #ffb41f;
  color: #000;
}

.form-wrapper {
  padding: 100px 0;
  position: relative;
}
.form-wrapper::before {
  position: absolute;
  content: "";
  width: 100px;
  height: 225px;
  border-radius: 12px;
  background: rgba(255, 180, 31, 0.1);
  right: 0;
  transform: rotate(90deg);
  top: 0;
  transform: rotate(90deg);
  top: 0;
  right: 95%;
}
.form-wrapper::after {
  position: absolute;
  content: "";
  width: 131px;
  height: 134px;
  background: url(../images/shapes/Ellipse1.webp);
  background-position: center;
  top: 25%;
  right: -2%;
  background-position: center;
  right: -2%;
  transform: rotate(130deg);
  top: 8%;
}
.form-wrapper h2 {
  font-size: 70px;
  font-weight: 600;
  text-transform: uppercase;
  line-height: 70px;
  margin-bottom: 100px;
  font-size: 40px;
  line-height: 40px;
  margin-block-end: 30px;
}
.form-wrapper h2 span {
  color: #ffb41f;
}
.form-wrapper__contact-box {
  background: #f2f2f2;
  text-align: center;
  padding: 45px 30px;
  border-radius: 10px;
  min-height: 295px;
}
.form-wrapper__contact-box h5 {
  margin-block-end: 20px;
  font-weight: 600;
}
.form-wrapper__contact-box a {
  color: #000;
  text-decoration: none;
}
.form-wrapper__contact-box:hover i {
  background-color: #ffb41f;
  color: #000;
}
.form-wrapper__icon-box i {
  font-size: 40px;
  color: #ffb41f;
  background-color: #000;
  display: inline-block;
  text-align: center;
  border-radius: 100%;
  width: 80px;
  height: 80px;
  line-height: 80px;
  transition: all 0.3s ease-in-out;
  margin-block-end: 20px;
}
.form-wrapper input[type=text],
.form-wrapper input[type=email],
.form-wrapper input[type=file],
.form-wrapper input[type=password],
.form-wrapper select {
  border: 2px solid #388697;
  height: 50px;
  border-radius: 0;
}
.form-wrapper input[type=file] {
  padding: 0.7rem 0.75rem;
}
.form-wrapper select {
  font-size: 1rem;
  color: #757574;
}
.form-wrapper textarea {
  border: 2px solid #388697;
  height: 245px;
  border-radius: 0;
  margin-bottom: 20px;
}
.form-wrapper .btn-success {
  background: linear-gradient(146.08deg, #2b3758 -0.65%, #2a9d8f 102.53%);
  border-radius: 0;
  padding: 12px 75px;
  color: #fff !important;
  text-decoration: none;
  float: right;
  text-transform: uppercase;
  font-size: 18px;
  font-weight: 600;
}
.form-wrapper .btn-success:hover {
  box-shadow: 0 0 10px #8d8d8d;
  cursor: pointer;
}
.form-wrapper .form-check-input {
  width: 3em;
  height: 3em;
  border: 2px solid #006b60;
  border-radius: 0;
  margin-right: 25px;
}
.form-wrapper .form-check-input:checked {
  background-color: #006b60;
  border-color: #006b60;
}
.form-wrapper::-moz-placeholder {
  color: #388697;
}
.form-wrapper:-ms-input-placeholder {
  color: #388697;
}
.form-wrapper::placeholder {
  color: #388697;
}
.form-wrapper .form-check-label {
  font-size: 15px;
  font-weight: 600;
}
.form-wrapper .success-message {
  color: #5dc267;
  font-size: 20px;
  border: 1px solid #5dc267;
  border-radius: 5px;
  padding: 5px 10px;
}
.form-wrapper .error-message {
  color: #ff0000;
  font-size: 20px;
  border: 1px solid #ff0000;
  border-radius: 5px;
  padding: 5px 10px;
}
.form-wrapper .create-account a {
  color: #388697 !important;
  text-decoration: none;
}
.form-wrapper .toast {
  position: fixed;
  inset-block-start: 10%;
  inset-inline-end: 0%;
  z-index: 999;
}

.join-our-team {
  padding: 150px 0;
  position: relative;
}
.join-our-team::before {
  position: absolute;
  content: "";
  width: 100px;
  height: 225px;
  border-radius: 12px;
  background: rgba(255, 180, 31, 0.1);
  right: 0;
  transform: rotate(90deg);
  top: 0;
  transform: rotate(90deg);
  right: 94%;
  top: 15%;
}
.join-our-team::after {
  position: absolute;
  content: "";
  width: 136px;
  height: 150px;
  background: url(../images/shapes/Ellipse.webp);
  background-position: center;
  left: 5%;
  top: 0;
  background-position: center;
  left: 80%;
  top: 70%;
  transform: rotate(180deg);
}
.join-our-team h1 {
  font-size: 180px;
  font-weight: 700;
  text-transform: uppercase;
  color: #e7e7e7;
  line-height: 180px;
  margin-top: -25px;
  text-align: right;
  position: relative;
  z-index: -2;
}
.join-our-team .career-img {
  position: relative;
}
.join-our-team .career-img h2 {
  transform: rotate(270deg);
  position: absolute;
  right: -415px;
  bottom: 42%;
  color: #fff;
  font-size: 90px;
  font-weight: 700;
}
.join-our-team .career-img::after {
  position: absolute;
  content: "";
  background: linear-gradient(146.08deg, #2b3758 -0.65%, #2a9d8f 102.53%);
  z-index: -1;
  width: 100%;
  height: 110%;
  left: 15%;
  top: -5%;
}

.career-form::before {
  position: absolute;
  content: "";
  width: 131px;
  height: 134px;
  background: url(../images/shapes/Ellipse1.webp);
  background-position: center;
  top: 25%;
  right: -2%;
  background-position: center;
  top: 0;
  right: 85%;
  transform: rotate(145deg);
}
.career-form::after {
  display: none;
}

.current-opening {
  margin-bottom: 50px;
}
.current-opening__opening-wrapper {
  border: 1px solid #388697;
  margin-bottom: 50px;
}
.current-opening__opening-wrapper ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.current-opening__header-area {
  padding: 15px;
  border-bottom: 1px solid #388697;
}
.current-opening__header-area h3 {
  font-size: 25px;
  line-height: 25px;
  font-weight: 500;
  margin: 0;
  color: #388697;
}
.current-opening__job-description {
  padding: 15px 15px 50px 15px;
}
.current-opening__job-description li {
  font-size: 18px;
  line-height: 18px;
  font-weight: 400;
  margin-bottom: 15px;
}
.current-opening__footer-area {
  padding: 10px 15px;
  border-top: 1px solid #388697;
}
.current-opening__footer-area ul {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.current-opening__footer-area p {
  margin: 0;
  color: #388697;
  font-size: 16px;
  font-weight: 400;
}
.current-opening__footer-area a {
  font-size: 18px;
  line-height: 18px;
  text-decoration: none;
  color: #388697;
  font-weight: 600;
}

.job-detail {
  margin: 50px 0;
}
.job-detail h3 {
  font-size: 25px;
  line-height: 25px;
  font-weight: 700;
  border-bottom: 1px solid #388697;
  padding-bottom: 20px;
  margin-bottom: 20px;
}
.job-detail h4 {
  font-size: 20px;
  line-height: 20px;
  font-weight: 600;
  margin: 30px 0;
}
.job-detail p,
.job-detail li {
  font-size: 18px;
  line-height: 18px;
  font-weight: 400;
}
.job-detail li {
  margin-bottom: 15px;
}

.old-text {
  margin: 50px 0;
}
.old-text h3 {
  font-size: 30px;
  font-weight: 600;
  line-height: 30px;
  margin-bottom: 20px;
}
.old-text h3 span {
  color: #ffb41f;
  display: inline-block;
  font-weight: 800;
}
.old-text p {
  font-size: 20px;
  margin-bottom: 50px;
}
.old-text ul {
  list-style-type: disc;
  padding-left: 50px;
}
.old-text ul li {
  font-size: 20px;
  margin-bottom: 10px;
}

@media (max-width: 1399px) and (min-width: 1200px) {
  .join-our-team .career-img h2 {
    right: -333px;
    font-size: 70px;
  }
}
@media (max-width: 1199px) and (min-width: 992px) {
  .join-our-team h1 {
    font-size: 110px;
    bottom: -40px;
  }

  .join-our-team .career-img h2 {
    font-size: 60px;
    right: -283px;
  }
}
@media (max-width: 991px) and (min-width: 768px) {
  .join-our-team h1 {
    font-size: 110px;
    bottom: -40px;
  }

  .join-our-team .career-img h2 {
    font-size: 40px;
    right: -194px;
  }

  .top-banner h1 {
    font-size: 50px;
    margin-top: -70px;
  }
  .top-banner .solution-pages-main-title h2 {
    font-size: 30px;
    line-height: 40px;
  }

  .office-address-buttons {
    padding: 50px 0;
  }
  .office-address-buttons::after {
    display: none;
  }
  .office-address-buttons::before {
    display: none;
  }

  .career-form h3 {
    margin-bottom: 50px;
  }
  .career-form textarea {
    margin-top: 50px;
  }

  .form-wrapper__contact-box {
    margin-top: 20px;
  }
}
@media (max-width: 767px) {
  .join-our-team h1 {
    font-size: 100px;
    bottom: -50px;
  }

  .join-our-team:after {
    display: none;
  }

  .join-our-team .career-img:after {
    width: 90%;
  }

  .join-our-team .career-img h2 {
    font-size: 45px;
    right: -178px;
  }

  .join-our-team .career-img h2 {
    font-size: 45px;
    right: -178px;
  }

  .join-our-team .career-img img {
    max-width: 90%;
  }

  .join-our-team .career-img:after {
    width: 90%;
  }

  .career-form textarea {
    margin-top: 50px;
  }
}
@media (max-width: 575px) {
  .join-our-team .career-img h2 {
    right: -108px;
    font-size: 32px;
  }

  .join-our-team .career-img:after {
    width: 85% !important;
  }

  .join-our-team h1 {
    font-size: 50px !important;
    bottom: -70px !important;
  }

  .join-our-team {
    padding-top: 0;
    padding-bottom: 75px;
  }
  .join-our-team::before {
    display: none;
  }
  .join-our-team::after {
    display: none;
  }
  .join-our-team h1 {
    font-size: 100px;
    bottom: -50px;
  }
  .join-our-team .career-img::after {
    width: 90%;
  }
  .join-our-team .career-img img {
    max-width: 90%;
  }

  .current-opening__footer-area .btn-success {
    padding: 8px 15px;
    font-size: 15px;
  }

  .old-text h3 {
    font-size: 20px;
    line-height: 30px;
  }
  .old-text p,
.old-text li {
    font-size: 16px !important;
    line-height: 25px;
  }
}
@media (max-width: 375px) {
  .join-our-team .career-img h2 {
    right: -85px;
    font-size: 25px;
  }

  .join-our-team h1 {
    font-size: 50px;
    bottom: -70px;
  }
  .join-our-team .career-img h2 {
    right: -85px;
    font-size: 25px;
  }

  .join-our-team {
    padding-top: 0;
    padding-bottom: 75px;
  }
  .join-our-team::before {
    display: none;
  }
  .join-our-team::after {
    display: none;
  }
  .join-our-team h1 {
    font-size: 100px;
    bottom: -50px;
  }
  .join-our-team .career-img::after {
    width: 90%;
  }
  .join-our-team .career-img img {
    max-width: 90%;
  }
  .join-our-team .career-img h2 {
    right: -85px;
    font-size: 25px;
  }
}