@import 'https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap';
/*font-family: 'Poppins', sans-serif;*/
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  line-height: 1.1;
  font-family: "Poppins", sans-serif;
}

p {
  font-family: "Poppins", sans-serif;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}

html {
  overflow-x: hidden;
  font-family: "Poppins", sans-serif;
}

body {
  overflow-x: hidden;
  font-family: "Poppins", sans-serif;
}

/*****Breadcrumb Section Starts*****/
.page-breadcrumb {
  padding: 0 115px;
}
.page-breadcrumb .breadcrumb {
  justify-content: flex-end;
  margin-top: 1rem;
}
.page-breadcrumb .breadcrumb a {
  color: #000;
  text-decoration: none;
  font-size: 16px;
  font-weight: 300;
}
.page-breadcrumb .breadcrumb i {
  padding: 0 10px;
}

.page-breadcrumb {
  padding: 0 115px;
}
.page-breadcrumb .breadcrumb {
  justify-content: flex-end;
  margin-top: 1rem;
}
.page-breadcrumb .breadcrumb a {
  color: #000;
  text-decoration: none;
  font-size: 16px;
  font-weight: 300;
}
.page-breadcrumb .breadcrumb i {
  padding: 0 10px;
}

/*****Breadcrumb Section Ends*****/
/*****Home Carousel Style Starts*****/
.carousel-control-next {
  opacity: 0;
}
.carousel-control-next:focus {
  opacity: 0;
}
.carousel-control-next:hover {
  opacity: 0;
}

.carousel-control-prev {
  opacity: 0;
}
.carousel-control-prev:focus {
  opacity: 0;
}
.carousel-control-prev:hover {
  opacity: 0;
}

.carousel-caption {
  text-align: left;
  left: 5%;
  top: 55%;
}
.carousel-caption h3 {
  font-size: 120px;
  line-height: 120px;
  font-weight: 700;
  color: #07102a;
}
.carousel-caption p {
  font-size: 50px;
  font-weight: 400;
  color: #fff;
  margin-bottom: 50px;
}
.carousel-caption p:last-child {
  max-width: 300px;
  width: 100%;
  background: #ffb41f;
  height: 2px;
  margin-left: 25px;
}
.carousel-caption a {
  color: #fff;
  border: 2px solid #fff;
  padding: 10px 25px;
  text-decoration: none;
  float: right;
  font-size: 20px;
  text-transform: uppercase;
  font-weight: 600;
  float: none !important;
}
.carousel-caption .banner-text {
  display: flex;
  align-items: center;
}

.carousel-indicators {
  display: none;
}

/*****Home Carousel Style Ends*****/
.home-banner {
  overflow: hidden;
  position: relative;
}
.home-banner__single-img img {
  width: 100%;
  height: 950px;
  object-fit: cover;
  animation: zoom-in-zoom-out 55s ease infinite;
}
.home-banner__single-img .mouse-btn {
  display: block !important;
}
.home-banner__single-img .mouse-btn a {
  background-image: url(../images/icons/mouse_icon.webp);
  background-repeat: no-repeat;
  bottom: 8%;
  content: "";
  display: block;
  height: 60px;
  left: 50%;
  margin-left: -20px;
  position: absolute;
  width: 40px;
  z-index: 9;
  -webkit-animation-name: floating;
  animation-name: floating;
  -webkit-animation-duration: 3s;
  animation-duration: 3s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
}
@keyframes zoom-in-zoom-out {
  0% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(1.1, 1.1);
  }
  100% {
    transform: scale(1, 1);
  }
}
.home-banner__health-exibition {
  max-width: 450px;
  width: 100%;
  height: auto;
  position: absolute;
  inset-block-start: 30%;
  inset-inline-end: 5%;
  background-color: rgba(255, 255, 255, 0.5);
  box-sizing: border-box;
  padding: 20px;
  box-shadow: 0 0 5px #2b3758;
  border-radius: 3px;
}
.home-banner__exibition-content h1 {
  font-size: 27px;
  line-height: 27px;
  font-weight: 600;
  margin-block-end: 15px;
}
.home-banner__exibition-content h3 {
  font-size: 20px;
  line-height: 26px;
  font-weight: 500;
}
.home-banner__exibition-content p {
  font-size: 16px;
  line-height: 25px;
  font-weight: 500;
}

.spinner-grow {
  width: 3rem;
  height: 3rem;
  visibility: hidden;
}

.spinner-grow.spinnershow {
  width: 3rem;
  height: 3rem;
  visibility: visible;
}

.term::after {
  top: 1% !important;
}

@media (max-width: 1199px) and (min-width: 992px) {
  .know-more h1 {
    font-size: 28px;
  }

  .carousel-caption {
    top: 65%;
    left: 10%;
  }
  .carousel-caption h3 {
    font-size: 90px;
  }
  .carousel-caption p {
    font-size: 35px;
  }
  .carousel-caption p:last-child {
    max-width: 250px;
  }
}
@media (max-width: 991px) and (min-width: 768px) {
  .home-banner__single-img img {
    height: auto;
  }

  .carousel-caption {
    top: 55%;
  }
  .carousel-caption h3 {
    font-size: 60px;
    line-height: 90px;
  }
  .carousel-caption p {
    font-size: 22px;
  }
  .carousel-caption p:last-child {
    max-width: 170px;
  }

  .buttons-wrapper a {
    display: block;
    text-align: center;
    margin: 10px 0;
  }

  .top-banner h1 {
    font-size: 100px;
    margin-top: -53px;
  }
  .top-banner .solution-pages-main-title {
    top: 40%;
  }
  .top-banner .solution-pages-main-title h2 {
    font-size: 60px !important;
    line-height: 60px;
  }

  .form-wrapper .form-check-input {
    margin-bottom: 61px;
  }

  .sec-achievement .img-box img {
    height: auto;
  }

  .home-banner__health-exibition {
    inset-block-start: 25%;
    inset-inline-end: 2%;
  }
}
@media (max-width: 516px) and (min-width: 375px) {
  .know-more a {
    width: 60% !important;
  }

  .carousel-caption {
    top: 30%;
  }
  .carousel-caption p {
    font-size: 18px;
  }
  .carousel-caption a {
    font-size: 13px !important;
  }

  .buttons-wrapper a {
    display: block;
    text-align: center;
    margin: 10px 0;
  }

  .form-wrapper .form-check-input {
    margin-bottom: 55px;
  }
}
@media (max-width: 767px) {
  .know-more {
    margin-bottom: 50px;
  }
  .know-more h1 {
    text-align: center;
    margin-bottom: 20px;
  }
  .know-more a {
    float: none;
    margin: 20px auto 0;
    display: block;
    width: 40%;
    text-align: center;
  }

  .left-img-holder.float-end {
    float: none !important;
  }

  .form-wrapper {
    padding-top: 0;
    padding-bottom: 50px;
  }
  .form-wrapper::before {
    display: none;
  }
  .form-wrapper::after {
    display: none;
  }
  .form-wrapper h2 {
    font-size: 40px;
    line-height: 45px !important;
    line-height: 30px;
  }
  .form-wrapper .form-check {
    margin-bottom: 30px;
  }
  .form-wrapper a {
    float: left;
  }
  .form-wrapper label {
    font-size: 14px;
  }
  .form-wrapper__contact-box {
    margin-top: 20px;
  }

  .hide-in-mobile {
    display: none;
  }

  .carousel-caption {
    top: 50%;
  }
  .carousel-caption h3 {
    font-size: 40px;
    line-height: 60px;
  }
  .carousel-caption p {
    font-size: 18px;
  }
  .carousel-caption p:last-child {
    max-width: 70px;
  }
  .carousel-caption a {
    font-size: 13px !important;
  }

  .home-banner__health-exibition {
    max-width: 100%;
    position: relative;
    inset-inline: 0;
    margin: 70px auto 50px;
    background: linear-gradient(146.08deg, #2b3758 -0.65%, #2a9d8f 102.53%);
    padding: 25px;
  }
  .home-banner__exibition-content {
    max-width: 450px;
    width: 100%;
    margin: 0 auto;
    background-color: rgba(255, 255, 255, 0.2);
    padding: 20px;
  }
  .home-banner__exibition-content h1,
.home-banner__exibition-content h3,
.home-banner__exibition-content p {
    color: #fff;
  }
}
@media (max-width: 575px) {
  .container {
    padding: 0 25px;
  }

  .carousel-caption h3 {
    font-size: 25px;
    line-height: 25px;
  }

  .carousel-caption p {
    font-size: 13px;
    margin-bottom: 20px;
  }

  .carousel-caption p:last-child {
    max-width: 40px;
    margin-left: 10px;
  }

  .form-wrapper h2 {
    font-size: 25px;
    line-height: 30px !important;
    margin-bottom: 50px;
  }
}
@media (max-width: 375px) {
  .know-more a {
    width: 70%;
  }

  .navbar-brand {
    max-width: 50% !important;
  }

  .buttons-wrapper a {
    display: block;
    text-align: center;
    margin: 10px 0;
    font-size: 14px;
  }

  .carousel-caption h3 {
    margin: 0;
  }
  .carousel-caption p {
    margin-bottom: 0;
  }
  .carousel-caption a {
    padding: 2px 10px;
  }

  .form-wrapper .form-check-input {
    margin-bottom: 55px;
  }
  .form-wrapper .form-check-label {
    font-size: 13px;
  }
  .form-wrapper__contact-box p {
    font-size: 14px;
  }

  .top-banner h1 {
    font-size: 35px;
    margin-top: -77px;
  }
  .top-banner .solution-pages-main-title h2 {
    font-size: 30px;
  }

  .page-breadcrumb {
    padding: 0 30px;
  }
}
.btn-outline-white {
  text-decoration: none;
  color: #fff;
  width: 267px;
  height: 67px;
  border: 1px solid #fff;
  display: block;
  font-size: 30px;
  font-weight: 300;
  font-family: Poppins, sans-serif;
  text-align: center;
  line-height: 65px;
  margin-top: 25px;
}
.btn-outline-white:hover {
  color: #fff;
}
.btn-outline-white--font-border {
  border-color: #006b60;
  color: #006b60;
  margin: 50px auto 0;
  font-size: 18px;
  font-weight: 500;
  width: 150px;
  line-height: 50px;
  height: 50px;
}
.btn-outline-white--font-border:hover {
  background-color: #ffb41f;
  border-color: #ffb41f;
  color: #000;
}

.media-achievements .overlay {
  position: relative;
  display: inline-block;
  cursor: pointer;
}
.media-achievements .overlay p {
  position: absolute;
  color: #ffb41f;
  top: 50%;
  width: 100%;
  text-align: center;
  z-index: 1;
  display: none;
  text-transform: uppercase;
  color: #fff;
  text-transform: capitalize;
  font-size: 20px;
  font-weight: 400;
  top: 60%;
}
.media-achievements .overlay:after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: none;
  background: -webkit-linear-gradient(top, rgba(43, 55, 88, 0.7) 0%, rgba(42, 157, 143, 0.7) 100%);
}
.media-achievements .overlay:hover::after {
  display: inline-block;
}
.media-achievements .overlay:hover p {
  display: block;
}
.media-achievements .overlay img {
  display: block;
}
.media-achievements::before {
  position: absolute;
  content: "";
  width: 136px;
  height: 150px;
  background: url(../images/shapes/Ellipse.webp);
  background-position: center;
  left: 5%;
  top: 0;
  background: url(../images/shapes/Ellipse.webp) !important;
  background-position: center !important;
  left: 5% !important;
  transform: rotate(0deg) !important;
  top: 8% !important;
  width: 136px !important;
  height: 150px !important;
}
.media-achievements::after {
  display: none;
}
.media-achievements h2 {
  background: linear-gradient(89.94deg, #2b3758 -3.98%, #2a9d8f 103.74%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 600;
  font-size: 100px;
  line-height: 132px;
  text-transform: capitalize;
  margin-bottom: 100px;
}

.media-achievements.pb-250 {
  padding-bottom: 250px;
}

.certificate {
  position: relative;
  background: linear-gradient(146.08deg, #2b3758 -0.65%, #2a9d8f 102.53%);
  padding: 70px 0 0;
}
.certificate::before {
  position: absolute;
  content: "";
  width: 100px;
  height: 225px;
  border-radius: 12px;
  background: rgba(255, 180, 31, 0.1);
  right: 0;
  transform: rotate(90deg);
  top: 0;
  transform: rotate(90deg);
  left: 0;
  top: -250px;
}
.certificate::after {
  position: absolute;
  content: "";
  width: 131px;
  height: 134px;
  background: url(../images/shapes/Ellipse1.webp);
  background-position: center;
  top: 25%;
  right: -2%;
  background-position: center;
  right: 0;
  top: -150px;
}
.certificate h1 {
  color: #fff;
  font-size: 150px;
  font-weight: 600;
  line-height: 150px;
  margin-bottom: -23px;
}

.certificate-image {
  padding: 200px 0;
  position: relative;
}
.certificate-image::after {
  position: absolute;
  content: "";
  width: 100px;
  height: 225px;
  border-radius: 12px;
  background: rgba(255, 180, 31, 0.1);
  right: 0;
  transform: rotate(90deg);
  top: 0;
  right: 0;
  transform: rotate(90deg);
  top: 0;
}
.certificate-image::before {
  position: absolute;
  content: "";
  width: 131px;
  height: 134px;
  background: url(../images/shapes/Ellipse1.webp);
  background-position: center;
  top: 25%;
  right: -2%;
  background-position: center;
  left: 10% !important;
  top: 65%;
  transform: rotate(140deg);
}
.certificate-image .certificate-img-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100%;
}

.media {
  margin-top: 150px;
  position: relative;
}
.media h1 {
  position: relative;
  z-index: -1;
}
.media::after {
  position: absolute;
  content: "";
  width: 100px;
  height: 225px;
  border-radius: 12px;
  background: rgba(255, 180, 31, 0.1);
  right: 0;
  transform: rotate(90deg);
  top: 0;
  right: 0;
  transform: rotate(90deg);
  top: -80px;
}
.media .banner-holder h1 {
  margin-top: 0;
  margin-bottom: -27px;
}

.media-slides {
  position: relative;
  padding-top: 0;
  padding-bottom: 200px;
}
.media-slides .overlay {
  position: relative;
  display: inline-block;
  cursor: pointer;
}
.media-slides .overlay p {
  position: absolute;
  color: #ffb41f;
  top: 50%;
  width: 100%;
  text-align: center;
  z-index: 1;
  display: none;
  text-transform: uppercase;
  color: #fff;
  text-transform: capitalize;
  font-size: 20px;
  font-weight: 400;
  top: 70%;
}
.media-slides .overlay:after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: none;
  background: -webkit-linear-gradient(top, rgba(43, 55, 88, 0.7) 0%, rgba(42, 157, 143, 0.7) 100%);
}
.media-slides .overlay:hover::after {
  display: inline-block;
}
.media-slides .overlay:hover p {
  display: block;
}
.media-slides .overlay img {
  display: block;
}
.media-slides::before {
  position: absolute;
  content: "";
  width: 136px;
  height: 150px;
  background: url(../images/shapes/Ellipse.webp);
  background-position: center;
  left: 5%;
  top: 0;
  background-position: center;
  left: 5%;
  top: 82%;
  transform: rotate(0deg);
}
.media-slides::after {
  position: absolute;
  content: "";
  width: 100px;
  height: 225px;
  border-radius: 12px;
  background: rgba(255, 180, 31, 0.1);
  right: 0;
  transform: rotate(90deg);
  top: 0;
  right: 0;
  transform: rotate(90deg);
  top: 78%;
}
.media-slides h3 {
  background: linear-gradient(89.94deg, #2b3758 -3.98%, #2a9d8f 103.74%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 600;
  font-size: 100px;
  line-height: 132px;
  text-transform: capitalize;
  margin-bottom: 50px;
}
.media-slides .bg-light {
  padding: 100px 0;
  background-color: #e7e7e7 !important;
}

@media (max-width: 991px) and (min-width: 768px) {
  .media .banner-holder h1 {
    margin-bottom: -55px;
    font-size: 150px;
  }

  .certificate h1 {
    font-size: 100px;
    margin-bottom: -40px;
  }
}
@media (max-width: 767px) {
  .certificate-image {
    padding: 50px 0;
  }
  .certificate-image::before {
    display: none;
  }
  .certificate-image::after {
    display: none;
  }
  .certificate-image .certificate-img-wrapper {
    margin: 25px 0;
  }

  .media {
    margin-top: 0 !important;
  }
  .media .banner-holder h1 {
    margin-bottom: -65px;
    font-size: 70px !important;
  }
  .media::after {
    display: none;
  }

  .media-achievements {
    padding-top: 50px !important;
  }

  .media-achievements.pb-250 {
    padding-bottom: 50px;
  }

  .media-slides {
    padding-bottom: 0;
  }

  .certificate h1 {
    font-size: 50px;
    margin-bottom: -58px;
  }
  .certificate::before {
    display: none;
  }
  .certificate::after {
    display: none;
  }
}
@media (max-width: 575px) {
  .media-achievements h2 {
    font-size: 35px;
    margin-bottom: 0;
  }
}