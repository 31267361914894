@import 'https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap';
/*font-family: 'Poppins', sans-serif;*/
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  line-height: 1.1;
  font-family: "Poppins", sans-serif;
}

p {
  font-family: "Poppins", sans-serif;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}

html {
  overflow-x: hidden;
  font-family: "Poppins", sans-serif;
}

body {
  overflow-x: hidden;
  font-family: "Poppins", sans-serif;
}

/*****Breadcrumb Section Starts*****/
.page-breadcrumb {
  padding: 0 115px;
}
.page-breadcrumb .breadcrumb {
  justify-content: flex-end;
  margin-top: 1rem;
}
.page-breadcrumb .breadcrumb a {
  color: #000;
  text-decoration: none;
  font-size: 16px;
  font-weight: 300;
}
.page-breadcrumb .breadcrumb i {
  padding: 0 10px;
}

.page-breadcrumb {
  padding: 0 115px;
}
.page-breadcrumb .breadcrumb {
  justify-content: flex-end;
  margin-top: 1rem;
}
.page-breadcrumb .breadcrumb a {
  color: #000;
  text-decoration: none;
  font-size: 16px;
  font-weight: 300;
}
.page-breadcrumb .breadcrumb i {
  padding: 0 10px;
}

/*****Breadcrumb Section Ends*****/
/*****Home Carousel Style Starts*****/
.carousel-control-next {
  opacity: 0;
}
.carousel-control-next:focus {
  opacity: 0;
}
.carousel-control-next:hover {
  opacity: 0;
}

.carousel-control-prev {
  opacity: 0;
}
.carousel-control-prev:focus {
  opacity: 0;
}
.carousel-control-prev:hover {
  opacity: 0;
}

.carousel-caption {
  text-align: left;
  left: 5%;
  top: 55%;
}
.carousel-caption h3 {
  font-size: 120px;
  line-height: 120px;
  font-weight: 700;
  color: #07102a;
}
.carousel-caption p {
  font-size: 50px;
  font-weight: 400;
  color: #fff;
  margin-bottom: 50px;
}
.carousel-caption p:last-child {
  max-width: 300px;
  width: 100%;
  background: #ffb41f;
  height: 2px;
  margin-left: 25px;
}
.carousel-caption a {
  color: #fff;
  border: 2px solid #fff;
  padding: 10px 25px;
  text-decoration: none;
  float: right;
  font-size: 20px;
  text-transform: uppercase;
  font-weight: 600;
  float: none !important;
}
.carousel-caption .banner-text {
  display: flex;
  align-items: center;
}

.carousel-indicators {
  display: none;
}

/*****Home Carousel Style Ends*****/
.home-banner {
  overflow: hidden;
  position: relative;
}
.home-banner__single-img img {
  width: 100%;
  height: 950px;
  object-fit: cover;
  animation: zoom-in-zoom-out 55s ease infinite;
}
.home-banner__single-img .mouse-btn {
  display: block !important;
}
.home-banner__single-img .mouse-btn a {
  background-image: url(../images/icons/mouse_icon.webp);
  background-repeat: no-repeat;
  bottom: 8%;
  content: "";
  display: block;
  height: 60px;
  left: 50%;
  margin-left: -20px;
  position: absolute;
  width: 40px;
  z-index: 9;
  -webkit-animation-name: floating;
  animation-name: floating;
  -webkit-animation-duration: 3s;
  animation-duration: 3s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
}
@keyframes zoom-in-zoom-out {
  0% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(1.1, 1.1);
  }
  100% {
    transform: scale(1, 1);
  }
}
.home-banner__health-exibition {
  max-width: 450px;
  width: 100%;
  height: auto;
  position: absolute;
  inset-block-start: 30%;
  inset-inline-end: 5%;
  background-color: rgba(255, 255, 255, 0.5);
  box-sizing: border-box;
  padding: 20px;
  box-shadow: 0 0 5px #2b3758;
  border-radius: 3px;
}
.home-banner__exibition-content h1 {
  font-size: 27px;
  line-height: 27px;
  font-weight: 600;
  margin-block-end: 15px;
}
.home-banner__exibition-content h3 {
  font-size: 20px;
  line-height: 26px;
  font-weight: 500;
}
.home-banner__exibition-content p {
  font-size: 16px;
  line-height: 25px;
  font-weight: 500;
}

.spinner-grow {
  width: 3rem;
  height: 3rem;
  visibility: hidden;
}

.spinner-grow.spinnershow {
  width: 3rem;
  height: 3rem;
  visibility: visible;
}

.term::after {
  top: 1% !important;
}

@media (max-width: 1199px) and (min-width: 992px) {
  .know-more h1 {
    font-size: 28px;
  }

  .carousel-caption {
    top: 65%;
    left: 10%;
  }
  .carousel-caption h3 {
    font-size: 90px;
  }
  .carousel-caption p {
    font-size: 35px;
  }
  .carousel-caption p:last-child {
    max-width: 250px;
  }
}
@media (max-width: 991px) and (min-width: 768px) {
  .home-banner__single-img img {
    height: auto;
  }

  .carousel-caption {
    top: 55%;
  }
  .carousel-caption h3 {
    font-size: 60px;
    line-height: 90px;
  }
  .carousel-caption p {
    font-size: 22px;
  }
  .carousel-caption p:last-child {
    max-width: 170px;
  }

  .buttons-wrapper a {
    display: block;
    text-align: center;
    margin: 10px 0;
  }

  .top-banner h1 {
    font-size: 100px;
    margin-top: -53px;
  }
  .top-banner .solution-pages-main-title {
    top: 40%;
  }
  .top-banner .solution-pages-main-title h2 {
    font-size: 60px !important;
    line-height: 60px;
  }

  .form-wrapper .form-check-input {
    margin-bottom: 61px;
  }

  .sec-achievement .img-box img {
    height: auto;
  }

  .home-banner__health-exibition {
    inset-block-start: 25%;
    inset-inline-end: 2%;
  }
}
@media (max-width: 516px) and (min-width: 375px) {
  .know-more a {
    width: 60% !important;
  }

  .carousel-caption {
    top: 30%;
  }
  .carousel-caption p {
    font-size: 18px;
  }
  .carousel-caption a {
    font-size: 13px !important;
  }

  .buttons-wrapper a {
    display: block;
    text-align: center;
    margin: 10px 0;
  }

  .form-wrapper .form-check-input {
    margin-bottom: 55px;
  }
}
@media (max-width: 767px) {
  .know-more {
    margin-bottom: 50px;
  }
  .know-more h1 {
    text-align: center;
    margin-bottom: 20px;
  }
  .know-more a {
    float: none;
    margin: 20px auto 0;
    display: block;
    width: 40%;
    text-align: center;
  }

  .left-img-holder.float-end {
    float: none !important;
  }

  .form-wrapper {
    padding-top: 0;
    padding-bottom: 50px;
  }
  .form-wrapper::before {
    display: none;
  }
  .form-wrapper::after {
    display: none;
  }
  .form-wrapper h2 {
    font-size: 40px;
    line-height: 45px !important;
    line-height: 30px;
  }
  .form-wrapper .form-check {
    margin-bottom: 30px;
  }
  .form-wrapper a {
    float: left;
  }
  .form-wrapper label {
    font-size: 14px;
  }
  .form-wrapper__contact-box {
    margin-top: 20px;
  }

  .hide-in-mobile {
    display: none;
  }

  .carousel-caption {
    top: 50%;
  }
  .carousel-caption h3 {
    font-size: 40px;
    line-height: 60px;
  }
  .carousel-caption p {
    font-size: 18px;
  }
  .carousel-caption p:last-child {
    max-width: 70px;
  }
  .carousel-caption a {
    font-size: 13px !important;
  }

  .home-banner__health-exibition {
    max-width: 100%;
    position: relative;
    inset-inline: 0;
    margin: 70px auto 50px;
    background: linear-gradient(146.08deg, #2b3758 -0.65%, #2a9d8f 102.53%);
    padding: 25px;
  }
  .home-banner__exibition-content {
    max-width: 450px;
    width: 100%;
    margin: 0 auto;
    background-color: rgba(255, 255, 255, 0.2);
    padding: 20px;
  }
  .home-banner__exibition-content h1,
.home-banner__exibition-content h3,
.home-banner__exibition-content p {
    color: #fff;
  }
}
@media (max-width: 575px) {
  .container {
    padding: 0 25px;
  }

  .carousel-caption h3 {
    font-size: 25px;
    line-height: 25px;
  }

  .carousel-caption p {
    font-size: 13px;
    margin-bottom: 20px;
  }

  .carousel-caption p:last-child {
    max-width: 40px;
    margin-left: 10px;
  }

  .form-wrapper h2 {
    font-size: 25px;
    line-height: 30px !important;
    margin-bottom: 50px;
  }
}
@media (max-width: 375px) {
  .know-more a {
    width: 70%;
  }

  .navbar-brand {
    max-width: 50% !important;
  }

  .buttons-wrapper a {
    display: block;
    text-align: center;
    margin: 10px 0;
    font-size: 14px;
  }

  .carousel-caption h3 {
    margin: 0;
  }
  .carousel-caption p {
    margin-bottom: 0;
  }
  .carousel-caption a {
    padding: 2px 10px;
  }

  .form-wrapper .form-check-input {
    margin-bottom: 55px;
  }
  .form-wrapper .form-check-label {
    font-size: 13px;
  }
  .form-wrapper__contact-box p {
    font-size: 14px;
  }

  .top-banner h1 {
    font-size: 35px;
    margin-top: -77px;
  }
  .top-banner .solution-pages-main-title h2 {
    font-size: 30px;
  }

  .page-breadcrumb {
    padding: 0 30px;
  }
}
.btn-outline-white {
  text-decoration: none;
  color: #fff;
  width: 267px;
  height: 67px;
  border: 1px solid #fff;
  display: block;
  font-size: 30px;
  font-weight: 300;
  font-family: Poppins, sans-serif;
  text-align: center;
  line-height: 65px;
  margin-top: 25px;
}
.btn-outline-white:hover {
  color: #fff;
}
.btn-outline-white--font-border {
  border-color: #006b60;
  color: #006b60;
  margin: 50px auto 0;
  font-size: 18px;
  font-weight: 500;
  width: 150px;
  line-height: 50px;
  height: 50px;
}
.btn-outline-white--font-border:hover {
  background-color: #ffb41f;
  border-color: #ffb41f;
  color: #000;
}

.it-solutions.inner-services-page {
  padding-bottom: 250px;
}
.it-solutions.inner-services-page .flip-card {
  flex: 0 0 calc(33.33% - 30px);
  margin: 0 15px 30px;
  perspective: 1000px;
}
.it-solutions.inner-services-page .flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
}
.it-solutions.inner-services-page .flip-card .flip-card-inner {
  position: relative;
  transform-style: preserve-3d;
  transition: all 1s ease;
}
.it-solutions.inner-services-page .flip-card .flip-card-inner .flip-card-front img {
  width: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}
.it-solutions.inner-services-page .flip-card .flip-card-inner .flip-card-back {
  background: linear-gradient(146.08deg, #2b3758 -0.65%, #2a9d8f 102.53%);
  position: absolute;
  left: 0;
  top: 0;
  padding: 15px;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  transform: rotateY(180deg);
  backface-visibility: hidden;
}
.it-solutions.inner-services-page .flip-card .flip-card-inner .flip-card-back h4 {
  color: #ffb41f;
  text-transform: uppercase;
}

.services-top h3 {
  font-size: 40px;
  font-weight: 600;
  line-height: 50px;
  text-align: center;
  margin-bottom: 100px;
}
.services-top h3 span {
  color: #ffb41f;
}
.services-top p {
  font-size: 18px;
  line-height: 35px;
}
.services-top p span {
  color: #ffb41f;
}

.new-beginning h5 {
  font-size: 12px;
  line-height: 17px;
  font-weight: 600;
}
.new-beginning .active svg {
  fill: #ffb41f;
}
.new-beginning .active h5 {
  color: #ffb41f;
}

.new-beginning {
  padding: 150px 0 75px;
  position: relative;
}
.new-beginning::before {
  position: absolute;
  content: "";
  width: 131px;
  height: 134px;
  background: url(../images/shapes/Ellipse1.webp);
  background-position: center;
  top: 25%;
  right: -2%;
  background-position: center;
  top: 15%;
  left: 0;
  transform: rotate(140deg);
}
.new-beginning::after {
  position: absolute;
  content: "";
  width: 100px;
  height: 225px;
  border-radius: 12px;
  background: rgba(255, 180, 31, 0.1);
  right: 0;
  transform: rotate(90deg);
  top: 0;
  right: 0;
  top: 0;
  transform: rotate(90deg);
}
.new-beginning p {
  max-width: 500px;
  margin: 0 auto;
  font-size: 20px;
  font-weight: 400;
  text-align: center;
}
.new-beginning h3 {
  font-size: 70px;
  font-weight: 600;
  text-transform: uppercase;
  line-height: 70px;
  margin-bottom: 100px;
  font-size: 50px;
  font-weight: 500;
  text-transform: uppercase;
  line-height: 60px;
  margin-bottom: 60px;
}
.new-beginning h3 span {
  display: block !important;
  color: #ffb41f;
}
.new-beginning h5 {
  background: linear-gradient(89.94deg, #2b3758 -3.98%, #2a9d8f 103.74%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  margin-bottom: 20px;
}
.new-beginning .icon-wrapper {
  text-align: center;
}
.new-beginning .icon-wrapper a {
  text-decoration: none;
  color: none;
}
.new-beginning .icon-wrapper:hover {
  cursor: pointer;
}
.new-beginning .icon-wrapper:hover svg {
  filter: invert(27%) sepia(63%) saturate(2884%) hue-rotate(296deg) brightness(120%) contrast(95%);
}
.new-beginning .icon-wrapper svg {
  width: 51px;
  height: 46px;
  margin-bottom: 30px;
}
.new-beginning .col-lg-3,
.new-beginning .col-xl-3,
.new-beginning .col-xxl-3 {
  width: 20%;
}

.new-beginning.hide-after::after {
  display: none;
}
.new-beginning.hide-after h3 span {
  display: inline-block;
}

.educational-solution {
  padding: 100px 0;
  border-bottom: 1px solid #006b60;
}
.educational-solution__short-description {
  text-align: center;
  margin-bottom: 60px;
}
.educational-solution__short-description h2 {
  font-size: 40px;
  font-weight: 600;
  margin-bottom: 40px;
}
.educational-solution__short-description h2 span {
  color: #ffb41f;
}
.educational-solution__short-description p {
  font-size: 20px;
  line-height: 30px;
  font-weight: 400;
}
.educational-solution__product-logo {
  display: flex;
  flex-wrap: wrap;
  column-gap: 55px;
  row-gap: 150px;
}
.educational-solution__product-logo--details {
  gap: 45px !important;
}
.educational-solution__overlay {
  position: relative;
  cursor: pointer;
  width: 100%;
  max-width: 290px;
  border: 1px solid #2b3758;
}
.educational-solution__overlay h4 {
  position: absolute;
  color: #ffb41f;
  top: 50%;
  width: 100%;
  text-align: center;
  z-index: 1;
  text-transform: uppercase;
  display: none;
  color: #fff;
  top: 40%;
  font-size: 16px;
  line-height: 18px;
  font-weight: 700;
}
.educational-solution__overlay:after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: none;
  background: -webkit-linear-gradient(top, rgba(43, 55, 88, 0.9) 0%, rgba(42, 157, 143, 0.9) 100%);
}
.educational-solution__overlay:hover::after {
  display: block;
}
.educational-solution__overlay:hover h4 {
  display: block;
}
.educational-solution__overlay img {
  display: block;
  padding: 0 10px;
}
.educational-solution__view-more {
  width: 100%;
  max-width: 290px;
}

.boost {
  background: url(../images/boost.webp) no-repeat top center;
  padding: 190px 0;
  background-size: cover;
}
.boost h3 {
  font-size: 40px;
  font-weight: 600;
  color: #fff;
  line-height: 60px;
  margin-bottom: 60px;
}
.boost h3 span {
  display: block;
  color: #ffb41f;
}
.boost a {
  color: #fff;
  border: 2px solid #fff;
  padding: 10px 25px;
  text-decoration: none;
  float: right;
  font-size: 20px;
  text-transform: uppercase;
  font-weight: 600;
  padding: 14px 20px;
  float: none;
}

.it-solutions.inner-services-page {
  padding-bottom: 250px;
}
.it-solutions.inner-services-page .flip-card {
  flex: 0 0 calc(33.33% - 30px);
  margin: 0 15px 30px;
  perspective: 1000px;
}
.it-solutions.inner-services-page .flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
}
.it-solutions.inner-services-page .flip-card .flip-card-inner {
  position: relative;
  transform-style: preserve-3d;
  transition: all 1s ease;
}
.it-solutions.inner-services-page .flip-card .flip-card-inner .flip-card-front img {
  width: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}
.it-solutions.inner-services-page .flip-card .flip-card-inner .flip-card-back {
  background: linear-gradient(146.08deg, #2b3758 -0.65%, #2a9d8f 102.53%);
  position: absolute;
  left: 0;
  top: 0;
  padding: 15px;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  transform: rotateY(180deg);
  backface-visibility: hidden;
}
.it-solutions.inner-services-page .flip-card .flip-card-inner .flip-card-back h4 {
  color: #ffb41f;
  text-transform: uppercase;
}

.services {
  padding: 0;
  position: relative;
}
.services .home-service-main {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}
.services .home-service-main .services-left {
  background: linear-gradient(146.08deg, #2b3758 -0.65%, #2a9d8f 102.53%);
  min-height: 100%;
  width: 40%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.services .home-service-main .services-left li {
  font-size: 35px;
  font-weight: 400;
  line-height: 70px;
  color: #fff;
}
.services .home-service-main .services-left li a {
  text-decoration: none;
  color: #fff;
  text-transform: uppercase;
}
.services .home-service-main .services-img {
  width: 60%;
}
.services h1 {
  text-align: right;
  font-size: 180px;
  font-weight: 600;
  color: #e7e7e7;
  margin-bottom: -43px;
  position: relative;
  z-index: -1;
}
.services img {
  width: 100%;
  height: 800px;
  object-fit: cover;
}

.services-top h1 {
  font-size: 180px;
  font-weight: 700;
  text-transform: uppercase;
  color: #e7e7e7;
  line-height: 180px;
  margin-top: -25px;
}
.services-top h2 {
  font-size: 40px;
  margin-bottom: 25px;
  line-height: 60px;
  font-weight: 600;
}
.services-top h2 span {
  color: #ffb41f;
}
.services-top p {
  font-size: 25px;
  line-height: 46px;
  font-weight: 400;
}
.services-top .img-right {
  position: relative;
  margin-top: -50px;
}
.services-top .img-right::after {
  position: absolute;
  content: "";
  background: linear-gradient(146.08deg, #2b3758 -0.65%, #2a9d8f 102.53%);
  width: 100%;
  height: 100%;
  left: 4%;
  z-index: -1;
  top: 5%;
}

.inner-services-page .left-img-holder img {
  margin: 0;
}

@media (max-width: 1399px) and (min-width: 1200px) {
  .services .home-service-main .services-left li {
    font-size: 30px;
    line-height: 60px;
  }

  .educational-solution__product-logo {
    row-gap: 190px;
  }
  .educational-solution__overlay, .educational-solution__view-more {
    max-width: 245px;
  }
}
@media (max-width: 1199px) and (min-width: 992px) {
  .services-top h3 {
    font-size: 32px;
    line-height: 50px;
  }
  .services-top p {
    font-size: 18px;
    line-height: 35px;
  }

  .inner-services-page .sliding-side h4 {
    font-size: 30px;
    line-height: 45px;
    margin-bottom: 20px;
  }
  .inner-services-page .sliding-side p {
    font-size: 15px;
    line-height: 25px;
  }

  .inner-services .flip-card {
    min-height: 372px;
  }

  .educational-solution__product-logo {
    column-gap: 25px;
  }
  .educational-solution__overlay, .educational-solution__view-more {
    max-width: 215px;
  }
}
@media (max-width: 991px) and (min-width: 768px) {
  .services .home-service-main .services-left li {
    font-size: 20px;
    line-height: 40px;
  }
  .services .services-img img {
    height: auto;
  }
  .services h1 {
    font-size: 120px;
    margin-bottom: -28px;
  }
  .services h1::before {
    top: -75%;
  }

  .services-top h1 {
    font-size: 125px;
  }
  .services-top h2 {
    font-size: 25px;
    line-height: 35px;
    margin-bottom: 15px;
  }
  .services-top p {
    font-size: 15px;
    line-height: 25px;
  }
  .services-top .img-right {
    margin-top: -60px;
  }

  .inner-services-page .sliding-side {
    justify-content: flex-start;
  }
  .inner-services-page .sliding-side h4 {
    font-size: 25px;
    margin-bottom: 0;
  }
  .inner-services-page .sliding-side p {
    font-size: 14px;
    line-height: 22px;
  }
  .inner-services-page .left-img-holder {
    margin-bottom: 30px;
  }

  .btn-outline-white {
    width: 150px !important;
    height: 50px !important;
    font-size: 18px !important;
    line-height: 50px !important;
    margin-top: 20px !important;
  }

  .educational-solution__short-description p {
    font-size: 16px;
    line-height: 24px;
  }
  .educational-solution__product-logo {
    column-gap: 15px;
    row-gap: 115px;
  }
  .educational-solution__overlay, .educational-solution__view-more {
    max-width: 160px;
  }
}
@media (max-width: 516px) and (min-width: 375px) {
  .services .services-left li {
    font-size: 20px !important;
    line-height: 40px !important;
  }

  .btn-outline-white {
    width: 150px !important;
    height: 45px !important;
    font-size: 18px !important;
    line-height: 44px !important;
    margin-top: 25px !important;
  }

  .educational-solution__product-logo {
    justify-content: center;
    row-gap: 15px !important;
  }
}
@media (max-width: 767px) {
  .it-solutions .sliding-side p {
    font-size: 16px;
    line-height: 24px;
    text-align: center;
  }

  .new-beginning::before {
    display: none;
  }
  .new-beginning .col-lg-3,
.new-beginning .col-xl-3,
.new-beginning .col-xxl-3 {
    width: 50%;
  }

  .new-beginning::after {
    display: none;
  }

  .it-solutions .sliding-side h2 {
    font-size: 25px;
    text-align: center;
    line-height: 25px;
    margin-bottom: 20px;
  }

  .boost a {
    font-size: 14px;
  }

  .boost {
    padding: 100px 0 !important;
  }

  .it-solutions .sliding-side p {
    font-size: 16px !important;
    line-height: 24px;
    text-align: center;
  }

  .new-beginning {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .new-beginning h3 {
    font-size: 22px;
    line-height: 32px;
    margin-bottom: 20px;
  }

  .services {
    padding-top: 50px;
    padding-bottom: 0;
  }
  .services::before {
    display: none;
  }
  .services .home-service-main {
    flex-direction: row-reverse;
    flex-wrap: wrap-reverse;
  }
  .services .home-service-main .services-left {
    padding: 30px 0;
    width: 100%;
    min-height: auto;
    justify-content: flex-start;
    padding-left: 20px;
  }
  .services .home-service-main .services-left li {
    font-size: 25px;
    line-height: 50px;
  }
  .services .home-service-main .services-img {
    width: 100%;
    padding: 0 20px;
  }
  .services h1 {
    font-size: 80px;
    margin-bottom: -19px;
  }
  .services h1::before {
    display: none;
  }

  .services-top {
    padding-top: 20px;
    padding-bottom: 75px;
  }
  .services-top .w-reverse {
    flex-wrap: wrap-reverse;
  }
  .services-top h1 {
    font-size: 85px;
  }
  .services-top h2 {
    font-size: 25px;
    line-height: 35px;
  }
  .services-top h3 {
    font-size: 22px;
    line-height: 32px;
  }
  .services-top p {
    font-size: 16px;
    line-height: 28px;
  }
  .services-top .img-right {
    margin-top: -75px;
    margin-bottom: 60px;
  }

  #cms-dev {
    flex-wrap: wrap-reverse;
    margin: 50px 0;
  }

  .boost h3 {
    font-size: 25px;
    line-height: 45px;
  }

  .educational-solution__short-description p {
    font-size: 16px;
    line-height: 24px;
  }
  .educational-solution__short-description h2 {
    font-size: 30px;
  }
  .educational-solution__product-logo {
    column-gap: 25px;
    row-gap: 50px;
    margin-block-start: 50px;
  }
  .educational-solution__overlay, .educational-solution__view-more {
    max-width: 245px;
  }

  .reverse {
    flex-wrap: wrap-reverse;
  }
}
@media (max-width: 575px) {
  .new-beginning h5 {
    font-size: 12px;
    line-height: 17px;
    font-weight: 600;
  }

  .services-top h1 {
    font-size: 50px;
    font-weight: 800;
  }
  .services-top .img-right {
    margin-top: -85px;
  }

  .services-top h1 {
    font-size: 50px;
    font-weight: 800;
  }
  .services-top .img-right {
    margin-top: -85px;
  }
}
@media (max-width: 375px) {
  .boost h3 {
    font-size: 18px;
    line-height: 30px;
  }

  .services .services-left li {
    font-size: 18px !important;
    line-height: 36px !important;
  }
  .services h1 {
    font-size: 55px;
    margin-bottom: -14px;
  }

  .btn-outline-white {
    width: 150px !important;
    height: 45px !important;
    font-size: 18px !important;
    line-height: 44px !important;
  }

  .educational-solution__product-logo {
    justify-content: center;
    row-gap: 15px !important;
  }
}