footer {
  background-color: #07102a;
  padding-top: 70px;
}
footer .logo-footer {
  border-right: 1px solid #ffb41f;
  padding: 10px 0;
}
footer .footer-text {
  margin-bottom: 60px;
}
footer .footer-text p {
  color: #fff;
  font-size: 18px;
  line-height: 25px;
  font-weight: 400;
  padding-left: 50px;
}
footer .contact {
  display: flex;
}
footer .contact div:last-child {
  color: #fff;
  margin-left: 20px;
  font-size: 18px;
  font-weight: 500;
}
footer .contact a {
  color: #fff !important;
  text-decoration: none;
}
footer .contact:hover a {
  color: #ffb41f !important;
}
footer .contact:hover i {
  color: #ffb41f !important;
}
footer .contact p {
  font-size: 18px;
  font-weight: 500;
}
footer .address {
  display: flex;
}
footer .address div:last-child {
  color: #fff;
  margin-left: 20px;
  font-size: 18px;
  font-weight: 500;
}
footer .address a {
  text-decoration: none;
  color: #fff;
}
footer .address a:hover {
  color: #ffb41f;
}
footer .email {
  display: flex;
}
footer .email div:last-child {
  color: #fff;
  margin-left: 20px;
  font-size: 18px;
  font-weight: 500;
}
footer .email a {
  color: #fff;
  text-decoration: none;
}
footer .email:hover a {
  color: #ffb41f !important;
}
footer .email:hover i {
  color: #ffb41f !important;
}
footer .social-media--icons {
  display: flex;
  justify-content: center;
}
footer .social-media--icons ul {
  list-style-type: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 30%;
}
footer .social-media--icons ul i {
  color: #fff;
  font-size: 26px;
}
footer .social-media--icons ul i:hover {
  color: #ffb41f;
}
footer .social-media--icons ul .fa-facebook {
  background-color: #fff;
  color: #07102a;
  width: 30px;
  border-radius: 50%;
  text-align: center;
  height: 30px;
  padding-top: 8px;
  font-size: 23px;
}
footer .social-media--icons ul .fa-facebook:hover {
  background-color: #ffb41f;
  color: #07102a;
}
footer .separator {
  display: block;
  height: 1px;
  background-color: #ffb41f;
  margin: 60px 0;
  position: relative;
}
footer .separator::after {
  position: absolute;
  content: "";
  width: 50%;
  height: 4px;
  background-color: #ffb41f;
  bottom: -1px;
  left: 0;
  right: 0;
  margin: 0 auto;
}
footer .footer-links {
  margin-top: 20px;
}
footer .footer-links ul {
  display: flex;
  list-style-type: none;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
  flex-wrap: wrap;
}
footer .footer-links ul a {
  color: #fff;
  text-decoration: none;
  padding: 0 10px;
  font-size: 20px;
  font-weight: 400;
}
footer .footer-links ul a:hover {
  color: #ffb41f;
}
footer .policy-links {
  padding: 30px 0 0;
}
footer .policy-links ul {
  padding: 0;
}
footer .policy-links ul li {
  display: inline-block;
}
footer .policy-links ul li a {
  text-decoration: none;
  color: #ffb41f;
  font-size: 18px;
  font-weight: 500;
}
footer .policy-links ul li:nth-child(2) {
  padding: 0 40px;
}
footer .copyright {
  padding: 30px 0 0;
  text-align: right;
}
footer .copyright p {
  margin: 0;
  font-size: 18px;
  font-weight: 500;
}
footer .copyright p span {
  color: #ffb41f;
}

.cookie-wrapper {
  display: none;
  width: 50%;
  position: fixed;
  bottom: 10px;
  z-index: 9;
  color: #fff;
  padding: 10px 20px;
  background: #fbf3e3;
  border-radius: 8px;
  border: 2px solid #000;
}
.cookie-wrapper.show {
  display: unset;
}
.cookie-wrapper p {
  text-align: justify;
  color: #000;
}
.cookie-wrapper p.text-right {
  text-align: right;
  margin: 0;
}
.cookie-wrapper p.text-right .btn-dark {
  background-color: #07102a;
  padding: 5px 8px;
  border: none;
  border-radius: 3px;
  font-size: 13px;
  font-weight: 500;
  color: #fff !important;
  text-decoration: none;
}
.cookie-wrapper p.text-right .btn-dark:hover {
  background-color: #ffb41f;
  color: #fff;
}
.cookie-wrapper a {
  color: #ffb41f;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 14px;
}

@media (max-width: 1024px) and (min-width: 992px) {
  footer .contact a,
footer .address a,
footer .email a {
    font-size: 16px;
  }
}
@media (max-width: 991px) and (min-width: 768px) {
  .social-media--icons ul {
    width: 45% !important;
  }

  footer .contact a,
footer .address a,
footer .email a {
    font-size: 12px;
  }
  footer .footer-text p {
    font-size: 15px !important;
  }
  footer .copyright p {
    font-size: 15px;
  }
  footer .policy-links li:nth-child(2) {
    padding: 0 10px !important;
  }
  footer .policy-links a {
    font-size: 15px !important;
  }
}
@media (max-width: 767px) {
  .social-media--icons ul {
    width: 80% !important;
  }

  footer .logo-footer {
    border: none !important;
    margin-bottom: 25px;
  }
  footer .logo-footer img {
    display: block;
    margin: 0 auto;
  }
  footer .footer-text p {
    padding: 0;
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
  }
  footer .contact a {
    font-size: 16px;
  }
  footer .address {
    display: block !important;
    text-align: center;
    margin: 50px 0;
  }
  footer .address a {
    font-size: 16px;
  }
  footer .address div:last-child {
    margin-top: 20px;
  }
  footer .email {
    display: block !important;
    text-align: center;
  }
  footer .email a {
    font-size: 16px;
  }
  footer .email div:last-child {
    margin-top: 20px;
  }
  footer .footer-links ul a {
    font-size: 16px;
  }
  footer .policy-links {
    text-align: center;
  }
  footer .policy-links ul li:nth-child(2) {
    padding: 0 20px;
  }
  footer .policy-links ul a {
    font-size: 16px !important;
  }
  footer .copyright p {
    text-align: center;
    font-size: 16px;
  }
}