@import 'https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap';
/*font-family: 'Poppins', sans-serif;*/
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  line-height: 1.1;
  font-family: "Poppins", sans-serif;
}

p {
  font-family: "Poppins", sans-serif;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}

html {
  overflow-x: hidden;
  font-family: "Poppins", sans-serif;
}

body {
  overflow-x: hidden;
  font-family: "Poppins", sans-serif;
}

/*****Breadcrumb Section Starts*****/
.page-breadcrumb {
  padding: 0 115px;
}
.page-breadcrumb .breadcrumb {
  justify-content: flex-end;
  margin-top: 1rem;
}
.page-breadcrumb .breadcrumb a {
  color: #000;
  text-decoration: none;
  font-size: 16px;
  font-weight: 300;
}
.page-breadcrumb .breadcrumb i {
  padding: 0 10px;
}

.page-breadcrumb {
  padding: 0 115px;
}
.page-breadcrumb .breadcrumb {
  justify-content: flex-end;
  margin-top: 1rem;
}
.page-breadcrumb .breadcrumb a {
  color: #000;
  text-decoration: none;
  font-size: 16px;
  font-weight: 300;
}
.page-breadcrumb .breadcrumb i {
  padding: 0 10px;
}

/*****Breadcrumb Section Ends*****/
/*****Home Carousel Style Starts*****/
.carousel-control-next {
  opacity: 0;
}
.carousel-control-next:focus {
  opacity: 0;
}
.carousel-control-next:hover {
  opacity: 0;
}

.carousel-control-prev {
  opacity: 0;
}
.carousel-control-prev:focus {
  opacity: 0;
}
.carousel-control-prev:hover {
  opacity: 0;
}

.carousel-caption {
  text-align: left;
  left: 5%;
  top: 55%;
}
.carousel-caption h3 {
  font-size: 120px;
  line-height: 120px;
  font-weight: 700;
  color: #07102a;
}
.carousel-caption p {
  font-size: 50px;
  font-weight: 400;
  color: #fff;
  margin-bottom: 50px;
}
.carousel-caption p:last-child {
  max-width: 300px;
  width: 100%;
  background: #ffb41f;
  height: 2px;
  margin-left: 25px;
}
.carousel-caption a {
  color: #fff;
  border: 2px solid #fff;
  padding: 10px 25px;
  text-decoration: none;
  float: right;
  font-size: 20px;
  text-transform: uppercase;
  font-weight: 600;
  float: none !important;
}
.carousel-caption .banner-text {
  display: flex;
  align-items: center;
}

.carousel-indicators {
  display: none;
}

/*****Home Carousel Style Ends*****/
.home-banner {
  overflow: hidden;
  position: relative;
}
.home-banner__single-img img {
  width: 100%;
  height: 950px;
  object-fit: cover;
  animation: zoom-in-zoom-out 55s ease infinite;
}
.home-banner__single-img .mouse-btn {
  display: block !important;
}
.home-banner__single-img .mouse-btn a {
  background-image: url(../images/icons/mouse_icon.webp);
  background-repeat: no-repeat;
  bottom: 8%;
  content: "";
  display: block;
  height: 60px;
  left: 50%;
  margin-left: -20px;
  position: absolute;
  width: 40px;
  z-index: 9;
  -webkit-animation-name: floating;
  animation-name: floating;
  -webkit-animation-duration: 3s;
  animation-duration: 3s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
}
@keyframes zoom-in-zoom-out {
  0% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(1.1, 1.1);
  }
  100% {
    transform: scale(1, 1);
  }
}
.home-banner__health-exibition {
  max-width: 450px;
  width: 100%;
  height: auto;
  position: absolute;
  inset-block-start: 30%;
  inset-inline-end: 5%;
  background-color: rgba(255, 255, 255, 0.5);
  box-sizing: border-box;
  padding: 20px;
  box-shadow: 0 0 5px #2b3758;
  border-radius: 3px;
}
.home-banner__exibition-content h1 {
  font-size: 27px;
  line-height: 27px;
  font-weight: 600;
  margin-block-end: 15px;
}
.home-banner__exibition-content h3 {
  font-size: 20px;
  line-height: 26px;
  font-weight: 500;
}
.home-banner__exibition-content p {
  font-size: 16px;
  line-height: 25px;
  font-weight: 500;
}

.spinner-grow {
  width: 3rem;
  height: 3rem;
  visibility: hidden;
}

.spinner-grow.spinnershow {
  width: 3rem;
  height: 3rem;
  visibility: visible;
}

.term::after {
  top: 1% !important;
}

@media (max-width: 1199px) and (min-width: 992px) {
  .know-more h1 {
    font-size: 28px;
  }

  .carousel-caption {
    top: 65%;
    left: 10%;
  }
  .carousel-caption h3 {
    font-size: 90px;
  }
  .carousel-caption p {
    font-size: 35px;
  }
  .carousel-caption p:last-child {
    max-width: 250px;
  }
}
@media (max-width: 991px) and (min-width: 768px) {
  .home-banner__single-img img {
    height: auto;
  }

  .carousel-caption {
    top: 55%;
  }
  .carousel-caption h3 {
    font-size: 60px;
    line-height: 90px;
  }
  .carousel-caption p {
    font-size: 22px;
  }
  .carousel-caption p:last-child {
    max-width: 170px;
  }

  .buttons-wrapper a {
    display: block;
    text-align: center;
    margin: 10px 0;
  }

  .top-banner h1 {
    font-size: 100px;
    margin-top: -53px;
  }
  .top-banner .solution-pages-main-title {
    top: 40%;
  }
  .top-banner .solution-pages-main-title h2 {
    font-size: 60px !important;
    line-height: 60px;
  }

  .form-wrapper .form-check-input {
    margin-bottom: 61px;
  }

  .sec-achievement .img-box img {
    height: auto;
  }

  .home-banner__health-exibition {
    inset-block-start: 25%;
    inset-inline-end: 2%;
  }
}
@media (max-width: 516px) and (min-width: 375px) {
  .know-more a {
    width: 60% !important;
  }

  .carousel-caption {
    top: 30%;
  }
  .carousel-caption p {
    font-size: 18px;
  }
  .carousel-caption a {
    font-size: 13px !important;
  }

  .buttons-wrapper a {
    display: block;
    text-align: center;
    margin: 10px 0;
  }

  .form-wrapper .form-check-input {
    margin-bottom: 55px;
  }
}
@media (max-width: 767px) {
  .know-more {
    margin-bottom: 50px;
  }
  .know-more h1 {
    text-align: center;
    margin-bottom: 20px;
  }
  .know-more a {
    float: none;
    margin: 20px auto 0;
    display: block;
    width: 40%;
    text-align: center;
  }

  .left-img-holder.float-end {
    float: none !important;
  }

  .form-wrapper {
    padding-top: 0;
    padding-bottom: 50px;
  }
  .form-wrapper::before {
    display: none;
  }
  .form-wrapper::after {
    display: none;
  }
  .form-wrapper h2 {
    font-size: 40px;
    line-height: 45px !important;
    line-height: 30px;
  }
  .form-wrapper .form-check {
    margin-bottom: 30px;
  }
  .form-wrapper a {
    float: left;
  }
  .form-wrapper label {
    font-size: 14px;
  }
  .form-wrapper__contact-box {
    margin-top: 20px;
  }

  .hide-in-mobile {
    display: none;
  }

  .carousel-caption {
    top: 50%;
  }
  .carousel-caption h3 {
    font-size: 40px;
    line-height: 60px;
  }
  .carousel-caption p {
    font-size: 18px;
  }
  .carousel-caption p:last-child {
    max-width: 70px;
  }
  .carousel-caption a {
    font-size: 13px !important;
  }

  .home-banner__health-exibition {
    max-width: 100%;
    position: relative;
    inset-inline: 0;
    margin: 70px auto 50px;
    background: linear-gradient(146.08deg, #2b3758 -0.65%, #2a9d8f 102.53%);
    padding: 25px;
  }
  .home-banner__exibition-content {
    max-width: 450px;
    width: 100%;
    margin: 0 auto;
    background-color: rgba(255, 255, 255, 0.2);
    padding: 20px;
  }
  .home-banner__exibition-content h1,
.home-banner__exibition-content h3,
.home-banner__exibition-content p {
    color: #fff;
  }
}
@media (max-width: 575px) {
  .container {
    padding: 0 25px;
  }

  .carousel-caption h3 {
    font-size: 25px;
    line-height: 25px;
  }

  .carousel-caption p {
    font-size: 13px;
    margin-bottom: 20px;
  }

  .carousel-caption p:last-child {
    max-width: 40px;
    margin-left: 10px;
  }

  .form-wrapper h2 {
    font-size: 25px;
    line-height: 30px !important;
    margin-bottom: 50px;
  }
}
@media (max-width: 375px) {
  .know-more a {
    width: 70%;
  }

  .navbar-brand {
    max-width: 50% !important;
  }

  .buttons-wrapper a {
    display: block;
    text-align: center;
    margin: 10px 0;
    font-size: 14px;
  }

  .carousel-caption h3 {
    margin: 0;
  }
  .carousel-caption p {
    margin-bottom: 0;
  }
  .carousel-caption a {
    padding: 2px 10px;
  }

  .form-wrapper .form-check-input {
    margin-bottom: 55px;
  }
  .form-wrapper .form-check-label {
    font-size: 13px;
  }
  .form-wrapper__contact-box p {
    font-size: 14px;
  }

  .top-banner h1 {
    font-size: 35px;
    margin-top: -77px;
  }
  .top-banner .solution-pages-main-title h2 {
    font-size: 30px;
  }

  .page-breadcrumb {
    padding: 0 30px;
  }
}
.btn-outline-white {
  text-decoration: none;
  color: #fff;
  width: 267px;
  height: 67px;
  border: 1px solid #fff;
  display: block;
  font-size: 30px;
  font-weight: 300;
  font-family: Poppins, sans-serif;
  text-align: center;
  line-height: 65px;
  margin-top: 25px;
}
.btn-outline-white:hover {
  color: #fff;
}
.btn-outline-white--font-border {
  border-color: #006b60;
  color: #006b60;
  margin: 50px auto 0;
  font-size: 18px;
  font-weight: 500;
  width: 150px;
  line-height: 50px;
  height: 50px;
}
.btn-outline-white--font-border:hover {
  background-color: #ffb41f;
  border-color: #ffb41f;
  color: #000;
}

.inner-about h3 {
  font-size: 18px;
  line-height: 30px;
}
.inner-about h1 {
  font-size: 50px;
  margin-bottom: -78px;
}

.mission-vision {
  background: linear-gradient(94.83deg, #008070 -15.89%, #2a9d8f 49.97%, #2b3758 122.9%);
  padding: 150px 0 50px;
  margin-top: -100px;
  position: relative;
}
.mission-vision::before {
  position: absolute;
  content: "";
  width: 136px;
  height: 150px;
  background-position: center;
  background: url(../images/shapes/Ellipse-green.webp);
  left: 0;
  top: 3%;
  transform: rotate(10deg);
}
.mission-vision::after {
  position: absolute;
  content: "";
  width: 131px;
  height: 134px;
  background: url(../images/shapes/Ellipse1.webp);
  background-position: center;
  top: 25%;
  right: -2%;
  top: 82%;
  right: -1%;
  transform: rotate(140deg);
}
.mission-vision .mission-vision-content h2 {
  color: #ffb41f;
  font-size: 40px;
  font-weight: 600;
  line-height: 55px;
  margin-bottom: 10px;
  text-transform: uppercase;
}
.mission-vision .mission-vision-content p {
  color: #fff;
  font-size: 20px;
  font-weight: 300;
  line-height: 30px;
}
.mission-vision .divider {
  width: 70%;
  background-color: #fff;
  height: 1px;
  margin: 50px auto;
}
.mission-vision svg {
  transition: 0.5s;
}
.mission-vision svg:hover {
  transform: scale(1.3);
  cursor: pointer;
}

.inner-business h3 {
  font-size: 40px;
  line-height: 60px;
}

.inner-about {
  padding: 100px 0;
  position: relative;
  z-index: 1;
}
.inner-about::before {
  position: absolute;
  content: "";
  width: 136px;
  height: 150px;
  background: url(../images/shapes/Ellipse.webp);
  background-position: center;
  left: 5%;
  top: 0;
  background-position: center;
  left: -15px;
  top: 50px;
}
.inner-about::after {
  position: absolute;
  content: "";
  width: 100px;
  height: 225px;
  border-radius: 12px;
  background: rgba(255, 180, 31, 0.1);
  right: 0;
  transform: rotate(90deg);
  top: 0;
  right: 0%;
  top: 15%;
  transform: rotate(0deg);
}
.inner-about h3 {
  font-size: 55px;
  font-weight: 600;
  line-height: 80px;
  margin-bottom: 120px;
}
.inner-about h3 span {
  display: block;
}
.inner-about h3 span span {
  color: #ffb41f;
  display: inline-block;
  font-weight: 800;
}
.inner-about h1 {
  font-size: 180px;
  font-weight: 700;
  text-transform: uppercase;
  color: #e7e7e7;
  line-height: 180px;
  margin-top: -25px;
  letter-spacing: -10px;
  margin-bottom: -49px;
  position: relative;
  z-index: -2;
}

.inner-solution {
  padding: 100px 0 40px;
  position: relative;
}
.inner-solution::before {
  position: absolute;
  content: "";
  width: 100px;
  height: 225px;
  border-radius: 12px;
  background: rgba(255, 180, 31, 0.1);
  right: 0;
  transform: rotate(90deg);
  top: 0;
  left: 0;
  top: 3%;
  transform: rotate(0);
}
.inner-solution .inner-solution-img {
  position: relative;
  margin-top: 50px;
}
.inner-solution .inner-solution-img::before {
  position: absolute;
  content: "";
  background: linear-gradient(146.08deg, #2b3758 -0.65%, #2a9d8f 102.53%);
  z-index: -1;
  width: 70%;
  height: 100%;
  left: 0;
  top: 25px;
}
.inner-solution .inner-solution-img img {
  margin-left: 25px;
}
.inner-solution h4 {
  font-size: 40px;
  line-height: 60px;
  font-weight: 600;
  margin-bottom: 25px;
}
.inner-solution h4 span {
  color: #ffb41f;
}
.inner-solution p {
  font-size: 20px;
  font-weight: 400;
  line-height: 35px;
}

.inner-services {
  padding: 100px 0;
  position: relative;
}
.inner-services::before {
  position: absolute;
  content: "";
  width: 100px;
  height: 225px;
  border-radius: 12px;
  background: rgba(255, 180, 31, 0.1);
  right: 0;
  transform: rotate(90deg);
  top: 0;
  left: 0;
  top: 0%;
  transform: rotate(0);
}
.inner-services::after {
  position: absolute;
  content: "";
  width: 136px;
  height: 150px;
  background: url(../images/shapes/Ellipse.webp);
  background-position: center;
  left: 5%;
  top: 0;
  background-position: center;
  left: 2%;
  top: 80%;
}
.inner-services h5 {
  background: linear-gradient(89.94deg, #2b3758 -3.98%, #2a9d8f 103.74%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  font-weight: 600;
  font-size: 25px;
  margin-bottom: 20px;
}
.inner-services h3 {
  font-size: 50px;
  font-weight: 600;
  position: relative;
}
.inner-services h3::after {
  position: absolute;
  content: "";
  width: 141px;
  height: 191px;
  background: url(../images/shapes/Ellipse2.webp);
  background-position: center;
  top: -150px;
  right: -23%;
}
.inner-services h3 span {
  color: #ffb41f;
  font-weight: 700;
}
.inner-services .flip-card-holder {
  display: flex;
  flex-wrap: wrap;
}
.inner-services .flip-card-holder .flip-card {
  flex: 0 0 calc(33.33% - 30px);
  margin: 0 15px 30px;
  perspective: 1000px;
}
.inner-services .flip-card-holder .flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
}
.inner-services .flip-card-holder .flip-card .flip-card-inner {
  position: relative;
  transform-style: preserve-3d;
  transition: all 1s ease;
}
.inner-services .flip-card-holder .flip-card .flip-card-inner .flip-card-front img {
  width: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}
.inner-services .flip-card-holder .flip-card .flip-card-inner .flip-card-back {
  background: linear-gradient(146.08deg, #2b3758 -0.65%, #2a9d8f 102.53%);
  position: absolute;
  left: 0;
  top: 0;
  padding: 15px;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  transform: rotateY(180deg);
  backface-visibility: hidden;
}
.inner-services .flip-card-holder .flip-card .flip-card-inner .flip-card-back h4 {
  color: #ffb41f;
  text-transform: uppercase;
}
.inner-services a {
  color: #388697;
  border: 2px solid #388697;
  text-decoration: none;
  padding: 10px 20px;
  font-size: 20px;
  font-weight: 600;
  text-transform: uppercase;
}

.inner-business {
  padding: 100px 0;
}
.inner-business h5 {
  background: linear-gradient(89.94deg, #2b3758 -3.98%, #2a9d8f 103.74%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 600;
  margin-bottom: 20px;
}
.inner-business h3 {
  font-size: 48px;
  font-weight: 600;
  line-height: 70px;
  color: #2b3758;
  margin-bottom: 45px;
}
.inner-business h3 span {
  color: #ffb41f;
  font-weight: 700;
}
.inner-business a {
  background: linear-gradient(146.08deg, #2b3758 -0.65%, #2a9d8f 102.53%);
  padding: 20px 30px;
  color: #fff;
  text-decoration: none;
  font-size: 20px;
  font-weight: 700;
  text-transform: uppercase;
}
.inner-business a:hover {
  box-shadow: 0 0 10px #8d8d8d;
}

@media (max-width: 1399px) and (min-width: 1200px) {
  .about-us .about-img::after {
    width: 90%;
    left: 16%;
  }
  .about-us .about-img h2 {
    right: -138px;
    font-size: 55px;
  }

  .mission-vision::before {
    display: none;
  }
}
@media (max-width: 1199px) and (min-width: 992px) {
  .about-us img {
    width: 85%;
  }
  .about-us .about-img::after {
    width: 60%;
    right: 0;
  }
  .about-us .about-img h2 {
    font-size: 55px;
    right: -107px;
  }

  .mission-vision::before {
    display: none;
  }

  .home-add-section .nutri-add {
    padding-top: 50px;
  }
  .home-add-section .add-content h1 {
    font-size: 70px !important;
  }
  .home-add-section .add-content p {
    font-size: 20px !important;
    line-height: 28px !important;
    margin-bottom: 35px !important;
  }
  .home-add-section .add-content a {
    font-size: 18px !important;
    padding: 8px 25px !important;
  }
}
@media (max-width: 991px) and (min-width: 768px) {
  .about-us img {
    width: 85%;
  }
  .about-us .about-img::after {
    width: 60%;
    right: 0 !important;
  }
  .about-us .about-img h2 {
    font-size: 45px;
    right: -88px;
  }
  .about-us .about-text h3 {
    font-size: 18px;
    line-height: 25px;
    margin-bottom: 30px;
  }
  .about-us .about-text p {
    font-size: 14px;
    line-height: 20px;
  }

  .inner-business h3 {
    font-size: 40px !important;
    line-height: 60px !important;
  }

  .mission-vision::before {
    display: none;
  }

  .home-add-section .nutri-add {
    padding-top: 50px;
  }
  .home-add-section .add-content {
    padding-left: 50px;
  }
  .home-add-section .add-content h1 {
    font-size: 60px !important;
    margin-bottom: 15px !important;
  }
  .home-add-section .add-content p {
    font-size: 18px !important;
    line-height: 25px !important;
    margin-bottom: 30px !important;
  }
  .home-add-section .add-content a {
    font-size: 16px !important;
    padding: 6px 20px !important;
  }

  .inner-solution h4 {
    font-size: 19px;
    line-height: 30px;
  }
  .inner-solution p {
    font-size: 15px;
    line-height: 22px;
  }
  .inner-solution .inner-solution-content {
    padding-left: 30px;
  }

  .inner-about::before {
    display: none;
  }
  .inner-about::after {
    display: none;
  }
  .inner-about h1 {
    font-size: 145px;
    margin-bottom: -60px;
  }
  .inner-about h3 {
    font-size: 42px;
    line-height: 58px;
  }
}
@media (max-width: 767px) {
  .inner-services-page {
    padding-bottom: 50px !important;
  }
  .inner-services-page .left-img-holder img {
    display: block;
    margin: 10px auto;
  }
  .inner-services-page .sliding-side {
    margin-top: 30px;
  }
  .inner-services-page .sliding-side h4 {
    text-align: center;
    margin-bottom: 20px;
    font-size: 25px;
  }
  .inner-services-page .sliding-side p {
    text-align: center;
    font-size: 13px;
    line-height: 22px;
  }

  .inner-services h3 {
    font-size: 28px !important;
  }

  .inner-services {
    padding-top: 50px;
  }
  .inner-services::before {
    display: none;
  }
  .inner-services::after {
    display: none;
  }
  .inner-services h3 {
    font-size: 30px;
  }
  .inner-services h3::after {
    display: none;
  }
  .inner-services h5 {
    font-size: 15px;
  }
  .inner-services .flip-card {
    flex: 0 0 calc(100% - 30px) !important;
  }

  .about-us {
    padding-bottom: 50px;
    padding-top: 50px;
  }
  .about-us .about-img img {
    width: 80%;
  }
  .about-us .about-img::after {
    width: 60%;
  }
  .about-us .about-img h2 {
    font-size: 50px;
    right: -82px;
  }
  .about-us .about-text {
    margin-top: 60px;
    padding-left: 0;
  }

  .mission-vision {
    padding-bottom: 50px;
  }
  .mission-vision .divider {
    margin: 50px 0;
    width: 100%;
  }
  .mission-vision .mission-vision-content h2 {
    font-size: 35px;
  }
  .mission-vision .mission-vision-content p {
    font-size: 20px;
    line-height: 32px;
  }
  .mission-vision::before {
    display: none;
  }
  .mission-vision::after {
    display: none;
  }

  .home-add-section {
    margin-top: 50px;
  }
  .home-add-section .add-content {
    padding-left: 0 !important;
    width: 50%;
    margin: 0 auto 50px;
  }
  .home-add-section .add-content h1 {
    font-size: 30px !important;
    margin-bottom: 15px !important;
  }
  .home-add-section .add-content p {
    font-size: 16px !important;
    line-height: 22px !important;
    margin-bottom: 30px !important;
  }
  .home-add-section .add-content a {
    font-size: 16px !important;
    padding: 6px 20px !important;
  }
}
@media (max-width: 575px) {
  .about-us .about-img::after {
    width: 50%;
    right: 6%;
  }
  .about-us .about-img .img-fluid {
    max-width: 80% !important;
  }
  .about-us .about-img h2 {
    right: -64px;
  }

  .about-text h3 {
    font-size: 25px !important;
    line-height: 35px !important;
  }
  .about-text p {
    font-size: 15px !important;
    line-height: 25px !important;
  }

  .sec-client p {
    font-size: 15px;
    line-height: 22px;
    padding: 0 10px;
  }

  .sec-achievement .swiper-button-prev {
    height: 25px;
    width: 25px;
    margin-right: 25px;
  }
  .sec-achievement .swiper-button-prev::before {
    font-size: 12px;
  }
  .sec-achievement .swiper-button-prev::after {
    font-size: 12px;
  }
  .sec-achievement .swiper-button-next {
    height: 25px;
    width: 25px;
  }
  .sec-achievement .swiper-button-next::before {
    font-size: 12px;
  }
  .sec-achievement .swiper-button-next::after {
    font-size: 12px;
  }

  .product-section .products .bg-img-wrapper {
    padding: 25px 0;
  }
  .product-section .products .bg-img-wrapper .number {
    height: 25px;
    width: 25px;
    font-size: 18px;
  }
  .product-section .products .bg-img-wrapper .detail-container {
    padding-top: 30px;
  }
  .product-section .products .bg-img-wrapper .detail-container .content {
    margin-bottom: 25px;
    font-size: 13px;
  }

  .inner-business h3 {
    font-size: 25px;
    line-height: 35px;
  }

  .inner-solution .inner-solution-img img {
    margin-left: 15px;
  }
}
@media (max-width: 375px) {
  .inner-about h1 {
    font-size: 50px !important;
    margin-bottom: -78px !important;
  }

  .inner-about h3 {
    font-size: 18px !important;
    line-height: 30px !important;
  }

  .mission-vision .mission-vision-content h4 {
    font-size: 30px;
  }
  .mission-vision .mission-vision-content p {
    font-size: 16px;
    line-height: 25px;
  }

  .inner-business h3 {
    font-size: 17px !important;
    line-height: 30px !important;
  }
  .inner-business h5 {
    font-size: 14px;
  }
  .inner-business a {
    padding: 10px 20px;
    font-size: 14px;
  }

  .inner-solution h4 {
    text-align: center;
    font-size: 25px;
    line-height: 35px;
  }
  .inner-solution p {
    text-align: center;
  }
  .inner-solution .inner-solution-img img {
    width: 95%;
  }
}