@import 'https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap';
/*font-family: 'Poppins', sans-serif;*/
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  line-height: 1.1;
  font-family: "Poppins", sans-serif;
}

p {
  font-family: "Poppins", sans-serif;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}

html {
  overflow-x: hidden;
  font-family: "Poppins", sans-serif;
}

body {
  overflow-x: hidden;
  font-family: "Poppins", sans-serif;
}

/*****Breadcrumb Section Starts*****/
.page-breadcrumb {
  padding: 0 115px;
}
.page-breadcrumb .breadcrumb {
  justify-content: flex-end;
  margin-top: 1rem;
}
.page-breadcrumb .breadcrumb a {
  color: #000;
  text-decoration: none;
  font-size: 16px;
  font-weight: 300;
}
.page-breadcrumb .breadcrumb i {
  padding: 0 10px;
}

.page-breadcrumb {
  padding: 0 115px;
}
.page-breadcrumb .breadcrumb {
  justify-content: flex-end;
  margin-top: 1rem;
}
.page-breadcrumb .breadcrumb a {
  color: #000;
  text-decoration: none;
  font-size: 16px;
  font-weight: 300;
}
.page-breadcrumb .breadcrumb i {
  padding: 0 10px;
}

/*****Breadcrumb Section Ends*****/
/*****Home Carousel Style Starts*****/
.carousel-control-next {
  opacity: 0;
}
.carousel-control-next:focus {
  opacity: 0;
}
.carousel-control-next:hover {
  opacity: 0;
}

.carousel-control-prev {
  opacity: 0;
}
.carousel-control-prev:focus {
  opacity: 0;
}
.carousel-control-prev:hover {
  opacity: 0;
}

.carousel-caption {
  text-align: left;
  left: 5%;
  top: 55%;
}
.carousel-caption h3 {
  font-size: 120px;
  line-height: 120px;
  font-weight: 700;
  color: #07102a;
}
.carousel-caption p {
  font-size: 50px;
  font-weight: 400;
  color: #fff;
  margin-bottom: 50px;
}
.carousel-caption p:last-child {
  max-width: 300px;
  width: 100%;
  background: #ffb41f;
  height: 2px;
  margin-left: 25px;
}
.carousel-caption a {
  color: #fff;
  border: 2px solid #fff;
  padding: 10px 25px;
  text-decoration: none;
  float: right;
  font-size: 20px;
  text-transform: uppercase;
  font-weight: 600;
  float: none !important;
}
.carousel-caption .banner-text {
  display: flex;
  align-items: center;
}

.carousel-indicators {
  display: none;
}

/*****Home Carousel Style Ends*****/
.home-banner {
  overflow: hidden;
  position: relative;
}
.home-banner__single-img img {
  width: 100%;
  height: 950px;
  object-fit: cover;
  animation: zoom-in-zoom-out 55s ease infinite;
}
.home-banner__single-img .mouse-btn {
  display: block !important;
}
.home-banner__single-img .mouse-btn a {
  background-image: url(../images/icons/mouse_icon.webp);
  background-repeat: no-repeat;
  bottom: 8%;
  content: "";
  display: block;
  height: 60px;
  left: 50%;
  margin-left: -20px;
  position: absolute;
  width: 40px;
  z-index: 9;
  -webkit-animation-name: floating;
  animation-name: floating;
  -webkit-animation-duration: 3s;
  animation-duration: 3s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
}
@keyframes zoom-in-zoom-out {
  0% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(1.1, 1.1);
  }
  100% {
    transform: scale(1, 1);
  }
}
.home-banner__health-exibition {
  max-width: 450px;
  width: 100%;
  height: auto;
  position: absolute;
  inset-block-start: 30%;
  inset-inline-end: 5%;
  background-color: rgba(255, 255, 255, 0.5);
  box-sizing: border-box;
  padding: 20px;
  box-shadow: 0 0 5px #2b3758;
  border-radius: 3px;
}
.home-banner__exibition-content h1 {
  font-size: 27px;
  line-height: 27px;
  font-weight: 600;
  margin-block-end: 15px;
}
.home-banner__exibition-content h3 {
  font-size: 20px;
  line-height: 26px;
  font-weight: 500;
}
.home-banner__exibition-content p {
  font-size: 16px;
  line-height: 25px;
  font-weight: 500;
}

.spinner-grow {
  width: 3rem;
  height: 3rem;
  visibility: hidden;
}

.spinner-grow.spinnershow {
  width: 3rem;
  height: 3rem;
  visibility: visible;
}

.term::after {
  top: 1% !important;
}

@media (max-width: 1199px) and (min-width: 992px) {
  .know-more h1 {
    font-size: 28px;
  }

  .carousel-caption {
    top: 65%;
    left: 10%;
  }
  .carousel-caption h3 {
    font-size: 90px;
  }
  .carousel-caption p {
    font-size: 35px;
  }
  .carousel-caption p:last-child {
    max-width: 250px;
  }
}
@media (max-width: 991px) and (min-width: 768px) {
  .home-banner__single-img img {
    height: auto;
  }

  .carousel-caption {
    top: 55%;
  }
  .carousel-caption h3 {
    font-size: 60px;
    line-height: 90px;
  }
  .carousel-caption p {
    font-size: 22px;
  }
  .carousel-caption p:last-child {
    max-width: 170px;
  }

  .buttons-wrapper a {
    display: block;
    text-align: center;
    margin: 10px 0;
  }

  .top-banner h1 {
    font-size: 100px;
    margin-top: -53px;
  }
  .top-banner .solution-pages-main-title {
    top: 40%;
  }
  .top-banner .solution-pages-main-title h2 {
    font-size: 60px !important;
    line-height: 60px;
  }

  .form-wrapper .form-check-input {
    margin-bottom: 61px;
  }

  .sec-achievement .img-box img {
    height: auto;
  }

  .home-banner__health-exibition {
    inset-block-start: 25%;
    inset-inline-end: 2%;
  }
}
@media (max-width: 516px) and (min-width: 375px) {
  .know-more a {
    width: 60% !important;
  }

  .carousel-caption {
    top: 30%;
  }
  .carousel-caption p {
    font-size: 18px;
  }
  .carousel-caption a {
    font-size: 13px !important;
  }

  .buttons-wrapper a {
    display: block;
    text-align: center;
    margin: 10px 0;
  }

  .form-wrapper .form-check-input {
    margin-bottom: 55px;
  }
}
@media (max-width: 767px) {
  .know-more {
    margin-bottom: 50px;
  }
  .know-more h1 {
    text-align: center;
    margin-bottom: 20px;
  }
  .know-more a {
    float: none;
    margin: 20px auto 0;
    display: block;
    width: 40%;
    text-align: center;
  }

  .left-img-holder.float-end {
    float: none !important;
  }

  .form-wrapper {
    padding-top: 0;
    padding-bottom: 50px;
  }
  .form-wrapper::before {
    display: none;
  }
  .form-wrapper::after {
    display: none;
  }
  .form-wrapper h2 {
    font-size: 40px;
    line-height: 45px !important;
    line-height: 30px;
  }
  .form-wrapper .form-check {
    margin-bottom: 30px;
  }
  .form-wrapper a {
    float: left;
  }
  .form-wrapper label {
    font-size: 14px;
  }
  .form-wrapper__contact-box {
    margin-top: 20px;
  }

  .hide-in-mobile {
    display: none;
  }

  .carousel-caption {
    top: 50%;
  }
  .carousel-caption h3 {
    font-size: 40px;
    line-height: 60px;
  }
  .carousel-caption p {
    font-size: 18px;
  }
  .carousel-caption p:last-child {
    max-width: 70px;
  }
  .carousel-caption a {
    font-size: 13px !important;
  }

  .home-banner__health-exibition {
    max-width: 100%;
    position: relative;
    inset-inline: 0;
    margin: 70px auto 50px;
    background: linear-gradient(146.08deg, #2b3758 -0.65%, #2a9d8f 102.53%);
    padding: 25px;
  }
  .home-banner__exibition-content {
    max-width: 450px;
    width: 100%;
    margin: 0 auto;
    background-color: rgba(255, 255, 255, 0.2);
    padding: 20px;
  }
  .home-banner__exibition-content h1,
.home-banner__exibition-content h3,
.home-banner__exibition-content p {
    color: #fff;
  }
}
@media (max-width: 575px) {
  .container {
    padding: 0 25px;
  }

  .carousel-caption h3 {
    font-size: 25px;
    line-height: 25px;
  }

  .carousel-caption p {
    font-size: 13px;
    margin-bottom: 20px;
  }

  .carousel-caption p:last-child {
    max-width: 40px;
    margin-left: 10px;
  }

  .form-wrapper h2 {
    font-size: 25px;
    line-height: 30px !important;
    margin-bottom: 50px;
  }
}
@media (max-width: 375px) {
  .know-more a {
    width: 70%;
  }

  .navbar-brand {
    max-width: 50% !important;
  }

  .buttons-wrapper a {
    display: block;
    text-align: center;
    margin: 10px 0;
    font-size: 14px;
  }

  .carousel-caption h3 {
    margin: 0;
  }
  .carousel-caption p {
    margin-bottom: 0;
  }
  .carousel-caption a {
    padding: 2px 10px;
  }

  .form-wrapper .form-check-input {
    margin-bottom: 55px;
  }
  .form-wrapper .form-check-label {
    font-size: 13px;
  }
  .form-wrapper__contact-box p {
    font-size: 14px;
  }

  .top-banner h1 {
    font-size: 35px;
    margin-top: -77px;
  }
  .top-banner .solution-pages-main-title h2 {
    font-size: 30px;
  }

  .page-breadcrumb {
    padding: 0 30px;
  }
}
.btn-outline-white {
  text-decoration: none;
  color: #fff;
  width: 267px;
  height: 67px;
  border: 1px solid #fff;
  display: block;
  font-size: 30px;
  font-weight: 300;
  font-family: Poppins, sans-serif;
  text-align: center;
  line-height: 65px;
  margin-top: 25px;
}
.btn-outline-white:hover {
  color: #fff;
}
.btn-outline-white--font-border {
  border-color: #006b60;
  color: #006b60;
  margin: 50px auto 0;
  font-size: 18px;
  font-weight: 500;
  width: 150px;
  line-height: 50px;
  height: 50px;
}
.btn-outline-white--font-border:hover {
  background-color: #ffb41f;
  border-color: #ffb41f;
  color: #000;
}

.top-banner h1 {
  font-size: 180px;
  font-weight: 700;
  text-transform: uppercase;
  color: #e7e7e7;
  line-height: 180px;
  margin-top: -25px;
}
.top-banner .solution-pages-main-title {
  position: absolute;
  width: 100%;
  text-align: center;
  top: 45%;
}
.top-banner .solution-pages-main-title h2 {
  color: #fff;
  font-size: 100px;
  line-height: 90px;
  text-transform: uppercase;
  font-weight: 600;
}
.top-banner .breadcrumb {
  justify-content: flex-end;
  margin-right: 115px;
}
.top-banner .breadcrumb a {
  color: #000;
  text-decoration: none;
  font-size: 16px;
  font-weight: 400;
}
.top-banner .breadcrumb i {
  color: #000;
  padding: 0 10px;
}

.back-btn {
  margin-top: 50px;
}
.back-btn ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.back-btn ul li {
  display: inline-block;
}
.back-btn ul li a {
  text-decoration: none;
  color: #000;
}

.it-solutions {
  padding: 100px 0;
  position: relative;
}
.it-solutions::after {
  position: absolute;
  content: "";
  width: 131px;
  height: 134px;
  background: url(../images/shapes/Ellipse1.webp);
  background-position: center;
  top: 25%;
  right: -2%;
  background-position: center;
  top: -1%;
  right: -1%;
  transform: rotate(110deg);
}
.it-solutions::before {
  position: absolute;
  content: "";
  width: 100px;
  height: 225px;
  border-radius: 12px;
  background: rgba(255, 180, 31, 0.1);
  right: 0;
  transform: rotate(90deg);
  top: 0;
  right: 0;
  transform: rotate(90deg);
  top: 93%;
}
.it-solutions .flip-card {
  /*img {
    width: 348px;
    height: 437px;
  }
  img.bit-large {
    width: 610px;
    height: 691px;
  }*/
}
.it-solutions .flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
}
.it-solutions .flip-card .flip-card-inner {
  position: relative;
  transform-style: preserve-3d;
  transition: all 1s ease;
  /*width: 80%;*/
  margin: 0 auto;
}
.it-solutions .flip-card .flip-card-inner .flip-card-front {
  background-color: #bbb;
  color: #000;
  background-size: contain;
  background-repeat: no-repeat;
}
.it-solutions .flip-card .front-four {
  background: url(../images/solutions/education.webp);
}
.it-solutions .flip-card .front-five {
  background: url(../images/solutions/health.webp);
}
.it-solutions .flip-card .front-six {
  background: url(../images/solutions/medical.webp);
}
.it-solutions .flip-card .front-seven {
  background: url(../images/solutions/enhanced.webp);
}
.it-solutions .sliding-side {
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: center;
}
.it-solutions .sliding-side h2 {
  font-size: 40px;
  font-weight: 600;
  line-height: 60px;
  margin-bottom: 20px;
}
.it-solutions .sliding-side h2 span {
  color: #ffb41f;
}
.it-solutions .sliding-side p {
  font-size: 18px;
  font-weight: 400;
  line-height: 32px;
}
.it-solutions .sliding-side p span {
  color: #006b60;
  font-weight: 600;
}
.it-solutions .solution-slider-wrapper .overlay {
  position: relative;
  display: inline-block;
  cursor: pointer;
}
.it-solutions .solution-slider-wrapper .overlay h4 {
  position: absolute;
  color: #ffb41f;
  top: 50%;
  width: 100%;
  text-align: center;
  z-index: 1;
  display: none;
  text-transform: uppercase;
  display: block;
  color: #fff;
  top: 40%;
  font-size: 16px;
  line-height: 18px;
  font-weight: 700;
}
.it-solutions .solution-slider-wrapper .overlay:after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: none;
  background: -webkit-linear-gradient(top, rgba(43, 55, 88, 0.9) 0%, rgba(42, 157, 143, 0.9) 100%);
  display: block;
}
.it-solutions .solution-slider-wrapper .overlay:hover::after {
  display: none;
}
.it-solutions .solution-slider-wrapper .overlay:hover h4 {
  display: none;
}
.it-solutions .solution-slider-wrapper .overlay img {
  display: block;
  border: 1px solid #2b3758;
}
.it-solutions .inner-solution-slide {
  margin-top: 50px;
}
.it-solutions .swiper-button-next {
  color: #fff;
  background-color: #fff;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  position: relative;
  position: relative;
  top: 15%;
  left: 85%;
  display: inline-flex;
  background-color: #ccc;
}
.it-solutions .swiper-button-next::after {
  font-size: 18px;
  color: #919090;
  font-weight: 100;
  content: "\f061" !important;
  font-family: "FontAwesome" !important;
}
.it-solutions .swiper-button-next:hover {
  background-color: #ffb41f;
}
.it-solutions .swiper-button-prev {
  color: #fff;
  background-color: #fff;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  position: relative;
  position: relative;
  top: 15%;
  left: 85%;
  display: inline-flex;
  background-color: #ccc;
}
.it-solutions .swiper-button-prev::after {
  font-size: 18px;
  color: #919090;
  font-weight: 100;
  content: "\f060" !important;
  font-family: "FontAwesome" !important;
}
.it-solutions .swiper-button-prev:hover {
  background-color: #ffb41f;
}
.it-solutions #ui-dev {
  position: relative;
}
.it-solutions #ui-dev::after {
  position: absolute;
  content: "";
  width: 239px;
  height: 241px;
  background: url(../images/shapes/Ellipse-large.webp);
  background-position: center;
  left: 30%;
  bottom: -40%;
}

.inner-solu .flip-card {
  flex: 0 0 calc(33.33% - 30px);
  margin: 0 15px 30px;
  perspective: 1000px;
}
.inner-solu .flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
}
.inner-solu .flip-card .flip-card-inner {
  position: relative;
  transform-style: preserve-3d;
  transition: all 1s ease;
  width: 80%;
  margin: 0 auto;
}
.inner-solu .flip-card .flip-card-inner .flip-card-front img {
  width: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}
.inner-solu .flip-card .flip-card-inner .flip-card-back {
  background: linear-gradient(146.08deg, #2b3758 -0.65%, #2a9d8f 102.53%);
  position: absolute;
  left: 0;
  top: 0;
  padding: 15px;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  transform: rotateY(180deg);
  backface-visibility: hidden;
}
.inner-solu .flip-card .flip-card-inner .flip-card-back h4 {
  color: #ffb41f;
  text-transform: uppercase;
}

@media (max-width: 1399px) and (min-width: 1200px) {
  .solutions .text-left h1 {
    font-size: 70px;
  }

  .inner-solu .sliding-side h4 {
    margin-bottom: 20px;
  }
  .inner-solu .inner-solution-slide {
    margin-top: 30px;
  }
  .inner-solu .solution-slider-wrapper .overlay h4 {
    font-size: 18px;
    line-height: 18px;
  }
  .inner-solu .swiper-button-next {
    top: 10%;
  }
  .inner-solu .swiper-button-prev {
    top: 10%;
  }
}
@media (max-width: 1199px) and (min-width: 992px) {
  .solutions .solution-box {
    padding: 20px 15px !important;
  }
  .solutions .solution-box h4 {
    font-size: 19px;
  }
  .solutions .solution-box p {
    font-size: 16px;
  }
  .solutions .text-left h1 {
    font-size: 44px !important;
    line-height: 65px !important;
  }

  .inner-solution h4 {
    font-size: 28px;
    line-height: 38px;
  }
  .inner-solution p {
    font-size: 16px;
  }
  .inner-solution .inner-solution-content {
    padding-left: 50px;
  }

  .it-solutions .sliding-side {
    margin-top: 10px;
  }
  .it-solutions .sliding-side h2 {
    font-size: 30px;
    line-height: 40px;
    margin-bottom: 20px;
  }
  .it-solutions .sliding-side p {
    font-size: 15px;
    line-height: 25px;
  }
  .it-solutions .inner-solution-slide {
    margin-top: 50px;
  }
  .it-solutions .swiper-button-prev {
    top: 8%;
  }
  .it-solutions .swiper-button-next {
    top: 8%;
  }

  .inner-solu .flip-card-inner {
    width: 100% !important;
  }

  .achievements h3 {
    font-size: 80px;
  }

  .form-wrapper .form-check-input {
    margin-bottom: 30px;
  }

  .solution-pages-main-title h2 {
    font-size: 60px !important;
  }
}
@media (max-width: 991px) and (min-width: 768px) {
  .solutions .text-left h1 {
    font-size: 80px;
    line-height: 80px;
    margin-bottom: 50px;
  }
  .solutions .text-right p {
    font-size: 16px;
    line-height: 28px;
    padding-left: 0;
    padding-right: 200px;
  }

  .it-solutions .inner-solution-slide {
    margin-top: 0;
  }
  .it-solutions .sliding-side {
    margin-top: 10px;
  }
  .it-solutions .sliding-side h2 {
    font-size: 25px;
    line-height: 30px;
    margin-bottom: 10px;
  }
  .it-solutions .sliding-side p {
    font-size: 14px;
    line-height: 22px;
  }
}
@media (max-width: 991px) {
  .inner-solu .flip-card-inner {
    width: 100% !important;
  }

  .solution-slider-wrapper .overlay h4 {
    font-size: 18px !important;
    line-height: 18px !important;
  }
}
@media (max-width: 767px) {
  .it-solutions .inner-solution-slide {
    margin-top: 30px;
  }

  .top-banner h1 {
    font-size: 50px;
    margin-top: -70px;
  }

  .it-solutions.inner-services-page::before {
    display: none;
  }
  .it-solutions.inner-services-page::after {
    display: none;
  }

  .inner-allsolution-pages h1 {
    font-size: 50px !important;
    line-height: 50px !important;
  }

  .inner-business h3 {
    font-size: 33px;
    line-height: 50px;
  }

  .it-solutions {
    padding-top: 0;
  }
  .it-solutions::after {
    display: none;
  }
  .it-solutions::before {
    display: none;
  }
  .it-solutions .flip-card-front {
    text-align: center;
  }
  .it-solutions .flip-card-front img {
    float: none !important;
    margin: 0 auto;
  }
  .it-solutions .sliding-side {
    margin-bottom: 50px;
    margin-top: 0;
  }

  .it-solutions #medical-innovations {
    margin-bottom: 100px;
  }

  .it-solutions #health-it-solutions {
    margin-bottom: 100px;
  }

  .it-solutions #enhanced-productivity {
    margin-bottom: 100px;
  }

  .it-solutions #educational-it-solutions {
    margin-bottom: 100px;
  }

  .it-solutions .swiper-button-prev {
    top: 5%;
    left: 75%;
  }

  .it-solutions .swiper-button-next {
    top: 5%;
    left: 75%;
  }
}
@media (max-width: 575px) {
  .it-solutions .solution-slider-wrapper h4 {
    font-size: 12px !important;
  }

  .solutions .text-left h1 {
    font-size: 34px !important;
    line-height: 50px !important;
  }

  .inner-allsolution-pages {
    padding: 100px 0;
  }

  .top-banner .breadcrumb {
    right: 40px;
  }
  .top-banner .breadcrumb a {
    font-size: 11px;
  }
  .top-banner .solution-pages-main-title {
    width: 92%;
  }
  .top-banner .solution-pages-main-title h2 {
    font-size: 18px !important;
    line-height: 35px !important;
  }
}
@media (max-width: 375px) {
  .top-banner h1 {
    font-size: 35px !important;
  }

  .solutions .text-left h1 {
    font-size: 35px;
    line-height: 42px;
  }
  .solutions .top-space .col-6 {
    width: 100%;
  }

  .it-solutions .solution-slider-wrapper h4 {
    font-size: 9px !important;
  }

  .inner-allsolution-pages h1 {
    font-size: 30px;
    line-height: 40px;
  }
}
@media (max-width: 320px) {
  .it-solutions .swiper-button-next,
.it-solutions .swiper-button-prev {
    left: 70%;
  }
}